section.succes-message {
    padding: 4rem 2rem;
    text-align: center;
    @media screen and (min-width: $desktop-extra-big-BP) { 
        padding: 2rem 30rem 11.5rem;
    }
    & > div {
        & > h2 {
            font-family: $ff-Cream-b;
            margin-bottom: 2rem;
        }
        p {
            padding-bottom: 1.5rem;
    
            @media screen and (min-width: $tablet-BP) { 
                padding-bottom: 2rem;
            }
            @media screen and (min-width: $desktop-BP) {
                padding-bottom: 2rem;
            }
        }
        & > a {
            margin: 0 auto;
        }
    }
}