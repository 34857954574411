section.contact-us-form {

    padding: 0;

    @media screen and (min-width: $tablet-BP) {
        margin-top: 3rem;
    } 

    @media screen and (min-width: $desktop-BP) {
        padding: 0;
        margin: 0;
    }

    & > div {
        padding: 0;
        @media screen and (min-width: $desktop-BP) {
            margin: 0;
            max-width: none;
        }

        & > .grid {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: auto 38rem;
                grid-template-rows: 1fr;
            }

            & > div {
                padding: 0 2em;
                &:first-child {
                    @media screen and (min-width: $desktop-BP) {
                        padding: 6rem;
                    }
                    @media screen and (min-width: $desktop-big-BP) {
                        padding: 6rem 6rem 6rem 15rem;
                    }

                    & > div {

                        &:first-child {

                            height: 100%;
                            background: $white;
                            border: 1px solid $gray-dots;
    
                            h3 {
                                font-family: $ff-Cream-b;
                                margin:4rem 8rem 0px 2rem;
                                @media screen and (min-width: $tablet-BP) {
                                    margin:  3rem 0;  
                                }

                                @media screen and (min-width: $desktop-BP) {
                                    font-size: 4rem;  
                                    margin:  4rem 4rem 0 4rem; 
                                }
                            }
    
                            & > form {
                                margin: 2rem 2rem;
                                @media screen and (min-width: $tablet-BP) {
                                    margin: 0;   
                                }
                                @media screen and (min-width: $desktop-BP) {
                                    margin: 2rem 4rem;   
                                }

                                & > fieldset {
                                    border:none;
                                    border-radius: inherit;
                                    padding: 0;
                                    margin: 0;

                                    & > legend {
                                        font-family: $ff-Alternate;
                                        //outline: none;
                                        margin-bottom: 1rem;
                                        padding-top: 3rem;
                                        font-size: 2.4rem;
                                        text-transform: uppercase;
                                    
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 2.6rem;
                                        }
                                    }
    
                                    & > div {
                                        &.grid {
                                            
                                            @media screen and (min-width: $tablet-BP) {
                                                display:grid;
                                                grid-template-columns: 1fr 1fr;
                                                gap: 0 4rem;
                                            }
                                        }
    
                                        &.is-half {
                                            display: grid;
                                            grid-template-columns: 1fr 1fr;
                                            gap: 0 4rem;

                                        }
    
                                        & > div {
    
                                            &.has-select {
                                                position: relative;
                                                & > select {
                                                    background-image: url(/media/globals/arrow-accordion-2.svg);
                                                    background-repeat: no-repeat;
                                                    background-position: 97% center;
                                                    background-size: 1.5rem;
                                                    font-family: $ff-Cream-b;
                                                }
                                            }
    
                                            & > textarea {
                                                height: 18rem;
                                                resize: none;
                                            }
    
                                            & > select,
                                            & > textarea,
                                            & > input {
                                                background: $gray-bg-soft;
                                                //outline: none;
                                                padding: 1.7rem;
                                                width: 100%;
                                                border: none;
                                                border-radius: inherit;
                                                appearance: none;
                                                font-size: 17px;

                                                @media screen and (min-width: $tablet-BP) {
                                                    font-size: 18px;
                                                    padding: 1.3rem 1.7rem 1.4rem 1.7rem;
                                                }
                                            }
                                            
                                            & > input {

                                                margin-bottom: 1rem;
                                                
                                                @media screen and (min-width: $tablet-BP) {
                                                    margin-bottom: auto;
                                                }

                                                &::placeholder {
                                                    color: $dark;
                                                    font-style: italic;
                                                }
                                            } 
                                        } 
                                    }
                                }
                                .ajax-message {
                                    & > div {
                                        &:first-child {
                                            text-align: right;
                                            padding: 0.5rem;
                                            margin-top: -2.5rem;
                                            margin-right: 0;
                                            padding: 0;
                                            @media screen and (min-width: $tablet-BP) {
                                                margin-right: 2rem;
                                            }
                                            & > input {
                                                border-radius: 5px;
                                                transform: rotate(-8deg);
                                            }
                                        }

                                        &.message {
                                            p {
                                                display: none;
                                                margin-top: 3rem;
                                                margin-bottom: 2rem;
                                            }
                                        }
                                    }
                                }
                            }                        
                        }
                        &:last-child {
                            padding: 1.5rem 2rem 0 2.5rem;
                            @media screen and (min-width: $tablet-BP) {
                                padding: 3rem 2rem;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                padding: 3rem 4rem 0 5rem;
                            }
                            p {
                                margin: 0;
                            }         
                        }
                    } 
                }
                &:last-child {
                    border-left: 1px solid $gray-dots;
                    background: $white;
                    @media screen and (min-width: $desktop-BP) {
                        padding: 4rem 0;
                    }

                    & > div {
                        &:last-child {
                            & > div {
                                border: none;
                                border-radius: inherit;
                            }
                        }
                    }
                }

                .ff-errors{
                    display: none;
                }
            }
        }
    }
}