section.careers-description-card {
    
    margin-bottom: 3rem;
    margin-top: 3rem;


    @media screen and (min-width: $tablet-BP) {
        padding: 0 2rem;
        margin: 4rem 2rem 4.5rem;
    }

    @media screen and (min-width: $desktop-BP) {
        margin: 6rem auto;
        padding: 0 4rem;
    }
  
    & > div {
        border:1px solid $gray-dots;
        background: $white;
        padding: 2rem;

        @media screen and (min-width: $tablet-BP) {
            padding: 4rem;
        }
        & > div {

            &:first-child { // title and date
                border-bottom: 3px solid $dark;
                @media screen and (min-width: $tablet-BP) {
                    display: grid;
                    grid-template-columns: 2fr auto;
                    gap: 1rem;
                    align-items: flex-start;
                    border-bottom: 3px solid $dark;
                }
                & > div {
                    &:first-child { // contains h3 Berkley title
                        
                        h3 {
                            font-family: $ff-Clawson-b;
                            font-weight: 700;
                            text-transform: uppercase;
                            // font-size: 4rem;
                            line-height: 1;
                            margin: 0.6rem 0;
                            text-align: center;
                            @media screen and (min-width: $tablet-BP) {
                                margin: 1rem 0;
                                text-align: left;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                // font-size: 6rem;
                                margin: 0;
                            }
                        }
                    }
                    &:last-child { // contains date November

                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                    
                        @media screen and (min-width: $tablet-BP) {
                            padding-bottom: 0rem;
                            border-bottom: 1px solid $gray;
                            display: block;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            padding-top: 0.5rem;
                        }
                        span {
                            font-size: 1.5rem;
                            font-family: $ff-sansSerif;
                            text-transform: uppercase;
                            @media screen and (min-width: $tablet-BP) {
                                margin-right: 2rem;
                            }
                        }
                        time {
                            font-family: $ff-HouseSlam-r;
                            text-transform: uppercase;
                            font-size: 3rem;
                        }
                    }
                }
            }

            &:nth-child(2) {
                p {
                    font-family: $ff-Cream-b;
                    margin: 20px 0 0;
                    @media screen and (min-width: $desktop-BP) {
                        font-size: 3rem;
                        
                    }
                }
                
            }

            &:nth-child(3) {
                font-family: $ff-sansSerif;
                @media screen and (min-width: $tablet-BP) {
                    padding: 1rem 0;
                }
                p {
                    margin: 20px 0 0;
                    @media screen and (min-width: $desktop-BP) {
                        margin-left: 5rem;
                    }
                }
            }

            &:last-child {
                border-bottom: 3px solid $dark;
                padding-bottom: 3rem;
                @media screen and (min-width: $desktop-BP) {
                    padding-left: 5rem;
                    padding-bottom: 4rem;
                }
            }
        }
    }
}