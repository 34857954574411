.careers-card {
    border:1px solid $gray-dots;
    background: $white;
    padding: 2rem;
    margin-bottom: 3rem;

    @media screen and (min-width: $tablet-BP) {
        padding: 4rem;
    }

    @media screen and (min-width: $desktop-BP) {
        margin-bottom: 3rem;
    }

    &.hide {
        opacity: 0;
        display: none;
        min-height: 50px;
        transition: all 500s ease;
        animation: selected 300ms 1;
    }

    & > div {
        @media screen and (min-width: $tablet-BP) {
            display:flex;
            justify-content: space-between;
            width: 100%;
        }
        & > div {
            
            &:first-child {
                @media screen and (min-width: $tablet-BP) {
                    padding-right: 4rem;
                }

                h3 {
                    margin-top: 0;
                    font-family: $ff-Cream-b;
                }

                h5 {
                    font-family: $ff-Clawson-b;
                    // margin: 0 0 50px;
                }

                & > div {
                    p {
                    text-transform: initial;
                        @media screen and (min-width: $tablet-BP) {
                            margin-bottom: 0;
                        }
                    }

                    
                }
            }

            &:last-child{
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
}