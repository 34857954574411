section.cards-bottom  {
    padding: 0;
    margin: 1.5rem 0 0;
    border-top: 1px solid $gray-dots;;
    padding-top: 1.5rem;

    &.contact-us {
        margin: 0;
    }

    @media screen and (min-width: $tablet-BP) {
        padding-top: 2rem;
    }
    
    & > div {
        padding: 0;
        @media screen and (min-width: $desktop-BP) {
            max-width: none;
        }
        .grid {
            padding: 0;
            position: relative;
            
            @media screen and (min-width: $tablet-BP) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 1em;
            }

            @media screen and (min-width: $desktop-BP) {
                gap: 0px 2em;
            }
        }   
    }
}