.locations-sticky-items {
    @media screen and (min-width: $tablet-BP) {
        background: url(/media/backgrounds/PaintBackground-800x70.jpg) repeat 0 0;
        z-index: 4;
        border-bottom: 1px solid $gray-dots;
    }
    @media screen and (min-width: $desktop-BP) {
        background: none;
        padding: 0;
        margin-bottom: 3.3rem;
    }
    ul {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @media screen and (min-width: $desktop-BP) {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            gap: 0px 0px;
            justify-content: space-around;
        }

        li {
            padding: 0;
            text-align: center;
            border-right: 1px solid $gray-dots;
            margin: 0;

            @media screen and (min-width: $tablet-BP) {
                padding: 1rem 2rem 0.7rem 2rem;
            }
            
            @media screen and (min-width: $desktop-BP) {
                border-right: none;
                border-bottom: 1px solid $gray-dots;
                text-align: left;
                margin: 0;
                padding: 0;
            }
            &:last-child {
                border: none;
            }
            a {
                &.active {
                    color: $red;
                }
                display: block;
                text-decoration: none;
                font-family: $ff-Clawson-b;
                font-size: 1.6rem;
                @media screen and (min-width: $desktop-BP) {
                    padding: 2rem 0 1.5rem;
                    font-family: $ff-Clawson-b;
                    font-size: 2rem;
                }
            }
        }
    }
}