.product-price-card {
    font-family: $ff-SignPainter;
    color: $red;
    font-size: 4rem;
    padding: 0 2rem;

    @media screen and (min-width: $desktop-BP) {
        font-size: 5.5rem;
    }

    .has-variants {
        font-size: 3.5rem;
        grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem 1rem;

            & > li {
                display: flex;
                flex-direction: column;
                
                & > div {
                    display: flex;
                    @media screen and (min-width: $tablet-BP) {
                        align-items: center;
                    }
                    & > span {
                        margin: 0;
                    }
                    .cents-v {
                        font-size: 2.5rem;
                        margin-left: 0.6rem;
                        @media screen and (min-width: $desktop-BP) {
                            align-self: baseline;
                        }
                    }
                }
                
                &:nth-child(3n+2){
                    border-left: 1px solid $gray-dots;
                    border-right: 1px solid $gray-dots;
                    padding: 0 1.6rem;
                }
    
                &:nth-child(3n+3){
                    padding: 0 1.5rem;
                }
    
                &:last-child {
                    border-right: none;
                }
    
                .variant-title {
                    font-family: $ff-sansSerif;
                    font-size: 1.7rem;
    
                    @media screen and (min-width: $desktop-BP) {
                        font-size: 2rem;
                    }   
                }
            } 
    }

    & > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem 1rem;
       
        & > li {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            
            & > span {
                margin: 0;
            }

            .cents {
                font-size: 3rem;
                margin-left: 0.7rem;
            }
          
        } 
    }
    
    & > div {
        p {
            color: $dark-gray;
            font-weight: 100;
        }
    }
}