.filter-basic {
    padding: 0;

    &.is-small {
        height: auto;
        & > ul {
            & > li {
                border: none;
                & > span.checkcontainer {
                    & > label.locationname {
                        font-family: $ff-sansSerif;
                        padding: 1rem 0 1rem 2rem;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
        overflow: hidden;
        height: 0;

        @media screen and (min-width: $tablet-BP) {
            padding: 0;
            justify-content: center;
        }
        
        @media screen and (min-width: $desktop-BP) {
            height: auto !important;
        }

        li {
            padding: 0;
            text-align: center;
            margin: 0;

            @media screen and (min-width: $tablet-BP) {
                padding: 0rem 2rem 0.2rem 2rem;
                border-right: 1px solid $gray-dots;
            }
            
            @media screen and (min-width: $desktop-BP) {
                border-right: none;
                border-bottom: 1px solid $gray-dots;
                text-align: left;
                margin: 0;
                padding: 0;
            }
            &:last-child {
                border: none;
            }
        }
    }

    
}