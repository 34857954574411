.payment-information-form.payment-info {

    padding: 3.5rem 0;
    @media screen and (min-width: $desktop-BP) {
        padding: 4.5rem 0;
    }
    .generic-button {
        @media screen and (min-width: $desktop-BP) {
            line-height: 2rem;
        }
    }
    & > div {
        form {
            .payment {
                h3 {
                    margin: 0 0 2rem;
                    font-size: 2.7rem;
                    font-family: $ff-Cream-b;
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0 0 4rem;
                        font-size: 3.7rem;
                    }
                }
            }
            .payment-data {
                margin-bottom: 3rem;
                & > div {
                    @media screen and (min-width: $tablet-BP) {
                        display: grid;
                        grid-template-columns: auto 2fr;
                        gap: 9rem;
                    }
                    
                    & > ul {

                        &:first-child {
                            margin-bottom: 2rem;
                            @media screen and (min-width: $tablet-BP) {
                                margin: 0;
                            }
                        }

                        .title-Addresses-one,
                        .title-Addresses-two {
                            font-size: 2.2rem;
                            margin-bottom: 1rem;
                            text-transform: uppercase;
                            font-family: $ff-Clawson-b;
                        }

                        li {
                            font-family: $ff-ballinger;
                            font-size: 1.8rem;
                            font-weight: 300;
                            margin-bottom: 0.4rem;
                        }
                    }
                }
            }

            .billing {
                margin-bottom: 4rem;
                fieldset {
                    border: none;
                    padding: 0;
                    margin: 0;
                    legend {
                        font-size: 2.6rem;
                        margin-bottom: 1rem;
                        text-transform: uppercase;
                        font-family: $ff-Alternate;
                    }
                }
                .card-title {
                    font-size: 2.5rem;
                    text-transform: uppercase;
                    font-family: $ff-Alternate;
                    margin-bottom: 3rem;
                }

                .StripeElement {
                    margin-top: 2rem;
                }
            }

            .cta-prev-cont {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 5rem;
                @media screen and (min-width: $desktop-BP) {
                    gap: 41rem;
                }
                input {
                    margin: 0;
                }
            }

            .errors-styles {
                color: #CF1A0D;
                font-family: $ff-ballinger;
                font-size: 1.4rem;
                padding: 0.5rem 0;
            }
        }
    }
}