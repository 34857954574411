section.accordion-departments-item {
    border: 1px solid $gray-dots;
    background: $white;
    padding: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    & > div {
        padding: 0;
        & > div {

            &.expanded {
                margin-bottom: 1rem;

                
                .accordion-tab {
                    &::after {
                        transform: rotate(180deg);
                        border-color: $red;
                        transition: transform .5s ease-in-out;
                    }
                    @media screen and (min-width: $desktop-BP) {
                        color: $dark; 
                    }
                }
                
                .accordion-content {
                    margin-top: 2rem;
                    
                    @media screen and (min-width: $desktop-BP) {
                        border: none;
                    }
                }
            }
            
            .accordion-tab {
                position: relative;
                padding: 2rem 3rem 2rem 2rem;
                font-family: $ff-Clawson-b;
                font-weight: 100;
                text-transform: uppercase;
                cursor: pointer;
                margin-bottom: -1rem;
                border: none;
                background: none;
                width: 100%;
                text-align: left;

                &.big-extra-large {
                    font-size: 3.5rem;
                    @media screen and (min-width: $tablet-BP) {
                        font-size: 6rem;
                    }
                    @media screen and (min-width: $desktop-BP) {
                        font-size: 6rem;
                    }
                }
    
                @media screen and (min-width: $tablet-BP) {
                    padding: 2.5rem 5rem;
                }

                @media screen and (min-width: $desktop-BP) {
                    padding: 2.5rem 10rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 3rem;
                    height: 3rem;
                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat center center;
                    background-size: 2rem;
                    top: calc(50% - 1.5rem);
                    right: 1.5rem;
                    transition: transform .5s ease-in-out;

                    @media screen and (min-width: $tablet-BP) {
                        right: 5rem;
                    }
    
                    @media screen and (min-width: $desktop-BP) {
                        right: 10rem;
                    }
                }
            }

            .accordion-content {
                height: 0;
                overflow: hidden;
                visibility: hidden;
                & > .grid {
                    
                    border-top: 3px solid $dark;
                    border-bottom: 2px solid $dark-gray;
                    margin-bottom: 4rem;
                    margin: 0 2rem;
                    padding-top: 1.8rem;
                    padding-bottom: 3rem;

                    @media screen and (min-width: $tablet-BP) {
                        margin: 0 5rem;
                        display: grid;
                        gap: 0px 0px;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        grid-template-columns: 2.5fr 2fr;
                        grid-template-rows: 1fr;
                        margin: 0 10rem;
                    }
    
                    & > div {
                        &:first-child {   
                            p {
                                margin: 0;
                                font-family: $ff-ballinger;
                                font-weight: 300;
                                padding-right: 2rem;
                                padding-bottom: 3rem;
                                @media screen and (min-width: $tablet-BP) {
                                    padding-top: 1.1rem;
                                    padding-bottom: 0;
                                }

                                @media screen and (min-width: $desktop-BP) {
                                    padding-top: 1.4rem;
                                }
                            }
                        }
        
                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: flex-end; 
                            padding-top: 0.9rem;

                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 0 3rem;
                                padding-top: 2rem;
                            }

                            @media screen and (min-width: $desktop-BP) {
                                display: flex;
                                align-items: flex-end;
                                flex-direction: column;
                            }
                            
                            & > div {
                                width: 100%;
                                position: relative;

                                @media screen and (min-width: $desktop-BP) {
                                    width: 90%;
                                }

                                .red-arrow-lottie {
                                    cursor: pointer;
                                    position: relative;
                                    svg {
                                    
                                        position: absolute;
                                        right: -6%;
                                        bottom: -30%;
                                        max-width: 8rem;
                                        max-height: 6rem;

                                        @media screen and (min-width: $tablet-BP) {
                                            right: -9%;
                                            max-width: 13rem;
                                        }
                                        
                                        @media screen and (min-width: $desktop-BP) {
                                            max-width: 18rem;
                                            max-height: 10rem;
                                        }
                                    }
                                }

                            }     
                        } 
                    }
                }
            }

            .accordion-slider-wrapper {
                .slider-accordion-deparments-dots{
                    position: relative;
                    padding: 0;
                    
                    @media screen and (min-width: $desktop-BP) {
                        margin-top: 0;
                    }

                    ul {
                        bottom: 0.2rem;
                        @media screen and (min-width: $tablet-BP) {
                            bottom: 5rem;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            bottom: 6rem;
                        }

                        li {
                            
                            height: 10px;
                            width: 10px;
                            
                            &.slick-active {
                                button {
                                    &::before {
                                        background: $red;
                                        opacity: 1;
                                        transition: all 300ms linear;
                                    }
                                }
                            }

                            button {
                                
                                &::before {
                                    transition: all 300ms linear;
                                    content: "";
                                    background: $gray-dots;
                                    opacity: 1;
                                    border-radius: 1rem;
                                    width: 0.8rem;
                                    height: 0.8rem;

                                    @media screen and (min-width: $tablet-BP) {
                                        width: 1.2rem;
                                        height: 1.2rem;
                                        background: $white;
                                    }
                                }
                                
                                &:hover {
                                    &::before {
                                        background: $red;
                                        opacity: 1;
                                        transition: all 300ms linear;
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }

            .accordion-keywords {
                h4 {
                    font-family: $ff-Cream-b;
                    text-align: center;
                    margin-bottom: 2.5rem;
                }
                
                ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding:0;
                    margin: 0 10rem;
                    
                    li {
                        list-style-type: none;
                        margin: 0 0 2rem 0;
                        padding: 1rem 2rem;
                        text-align: center;
                        width: 25%;
                        span{
                            display: block;
                            img {
                                width: 85%;
                                height: auto;
                            }
                        }  
                    }
                }
            }

            .accordion-show-less {
                text-align: center;
                padding: 3rem 0;
                a {
                    font-family: $ff-sansSerif;
                    color: $red;
                    font-weight: bold;
                    text-decoration: underline;
                    font-size: 1.5rem;
                    font-style: italic;
                }
            }
        }
    }
}