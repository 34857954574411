.cart-item {
    border: 1px solid $gray-dots;
    background: $white;
    padding:4rem 2rem;
    margin-bottom: 3rem;
    & > .grid {
        @media screen and (min-width: $desktop-BP) { 
            display: grid;
            grid-template-columns: 1.5fr 5fr;
            gap: 0 2rem;
        }
        & > div {
            &:first-child {
                text-align: center;
                figure {
                    margin: 0;
                    img {
                        width: 50%;
                        height: 50%;
                        object-fit: cover;
                        @media screen and (min-width: $desktop-BP) { 
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                & > div {
                    border-bottom: 1px solid $gray-dots;
                    padding-bottom: 2rem;
                    h3 {
                        font-family: $ff-Cream-b;
                    }
                }
            }
            &:last-child {
                & > div {
                    & > div {
                        &:first-child {
                            @media screen and (min-width: $desktop-BP) { 
                                display: flex;
                                justify-content: space-between;
                                border-bottom: 1px solid $gray-dots;
                                margin-bottom: 3rem;
                                align-items: center;
                            }
                            h3 {
                                @media screen and (min-width: $desktop-BP) { 
                                    font-family: $ff-Cream-b;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}