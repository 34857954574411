div.accordion-departments-item-slider {
    margin-top: 4rem;
    & > div {
        max-height: 180px;
        @media screen and (min-width: $tablet-BP) {
            max-height: 320px;
        }

        @media screen and (min-width: $desktop-BP) {
            max-height: 420px;
        }
        @media screen and (min-width: $desktop-big-BP) {
            max-height: 560px;
        }
        img {
            padding:0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            @media screen and (min-width: $tablet-BP) {
                    padding:0 2rem;
            }
        }    
    }
}