section.card-text-center {
    padding: 0;
    
    & > div {
        position: relative;
        padding: 0;
        max-height: 20rem;
        overflow: hidden;
        margin-bottom: 0.5rem;
        border-top: 1px solid $gray-dots;
        border-bottom: 1px solid $gray-dots;

        @media screen and (min-width: $tablet-BP) {
            max-height: 23rem;
            border: none;
        }

        @media screen and (min-width: $desktop-BP) {
            max-height: 35rem;
        }

        a {
            display: block;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        
            & > div {
                position: absolute;
                background: $white;
                border: 1px solid $gray-dots;
                z-index: 1;
                top: 3rem;
                left: 3rem;
                right: 3rem;
                bottom: 3rem;
                text-align: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 40rem;
                margin: auto;
                transition: all .5s ease;

                @media screen and (min-width: $desktop-BP) {
                    top: 10rem;
                    left: 13rem;
                    right: 13rem;
                    bottom: 10rem 
                }

                h3 {
                    font-size: 3.3rem;
                    @media screen and (min-width: $tablet-BP) {
                        font-size: 3.3rem;
                    }
                }

                p {
                    width: 100%;
                }

                svg {
                    
                    display: none;
                    @media screen and (min-width: $desktop-BP) {
                        display:block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 80% !important;
                        height: 80% !important;
                        z-index: 2;
                    }
                }

                &:hover {
                    @media screen and (min-width: $desktop-BP) {
                    
                        transition: all .5s ease;
                        background: $red;

                        p {
                            color: $red;
                        }
        
                        h3 {
                        color: $red;
                        } 
                    }
                }
                
                p {
                    font-family: $ff-Cream-b;
                    margin: 0;
                }

                h3 {
                    font-family: $ff-Cream-b;
                    margin: 0;
                } 
            }
        }
    }
}