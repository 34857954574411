section.delivery-policy-content{

    & div {
        // padding: 0;
        // margin: 0 auto;
        
        p {
            font-weight: 300;
        }
    }
    & .zip-codes-grid ul {
        margin: 40px 0;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        list-style: none;
        padding: 0;
        text-align: center;
        font-weight: 300;
        font-size: 18px;
        font-family: $ff-ballinger;
    }
    @media screen and (min-width: $tablet-BP) {
        & .zip-codes-grid ul {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media screen and (min-width: $desktop-BP) {
        & .zip-codes-grid ul {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}