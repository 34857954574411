section.cta {
    margin: 5rem 0 5rem;

    & >div {
        background:$white;
        border: 1px solid $gray-dots;
        padding: 4em 3em;

        @media(min-width: $tablet-BP) {
            padding: 4em 6em;
        }
 
        &>div {
            &:first-child {
                p {
                    font-family: $ff-Cream-m;
                    color: $red;
                    line-height: 1.2;
                };
            }
        }
    }

}