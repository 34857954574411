div.price-product-detail {
    font-family: $ff-SignPainter;
    color: $red;
    font-size: 5rem;
  
    @media screen and (min-width: $tablet-BP) {
        font-size: 6rem;
    }

    & > div {
        .cents {
            font-size: 3rem;
            margin-left: 0.7rem;
        }
    }
}