.recipe-container.entry{
    display: block;

    .recipe-header{
        display: flex;
        flex-direction: column-reverse;
        background-color: #2C412E;
        color: $white;
        min-height: 600px;
        padding: 40px 0;

        .recipe-header-main{
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            margin-top: -25px;

            h1{
                font-family: $ff-Alternate;
                font-size: 6rem;
                text-transform: uppercase;
                width: 80%;
                margin: 20px auto;
            }

            .meta-data{
                text-transform: uppercase;
                font-family: $ff-Alternate-cond;
                font-size: 3rem;
            }
        }

        .recipe-header-image{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .image-wrapper{
                position: relative;
                width: auto;
                display: flex;
                justify-content: center;

                .sticker{
                    position: absolute; 
                    top: 0;
                    right: 0;
                    background-size: 8.3rem;
                    width: 10rem;
                    height: 10rem;
                    top: -5rem;
                    right: 2rem;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    z-index: 1;
                }
    
                .sticker:hover{
                    -webkit-transition: -webkit-transform .3s ease-in-out;
                    transition: -webkit-transform .3s ease-in-out;
                    transition: transform .3s ease-in-out;
                    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
                    -webkit-transform: rotate(9deg);
                    transform: rotate(9deg);
                }
    
                .sticker-new{
                    background-image: url(/media/recipes/WB-New.png);
                }
    
                .sticker-eat-good-food{
                    background-image: url(/media/recipes/WB-EatGoodFood.png);
                }
    
                img{
                    width: 90%;
                }
            }
        }
    }

    .recipe-content{
        display: grid;
        font-family: $ff-sansSerif;
        font-size: 3rem;
        margin: 0;

        .title{
            font-family: $ff-Alternate-cond;
            font-size: 4rem;
            text-transform: uppercase;
        }

        .recipe-ingredients{
            background-color: $red;
            color: $white;
            padding: 40px;

            .title{
                text-align: center;
            }

            .ingredients-button{
                display: flex;
                justify-content: center;

                .generic-button{
                    border: none;
                    border-radius: 6px;
                    padding: 10px 40px;
                }
                .generic-button:hover{
                    border: 1px solid $white;
                }
            }

            ul{
                margin: 20px 0;
                margin-left: 30px;

                li{
                    list-style-type: none;
                    font-size: 3rem;
                    text-transform: uppercase;
                    font-family: $ff-Alternate-cond;
                    margin-bottom: 30px;
                }
            }

        }

        .recipe-instructions{
            color: $black;
            padding: 40px;
            font-size: 2.5rem;

            ol{
                margin: 20px 0;
                margin-left: 30px;

                li{
                    list-style-type: decimal;
                    margin-bottom: 30px;
                }
            }
            p{
                font-size: 2.5rem;
            }

        }

    }

    .recipes-entries{
        background-image: url(/media/backgrounds/footer-bg.png);
    }

    @media screen and (min-width: $mobile-BP) {
        .recipe-header{
            display: grid;
            grid-template-columns: 1fr 1fr;

            .recipe-header-main{
                margin-top: 0;
            }

            .recipe-header-image{
                img{
                    border: 20px solid #EFEADF;
                    width: 70%;
                }
            }
        }

        .recipe-content{
            grid-template-columns: 1fr 2fr;
            margin: 20px 0;
        }

    }

    @media screen and (min-width: $tablet-BP) {
        .recipe-header{
            .recipe-header-image{
                .image-wrapper{
                    width: 80%;
                    .sticker{
                        background-size: 11.3rem;
                        width: 14rem;
                        height: 14rem;
                        top: -5rem;
                        right: 4rem;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $desktop-BP) {
        .recipe-header{
            .recipe-header-image{
                .image-wrapper{
                    width: 70%;
                }
                
            }
        }
        .recipe-content{
            .recipe-ingredients{
                padding: 50px;
            }
            .recipe-instructions{
                padding: 50px 120px;
            }
        }
    }

}