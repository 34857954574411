section.accordion-departments{
    padding: 0 2rem;
    @media screen and (min-width: $tablet-BP) {
        margin: 0 auto;
        margin-top: 4rem;
        margin-bottom: 6rem;
    }

    @media screen and (min-width: $desktop-BP) {
        margin-top: 6rem;
        padding: 0rem 1.5rem;
    }

    // @media screen and (min-width: $desktop-big-BP) {
    //     // margin-top: 10rem;
    //     margin-bottom: 8rem;
    // }

    & > div {
        padding: 0;
    }
}