div.product-title {
    margin-bottom: 1rem;

    @media screen and (max-width: $desktop-BP){
        padding: 20px;
        order: -1;
    }

    & > div {
        &:first-child {
            .product-title-heading{
                font-family: $ff-Cream-b;
                margin: 0;
                @media screen and (min-width: $desktop-BP) {
                    font-size: 4.5rem;
                }
            }

            .disclaimer {
                font-style: italic;
            }
            
        }

        &:nth-child(2) {
            margin-bottom: 1rem;
        }
        &:nth-child(2),
        &:nth-child(3) {
            font-family: $ff-ballinger;
            font-weight: 100;
            margin: 0;
            @media screen and (min-width: $desktop-BP) {
                font-size: 2rem;
            }
        }

        &:nth-child(2) {
            & > div {
                & > div {
                    & > div {
                        font-family: $ff-ballinger;
                        font-size: 1.5rem;
                        margin-bottom: 0.5rem;
                        font-weight: 400;
                        @media screen and (min-width: $tablet-BP) {
                            font-size: 1.5rem;
                        }
                        &:first-child {
                            margin-bottom: 1rem;
                        }
                        &:last-child {
                            margin-bottom: 3rem;
                        }
                    }
                }
            }
        }
    }
}
