/*-----------------------------------*\
	FONT SIZE PALETTE
\*-----------------------------------*/
.has-xs-font-size {
	font-size: 14px;
}

.has-small-font-size {
	font-size: 16px;
}

.has-medium-font-size {
	font-size: 18px;
}

.has-large-font-size {
	font-size: 24px;
}

.has-xl-font-size {
	font-size: 2.5rem;
	@media(min-width: $tablet-BP) {
		font-size: 1.6rem;
	}
	@media(min-width: $desktop-BP) {
		font-size: 2rem;
	}
}

/*-----------------------------------*\
	HEADER
\*-----------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	letter-spacing: 0;
	font-family: $ff-sansSerif;
	font-weight: 700;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: 40px;

	@media(min-width: $tablet-BP) {
		font-size: 70px;
	}
}

h2,
.h2 {
	font-size: 32px;

	@media(min-width: $tablet-BP) {
		font-size: 55px;
	}
}

h3,
.h3 {
	font-size: 26px;

	@media(min-width: $tablet-BP) {
		font-size: 35px;
	}

	&.small {
		font-size: 2.5rem;
	}

	&.medium {
		font-size: 3rem;
		@media(min-width: $tablet-BP) {
			font-size: 5rem;
		}
		@media(min-width: $desktop-BP) {
			font-size: 5.2rem;
		}
		&.house-slant {
			font-size: 4.5rem;
			@media(min-width: $desktop-BP) {
				font-size: 5.5rem;
			}
		}

		&.medium-inter {
			@media(min-width: $desktop-BP) {
				font-size: 4rem;
			}
		}
	}

	&.medium-2 {
		font-size: 3.5rem;
	}

	&.is-large {
		font-size: 4.5rem;
		@media(min-width: $desktop-BP) {
			font-size: 5rem;
		}
	}

	&.big {
		font-size: 4.5rem;
		@media screen and (min-width: $tablet-BP) {
			font-size: 5rem;
		}
		@media(min-width: $desktop-BP) {
			font-size: 6rem;
		}

		&.big-inter {
			font-size: 4.3rem;
		}

		&.big-large {
			@media screen and (min-width: $tablet-BP) {
				font-size: 7rem;
			}

			@media screen and (min-width: $tablet-BP) {
				font-size: 9rem;
			}
		}
	}
	
	&.extra-big {
		font-size: 6rem;
	}

	&.big-extra-large {
		font-size: 3.5rem;
		@media screen and (min-width: $tablet-BP) {
			font-size: 6rem;
		}
		@media screen and (min-width: $desktop-BP) {
			font-size: 8rem;
		}
	}
}

h4,
.h4 {
	font-size: 23px;

	@media(min-width: $tablet-BP) {
		font-size: 26px;
	}

	&.inter {
		font-size: 3rem;
	}

	&.is-big {
		@media(min-width: $tablet-BP) {
			font-size: 4rem;
		}
	}
}

h5,
.h5 {
	font-size: 16px;

	@media(min-width: $tablet-BP) {
		font-size: 25px;
	}
}

h6,
.h6 {
	font-size: 14px;

	@media(min-width: $tablet-BP) {
		font-size: 16px;
	}
}

/*-----------------------------------*\
	PARAGRAPH
\*-----------------------------------*/
p {
	font-size: 17px;
	line-height: 1.6;
	font-family: $ff-sansSerif;

	@media(min-width: $tablet-BP) {
		font-size: 18px;
	}

	&.is-small {
		font-size: 14px;
	}

	&.is-large {
		font-size: 18px;

		@media(min-width: $tablet-BP) {
			font-size: 28px;
		}

		&.is-large-2 {
			@media(min-width: $tablet-BP) {
				font-size: 22px;
			}
			@media screen and (min-width: $desktop-BP) {
				font-size: 18px;
			}
			@media(min-width: $desktop-big-BP) {
				font-size: 20px;
			}
			@media screen and (min-width: $desktop-extra-big-BP) {
				font-size:24px;
			}
		}
	}

	&.is-extra-large {
		font-size: 20px;
		line-height: 1.5;

		@media(min-width: $tablet-BP) {
			font-size: 50px;
			line-height: 1.2;
		}
	}

	&.is-large-bold {
		font-size: 17px;
		font-weight: 700;

		@media(min-width: $tablet-BP) {
			font-size: 28px;
		}
	}

	&.is-serif {
		font-family: $ff-serif;
	}

	&.is-serif-large {
		font-family: $ff-serif;
		font-size: 15px;

		@media(min-width: $tablet-BP) {
			font-size: 20px;
		}
	}

	&.is-serif-extra-large {
		font-family: $ff-serif;
		font-size: 21px;

		@media(min-width: $tablet-BP) {
			font-size: 50px;
		}
	}

	&.is-bold {
		font-weight: bold;
	}

	&.is-extra-l-2 {
		font-size: 20px;
	}

}

a, p a {
	color: $black;
	// //outline: none;
	
	// &:active,&:focus{
	// 	//outline: none;
	// }

	&.is-small {
		font-size: 14px;
	}

	&.is-small-two {
		font-size: 16px;
		
	}

	&.is-a-inter {
		font-size: 1.6rem;
		@media(min-width: $tablet-BP) {
			font-size: 2rem;
		}
	}


	&.is-a-medium {
		font-size: 3rem;
		@media(min-width: $tablet-BP) {
			font-size: 2.7rem;
		}

		@media(min-width: $desktop-BP) {
			font-size: 3rem;
		}

		.is-a-medium-2 {
			@media(min-width: $tablet-BP) {
				font-size: 2rem;
			}
		}
	}

}

.p-is-small-inter {
	font-size: 1.7rem;
	@media screen and (min-width: $desktop-big-BP) {
		font-size: 2rem;
	}
}
.is-p-medium {
	font-size: 15px;
}

.p-is-inter {
	font-size: 2.5rem;
	@media(min-width: $tablet-BP) {
		font-size: 2.5rem;
	}
}

/*-----------------------------------*\
	FONTS WEIGHT HELPERS
\*-----------------------------------*/

.fw-l {
	font-weight: lighter;
}

.fw-3 {
	font-weight: 300;
}


/*-----------------------------------*\
	HORIZONTAL RULE
\*-----------------------------------*/
hr {
	margin: 3em auto;
	height: 3px;
	background-color: $black;
	border: none;

	&.is-style-wide {
		margin: 6em auto;
		max-width: 1024px;
	}

	&.is-style-dots::before {
		font-weight: bold;
		font-size: 35px;
	}
}

li {
	&.is-small-1 {
		font-size: 1.5rem;	
	}
}