section.explore-careers {
    max-width: 100%;
    padding:0;
    & > div {
        padding:0;
        max-width: none;
        padding-bottom: 4rem;
        margin-bottom: 4rem;

        @media screen and (min-width: $tablet-BP) {
            margin-top: 3rem;
            margin-bottom: 6rem;
        }
        @media screen and (min-width: $desktop-BP) {
            padding-bottom: 0;
            margin-top: 6rem;
            margin-bottom: 10rem;
        }

        .grid {
            position: relative;
            
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: 1fr;
                gap: 0px 0px;
            }
            & > div {

                &:first-child {
                    position: absolute;
                    left: 2rem;
                    right: 2rem;
                    height: 6rem;
                    bottom: -5rem;
                    background: $white;
                    border: 1px solid $gray-dots;
                    border-top: none;

                    @media screen and (min-width: $tablet-BP) { 
                        height: 3rem;
                        bottom: -2rem;
                    }

                    @media screen and (min-width: $desktop-BP) { 
                        grid-area:1 / 2 / 2 / 12; 
                        border: 1px solid $gray-dots;
                        position: static;
                        left: auto;
                        right: auto;
                        height: auto;
                        bottom: auto;
                    }  
                }
    
                &:nth-child(2) {
                    padding: 0 2rem;
                    background: #fff;
                    border: 1px solid $gray-dots;
                    margin: 0 2rem;

                    @media screen and (min-width: $desktop-BP) { 
                        grid-area:  1 / 2 / 2 / 6;
                        padding:0;
                        background: none;
                        border: none;
                        padding: 4rem 0 4rem 2rem;
                    }

                    @media screen and (min-width: $desktop-big-BP) { 
                        padding-left: 5rem;
                        padding-top: 4rem;
                    }                 

                    h3 {
                        font-family: $ff-Cream-b;
                        padding-top: 2rem;

                        @media screen and (min-width: $desktop-BP) { 
                            padding-top: 0;
                            margin-top: 0;
                            font-size: 3rem;
                        }
                        @media screen and (min-width: $desktop-big-BP) { 
                            margin-top: 3rem;
                            font-size: 3.5rem;
                        }
                        @media screen and (min-width: $desktop-extra-big-BP) { 
                            font-size: 4rem;
                        }

                    }

                    p {
                        font-family: $ff-ballinger;

                        @media screen and (min-width: $desktop-BP) { 
                          margin: 1rem 0 2rem 0;
                          font-size: 15px;
                        }
                        @media screen and (min-width: $desktop-big-BP) { 
                            font-size: 17px;
                            padding-right: 8rem;
                        }
                        @media screen and (min-width: $desktop-extra-big-BP) { 
                            margin: 3rem 0;
                        }
                    }

                    & > div {

                        margin-bottom: 1.5rem;

                        @media screen and (min-width: $tablet-BP) { 
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 0 3rem;
                            margin-bottom: 3rem;
                        }  

                        @media screen and (min-width: $desktop-BP) { 
                            display: block;
                            margin: 0 0 0.5rem 0;
                            width: 65%;
                            max-width: 30rem;
                        }

                        @media screen and (min-width: $desktop-big-BP) { 
                            display: block;
                           
                        }
                        
                        & > div {
                            margin: 2rem 0;

                            @media screen and (min-width: $desktop-BP) {  
                                margin: 0 0 1rem 0; 
                            }
                        }
                    }
                }
    
                &:last-child {
                    position: relative;
                    @media screen and (min-width: $desktop-BP) { 
                      grid-area:1 / 6 / 2 / 13;
                      max-width: 100%;
                      padding: 4rem 0;
                     
                    }

                    span  {
                        background:url(/media/globals/icon-slider-buttom.svg) no-repeat 0 0;
                        background-size: 13rem 13rem;
                        position:absolute;
                        width: 13rem;
                        height: 13rem;
                        left: 0;
                        bottom: -8rem;
                        zoom: 1;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                   

                        @media screen and (min-width: $tablet-BP) { 
                            left: 4rem;
                            background-size: 17rem 17rem;
                            width: 17rem;
                            height: 17rem;
                        }

                        @media screen and (min-width: $desktop-BP) { 
                            left: -10rem;
                            bottom: -6rem;
                            transition: transform .2s ease-in-out;
                            &:hover {
                                transition: transform .2s ease-in-out;
                                transform: rotate(4deg);  
                            }
                        }

                        a {
                            font-family: $ff-HouseSlam-r;
                            color: $white;
                            transform: rotate(-12deg);
                            display:block;
                            transition: transform .3s ease-in-out;
                            text-decoration: none;
                            font-size: 1.7rem;
                            text-align: center;
                            padding:9px;
                            letter-spacing: 1px;
                            @media screen and (min-width: $tablet-BP) {
                                font-size: 2.2rem;
                            }
                        } 
                    }
                }
            }
        } 
    }
}