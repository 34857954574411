section.apply-form-flexbox {
    @media screen and (min-width: $tablet-BP) {
        padding: 0 2rem;
    }
    @media screen and (min-width: $desktop-BP) {
        padding: 0 4rem;
    }

    & > div {
        padding: 0;

        @media screen and (min-width: $desktop-BP) {
            padding: 0 2rem;
        }
        & > div {
            margin: 0;
            border: 1px solid $gray-dots;
            padding: 4rem 2rem;
            background: $white;
            
            @media screen and (min-width: $tablet-BP) {
                margin-bottom: 2rem;
                margin-top: 3rem;
            }
            @media screen and (min-width: $desktop-BP) {
                padding: 6rem;
                margin-bottom: 6rem;
                margin-top: 6rem;
            }

            h2 {
                font-family: $ff-Cream-b;
                font-size: 2.7rem;
                @media screen and (min-width: $tablet-BP) {
                    font-size: 4.1rem;
                }
            }

            form {
                .ff-form-errors {
                    border: 1px solid $gray-dots;
                    padding: 1rem;
                    color:$red;
                    font-size: 1.7rem;
                    text-align: center;
                    margin: 2rem 0px;
                    p {
                        margin: 0;
                    }
                }

                .freeform-custom-radio {
                    .checkcontainer {
                        font-size: 1.8rem;
                        font-family: $ff-Cream-r;
                        font-style: italic;
                        margin: 1rem 0 0.5rem 0;
                        display: inline-block;
                    }
                }

                & > div {
                    &.freeform-progess {
                        background-color: $gray-bg-soft;
                        width: 100%;
                        position: relative;
                        height: 1rem;
                        margin-top: 1rem;
                        margin-bottom: 2rem;
                        > .freeform-progress-bar {
                            background: $red;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                        }
                    }
                    &.freeform-steps {
                        font-family: $ff-Cream-r;
                        font-style: italic;
                        font-size: 1.5rem;
                    }

                    &.freeform-row {

                          // init global styles
                        .freeform-column {
                            position: relative;
                            margin: 2rem 0;
                            h3 {
                                font-family: $ff-Cream-b;
                            }
                            label.freeform-label {
                                font-family: $ff-Alternate;
                                font-size: 2.4rem;
                                text-transform: uppercase;
                                margin-bottom: 1rem;
                                display: block;
                                @media screen and (min-width: $tablet-BP) {
                                    font-size: 2.6rem;
                                }
                            }
                            .freeform-input {
                                // //outline: none;
                                background: $gray-bg-soft;
                                border: none;
                                padding: 1.2rem;
                                border-radius: 0;
                            }
                            input {
                                font-size: 1.8rem;
                                &::placeholder {
                                    font-style: italic;
                                    font-family: $ff-Cream-r;
                                    font-size: 1.8rem;
                                    color: $dark;
                                }
                            }
                            .input-group-one-line {
                                font-family: $ff-Cream-b;
                                border-bottom: 1px solid #ccc;
                                padding: 2rem 0;
                                display: flex;
                                flex-direction: column;
                                @media screen and (min-width: $tablet-BP) {
                                    flex-direction: row;
                                }
                                input[type='radio']:after {
                                    width: 15px;
                                    height: 15px;
                                    border-radius: 15px;
                                    top: -2px;
                                    left: -1px;
                                    position: relative;
                                    background-color: $white;
                                    content: '';
                                    display: inline-block;
                                    visibility: visible;
                                    border: 1px solid $gray-dots;
                                }
                                input[type='radio']:checked:after {
                                    background-color:$red;
                                }
                                label {
                                    font-family: $ff-Cream-b;
                                    font-size: 2rem;
                                }
                                @media screen and (min-width: $tablet-BP) {
                                    font-size: 2.2rem;
                                }
                            }
                            input[type=checkbox],
                            input[type="radio"] {
                                -webkit-appearance: none;
                                -moz-appearance:    none;
                                appearance:         none;
                            }

                            input[type='checkbox'] {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                // //outline: none;
                                position: relative;
                                width: 2rem;
                                height: 2rem;
                                
                                cursor: pointer;
                            }
                            input[type='checkbox']::before {
                                content: '';
                                color: #fff;
                                position: absolute;
                                top: 4px;
                                right: 4px;
                                bottom: 4px;
                                left: 4px;
                                background-color: transparent;
                                background-size: contain;
                                background-position: center center;
                                background-repeat: no-repeat;
                                -webkit-transform: scale(0);
                                        transform: scale(0);
                                -webkit-transition: -webkit-transform 0.25s ease-in-out;
                                transition: -webkit-transform 0.25s ease-in-out;
                                transition: transform 0.25s ease-in-out;
                                transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
                                background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=");
                                filter: invert(16%) sepia(97%) saturate(3253%) hue-rotate(356deg) brightness(91%) contrast(97%);
                            }
                            
                            input[type='checkbox']:checked::before {
                                -webkit-transform: scale(1);
                                        transform: scale(1);
                            }
                            textarea {
                                resize: vertical;
                                background: $gray-bg-soft;
                                border: none;
                                min-height: 70px;
                                // margin-bottom: 2rem;
                                // //outline: none;
                                padding: 2rem;
                                font-size: 1.7rem;
                                &::placeholder{
                                    color: $dark;
                                }
                            }
                        }
                         // end global styles

                        //init all pages
                        &.freeform-row-rich-text {
                            border-bottom: 1px solid $gray-dots !important;
                            
                            .freeform-column {
                                margin-bottom: 1rem;
                                p {
                                    border-bottom: 1px solid $gray-dots;
                                    margin: 0;
                                    padding-bottom: 1rem;
                                    text-transform: uppercase; 
                                    font-family: $ff-Alternate;
                                    font-size: 2.4rem;
                                    font-weight: 100;
                                    margin-bottom: 2rem;

                                    @media screen and (min-width: $tablet-BP) {
                                        font-size: 2.6rem;
                                    }
                                    strong {
                                        font-family: $ff-Clawson-b;
                                        
                                    }
                                }
                            }
                        }

                        &.freeform-row-sunday-start-time,
                        &.freeform-row-monday-start-time,
                        &.freeform-row-tuesday-start-time,
                        &.freeform-row-wednesday-start-time,
                        &.freeform-row-thursday-start-time,
                        &.freeform-row-friday-start-time,
                        &.freeform-row-saturday-start-time {
                            .freeform-column {
                                margin-bottom: 2rem;
                            }
                        }
                        &.freeform-row-sunday-start-time {
                            .freeform-column {
                                margin-top: 2rem !important;
                            }
                        }

                        &.freeform-row-applying-for,
                        &.freeform-row-have-you-attended-high-school {
                            margin-top: 2rem
                        }

                        &.freeform-row-first-name {
                            margin-bottom: 3rem;
                            @media screen and (min-width: $tablet-BP) {
                                margin-bottom: 0;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                gap: 2rem;
                            }
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        &.freeform-row-address {
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        &.freeform-row-city {
                            margin-bottom: 3rem;
                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 2rem;
                                margin-bottom: 0;
                            }
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        &.freeform-row-phone-number {
                            margin-bottom: 4rem;
                            .freeform-column {
                                margin-top: 2rem;
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        // buttons next and prev
                        &.freeform-row-next,
                        &.freeform-row-next-step,
                        &.freeform-row-submit {
                            .freeform-column,
                            .freeform-column-content-align-spread {

                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 4rem;
                                // text-align: right;
                                @media screen and (min-width: $tablet-BP) {
                                    display: block;
                                    text-align: right;
                                }
                                button {
                                    background-color: $white;
                                    color: $red;
                                    font-size: 1.9rem;
                                    text-decoration: none;
                                    letter-spacing: 1px;
                                    text-align: center;
                                    border: 1px solid $gray-dots;
                                    font-family: $ff-Alternate-cond;
                                    text-transform: uppercase;
                                    padding: 1rem;
                                    width: auto;
                                    min-width: 40%;
                                    transition: all .2s ease-in;
                                    cursor: pointer;
                            
                                    @media screen and (min-width: $tablet-BP) {
                                        width: auto;
                                        font-size: 2rem;
                                        max-width: none;
                                        min-width: auto;
                                        padding: 1rem 2rem;
                                    }
                                    &:hover {
                                        @media screen and (min-width: $desktop-BP) {
                                            background-color:$red !important;
                                            transition: all 275ms ease;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                            .freeform-column-content-align-spread {
                                display: flex;
                                justify-content: space-between;
                            }
    
                            .freeform-column-content-align-spread {
                                button,
                                input[type="submit"]{
                                    background-color: $white;
                                    color: $red;
                                    font-size: 1.9rem;
                                    text-decoration: none;
                                    letter-spacing: 1px;
                                    text-align: center;
                                    border: 1px solid $gray-dots;
                                    font-family: $ff-Alternate-cond;
                                    text-transform: uppercase;
                                    padding: 1rem;
                                    width: auto;
                                    min-width: 40%;
                                    transition: all .2s ease-in;
                                    cursor: pointer;
                            
                                    @media screen and (min-width: $tablet-BP) {
                                        width: auto;
                                        font-size: 2rem;
                                        max-width: none;
                                        min-width: auto;
                                        padding: 1rem 2rem;
                                    }
                                    &:hover {
                                        @media screen and (min-width: $desktop-BP) {
                                            background-color:$red !important;
                                            transition: all 275ms ease;
                                            color: $white;
                                        }
                                    }
                                } 
                            }
                        }

                        &.freeform-row-have-you-ever-worked-at-westborn,
                        &.freeform-row-has-a-member-of-your-family-ever-worked-for-westborn,
                        &.freeform-row-have-you-ever-been-convicted-of-a-felony,
                        &.freeform-row-have-you-attended-high-school,
                        &.freeform-row-have-you-attended-a-technicalvocational-school,
                        &.freeform-row-have-you-attended-collegeuniversity,
                        &.freeform-row-workload,
                        &.freeform-row-sunday-start-time,
                        &.freeform-row-monday-start-time,
                        &.freeform-row-tuesday-start-time,
                        &.freeform-row-wednesday-start-time,
                        &.freeform-row-thursday-start-time,
                        &.freeform-row-friday-start-time,
                        &.freeform-row-saturday-start-time,
                        &.freeform-row-name-of-emergency-contact,
                        &.freeform-row-home-phone,
                        &.freeform-row-supervisors-name,
                        &.freeform-row-start-date,
                        &.freeform-row-starting-wagesalary,
                        &.freeform-row-reason-for-leaving,
                        &.freeform-row-zip-code,
                        &.freeform-row-position,
                        &.freeform-row--zip-code,
                        &.freeform-row-applying-for,
                        &.freeform-row-are-you-at-least-18 {
                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 2rem;
                            }
                            
                            .freeform-column {
                                margin: 0;
                                .input-group-one-line {
                                    border: none;
                                }
                            }
                            textarea, input[type="text"] {
                                width: 100%;
                            }
                        }

                        &.freeform-row-applying-at  {
                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                gap: 2rem; 
                            }
                        }
                        &.freeform-row-have-you-ever-been-convicted-of-a-felony {
                            border-bottom: 1px solid $gray-dots;
                            margin-bottom: 4rem;
                        }

                        &.freeform-row-what-are-your-personal-interests,
                        &.freeform-row-special-training-or-skills {
                            padding-top: 2rem;
                            @media screen and (min-width: $tablet-BP) {
                                padding-top: 0;
                            }
                            .freeform-input {
                                display: flex;
                                flex-direction: column;
                                -webkit-appearance: none;
                                border-radius: 0;
                            }
                            textarea {
                                width: 100%;
                                min-height: 70px;
                            }
                        }
                        &.freeform-row-what-are-your-personal-interests { 
                            border-top: 1px solid $gray-dots;
                        }
                        
                        &.freeform-row-are-you-legally-authorized-to-live-and-work-in-the-us {
                            border-top: 1px solid $gray-dots;
                        }

                        &.freeform-row-how-did-you-hear-about-westborn,
                        &.freeform-row-position-applying {
                            border-bottom: 1px solid $gray-dots;
                            margin-bottom: 2rem;
                            
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                                @media screen and (min-width: $tablet-BP) {
                                }
                                
                                .input-group-one-line {
                                    border: none;
                                }
                                .freeform-input {
                                    margin: 0;
                                    font-size: 1.8rem;
                                    -webkit-appearance: none;
                                    border-radius: 0;
                                    
                                    .selected {
                                        background-color: #e6e0d3;
                                        color: $red;
                                    }
                                }
                            }
                        }

                        &.freeform-row-applying-at  {
                            border-bottom: 1px solid $gray-dots;
                            .freeform-column {
                                margin-bottom: 2rem;
                            }
                        }

                        &.freeform-row-how-did-you-hear-about-westborn,
                        &.freeform-row-are-you-at-least-18 {
                            .selected {
                                background-color: #e6e0d3;
                                color: $red;
                            }
                        }

                        &.freeform-row-which-store-or-department {
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                                .freeform-input {
                                    // //outline: none;
                                    background: #F5F2EB;
                                    border: none;
                                    padding: 1.2rem 1.7rem;
                                    width: 100%;
                                    -webkit-appearance: none;
                                    border-radius: 0; 
                                }
                            }
                        }
                        
                        &.freeform-row-sunday-start-time,
                        &.freeform-row-monday-start-time,
                        &.freeform-row-tuesday-start-time,
                        &.freeform-row-wednesday-start-time,
                        &.freeform-row-thursday-start-time,
                        &.freeform-row-friday-start-time,
                        &.freeform-row-saturday-start-time,
                        &.freeform-row-home-phone,
                        &.freeform-row-name-of-emergency-contact,
                        &.freeform-row-supervisors-name,
                        &.freeform-row-zip-code,
                        &.freeform-row-start-date,
                        &.freeform-row-position,
                        &.freeform-row-starting-wagesalary,
                        &.freeform-row-reason-for-leaving,
                        &.freeform-row--zip-code,
                        &.freeform-row-have-you-ever-worked-at-westborn,
                        &.freeform-row-has-a-member-of-your-family-ever-worked-for-westborn,
                        &.freeform-row-have-you-ever-been-convicted-of-a-felony,
                        &.freeform-row-highest-year-completed {
                            .freeform-column {
                                &:first-child {
                                    margin-bottom: 2rem;
                                }
                            }
                        }

                        &.freeform-row-have-you-ever-worked-at-westborn,
                        &.freeform-row-has-a-member-of-your-family-ever-worked-for-westborn,
                        &.freeform-row-have-you-ever-been-convicted-of-a-felony,
                        &.freeform-row-workload,
                        &.freeform-row-start-date,
                        &.freeform-row-starting-wagesalary {
                            .freeform-column {
                                &:last-child {
                                    margin-bottom: 2rem;
                                }
                            }
                        }

                        &.freeform-row-are-you-at-least-18,
                        &.freeform-row-have-you-ever-worked-at-westborn,
                        &.freeform-row-have-you-attended-a-technicalvocational-school,
                        &.freeform-row-name-of-emergency-contact,
                        &.freeform-row-applying-at   {
                            margin-top: 2rem;
                        }

                        &.freeform-row-have-you-attended-high-school,
                        &.freeform-row-did-you-receive-a-diploma,
                        &.freeform-row-have-you-attended-collegeuniversity,
                        &.freeform-row-have-you-attended-a-technicalvocational-school {
                            .freeform-column {
                                margin-bottom: 2rem;
                            }
                            
                        }

                        &.freeform-row-highest-year-completed,
                        &.freeform-row-did-you-receive-a-diploma {
                            border-bottom: 1px solid $gray-dots;
                        }

                        &.freeform-row-attendance-start-date,
                        &.freeform-row-attendance-start-date--technical,
                        &.freeform-row-majorsminors-technical,
                        &.freeform-row-attendance-start-date-college,
                        &.freeform-row-majorsminors-college,
                        &.freeform-row-supervisors-name {
                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 2rem;
                            }
                            .freeform-column {
                                .freeform-input {
                                    width: 100%;
                                    -webkit-appearance: none;
                                    border-radius: 0;
                                }
                            }
                        }
                        &.freeform-row-majorsminors-technical,
                        &.freeform-row-majorsminors-college {
                            border-bottom: 1px solid $gray-dots;
                            margin-bottom: 2rem;
                        }
                        &.freeform-row-majorsminors-college {
                            margin-bottom: 4rem;
                        }
                        
                        &.freeform-row-which-departments-are-you-interested-in {
                            .freeform-column {
                                .input-group-one-line {
                                    display: grid;
                                    gap: 1rem;
                                    align-items: center;
                                    @media screen and (min-width: $tablet-BP) {
                                        grid-template-columns: 1fr 1fr;
                                        gap: 1rem 7rem;
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        grid-template-columns: 1fr 1fr 1fr;
                                        gap: 1rem 19rem;
                                    }
                                }
                            }
                        }

                        &.freeform-row-which-departments-are-you-interested-in,
                        &.freeform-row-workload{
                            .freeform-column {
                                .input-group-one-line {
                                    .freeform-custom-check{
                                        label {
                                            margin-top: 4px;
                                            margin-left: 0.7rem;
                                        }

                                    }
                                }
                            }
                            &.freeform-row-workload {
                                .input-group-one-line {
                                    .freeform-custom-check{
                                        &:last-child {
                                            @media screen and (min-width: $tablet-BP) {
                                                margin-left: 1rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.freeform-row-i-agree-to-the-above-statement {
                            .freeform-column {
                                label {
                                    display: flex;
                                    
                                    @media screen and (min-width: $tablet-BP) {
                                        align-items: flex-end;
                                    }
                                }
                                input {
                                    margin-right: 1rem;
                                }
                            }
                        }


                        &.freeform-row-workload  {
                            border-bottom: 1px solid $gray-dots;
                            margin-bottom: 2rem;
                            .freeform-column {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        &.freeform-row-rich-text{
                            border: none;
                            .freeform-column{
                                p {
                                    font-size: 1.8rem;
                                    font-family: $ff-Cream-b;
                                    text-transform: none;
                                    border: none;
                                    margin: 0;
                                    strong {
                                        font-size: 2rem;
                                        
                                    }
                                }
                            }
                        }

                        &.freeform-row-home-phone {
                            .freeform-column {
                                border-bottom: 1px solid $gray-dots;
                                margin-bottom: 4rem;
                            }
                        }

                        &.freeform-row-may-we-contact  {
                            border-bottom: 1px solid $gray-dots;
                        }

                        &.freeform-row--may-we-contact {
                            margin-bottom: 4rem;
                        }

                        &.freeform-row-home-phone,
                        &.freeform-row-employer-company,
                        &.freeform-row-street-address,
                        &.freeform-row-zip-code,
                        &.freeform-row-position,
                        &.freeform-row--zip-code,
                        &.freeform-row-starting-wagesalary {
                            .freeform-column {
                                .freeform-input {
                                    width: 100%; 
                                    -webkit-appearance: none;
                                    border-radius: 0;
                                }
                            }
                        }

                        &.freeform-row-applying-for,
                        &.freeform-row-are-you-at-least-18{
                            padding-bottom: 1.5rem;
                            border-bottom: 1px solid $gray-dots;
                            .freeform-column {
                                position: relative;
                                select {
                                    background-image: url(/media/globals/arrow-accordion-2.svg);
                                    background-repeat: no-repeat;
                                    background-position: 97% center;
                                    background-size: 1rem;
                                    background-color: #F5F2EB;
                                    // //outline: none;
                                    border: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                                    font-family: "Cream-Regular";
                                    padding: 1.3rem 1.7rem 1.4rem 1.7rem;
                                    font-size: 1.7rem;
                                    margin: 1rem 0 0.5rem 0;
                                    width: 100%;
                                    font-family: "Cream-Bold";;
                                    @media screen and (min-width: $tablet-BP) {
                                        padding: 1rem 3.5rem 1rem 0.5rem;
                                    }	
                                    @media screen and (min-width: $desktop-BP) {
                                        padding: 1.3rem 1.7rem 1.4rem 1.7rem;
                                    }
                                }
                            }
                        }

                        &.freeform-row-applying-at, 
                        &.freeform-row-city,
                        &.freeform-row-highest-year-completed  {
                            .freeform-column {
                                position: relative;
                                select {
                                    background-image: url(/media/globals/arrow-accordion-2.svg);
                                    background-repeat: no-repeat;
                                    background-position: 97% center;
                                    background-size: 1rem;
                                    background-color: #F5F2EB;
                                    // //outline: none;
                                    border: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;
                                    font-family: "Cream-Regular";
                                    padding: 1.3rem 1.7rem 1.4rem 1.7rem;
                                    font-size: 1.7rem;
                                    width: 100%;
                                    font-family: "Cream-Bold";;
                                    @media screen and (min-width: $tablet-BP) {
                                        padding: 1rem 3.5rem 1rem 0.5rem;
                                    }	
                                    @media screen and (min-width: $desktop-BP) {
                                        padding: 1.3rem 1.7rem 1.4rem 1.7rem;
                                    }
                                }
                            }

                            &.freeform-row-highest-year-completed {
                                .freeform-column {
                                    select {
                                        width: 50%;
                                    }
                                }
                            }
                        }

                        .parsley-errors-list {
                            .parsley-string,
                            .parsley-type,
                            .parsley-required,
                            .parsley-address,
                            .parsley-hours {
                                color: #CF1A0D;
                                font-family: "ballinger", sans-serif;
                                font-size: 1.4rem;
                                padding: 0.5rem 0;
                            }
                        }

                        .ff-errors {
                            padding: 0.3rem 1rem;
                        }
                        .flatpickr-hour {
                            height: 3rem;
                            width: 10rem;
                        }
                        &.freeform-row-you-may-upload-your-resume {
                            margin-bottom: 4rem;
                            input[type='file']{
                                max-width: 100%;
                            }
                        }

                        &.freeform-row-which-departments-are-you-interested-in,
                        &.freeform-row-workload {
                            .freeform-column {
                                label {
                                    font-size: 2rem;
                                    font-family: $ff-Clawson-b;  
                                }
                            }
                        }

                        //mobile
                        &.freeform-row-applying-for {
                            .freeform-column {
                                
                                    margin-top: 2rem;
                                    @media screen and (min-width: $tablet-BP) {
                                        margin: 0;
                                    }
                                
                            }
                        }
                        &.freeform-row-are-you-at-least-18 {
                            .freeform-column {
                                &:first-child {
                                    margin-bottom: 2rem;
                                    @media screen and (min-width: $tablet-BP) {
                                        margin: 0;
                                    }
                                }
                                
                            }
                            
                        }
                        &.freeform-row-home-phone {
                            .freeform-column {
                                border: none;
                            }
                        } 
                    }
                }
            }
        }
    }
}