.filters-sticky-items {
    padding: 0;
    @media screen and (min-width: $tablet-BP) {
        background: url(/media/backgrounds/PaintBackground-800x70.jpg) repeat 0 0;
        z-index: 4;
        border-bottom: 1px solid $gray-dots;
    }
    @media screen and (min-width: $desktop-BP) {
        background: none;
        margin-bottom: 3.3rem;
        
    }

    &.small {
        height: auto;
        & > ul {
            & > li {
                border: none;
                & > span.checkcontainer {
                    & > label.locationname {
                        font-family: $ff-sansSerif;
                        padding: 1rem 0;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2rem 1.5rem 2rem;
        border-bottom: 1px solid $gray-dots;
        margin-bottom: 1.5rem;
        @media screen and (min-width: $tablet-BP) {
            padding: 1rem 2rem 1rem ;
            margin: 0;
            justify-content: center;
        }
        @media screen and (min-width: $desktop-BP) {
            display: block;
            padding: 0;
            border: none;
        }
        h3 {
            font-size: 28px;
            font-family: $ff-Alternate-cond;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: 0.2rem;
            @media screen and (min-width: $desktop-BP) {
                border-bottom: 3px solid $dark;
                font-size: 25px;
            }

            @media screen and (min-width: $desktop-big-BP) {
                font-size: 28px;
            }
        }
        .filter-icon {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            zoom: 1;
            background: url(/media/globals/mobile-circles-line-filter.svg) no-repeat 0 0;
            cursor: pointer;
            @media screen and (min-width: $tablet-BP) {
                display: none;
            }
        }
    }


    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
        overflow: hidden;
        height: 0;

        @media screen and (min-width: $tablet-BP) {
            padding: 0;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            height: auto !important;
        }
        
        @media screen and (min-width: $desktop-BP) {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            gap: 0px 0px;
            justify-content: space-around;
        }

        li {
            padding: 0;
            text-align: center;
            margin: 0;

            @media screen and (min-width: $tablet-BP) {
                padding: 0rem 2rem 0.2rem 2rem;
                border-right: 1px solid $gray-dots;
            }
            
            @media screen and (min-width: $desktop-BP) {
                border-right: none;
                border-bottom: 1px solid $gray-dots;
                text-align: left;
                margin: 0;
                padding: 0;
            }
            &:last-child {
                border: none;
            }
        }
    }
}