section.privacy-policy {

    & div {
        padding: 0;
        margin: 0 auto;
        
        p {
            font-weight: 300;
        }
    }
}