.state-select {
    position: relative;
    // @media screen and (min-width: $tablet-BP) { 
    //     margin-right: 1rem;
    // }
    @media screen and (min-width: $desktop-BP) { 
        margin-right: 0;
    }

    label {
        font-size: 2.6rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-family: $ff-Alternate;
    }

    & > select {
        background-image: url(/media/globals/arrow-accordion-2.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 1rem;
        background-color: $gray-bg-soft;
        //outline: none;
        border: none;
        appearance: none;
        font-family: $ff-Cream-r;
        padding: 1.3rem 1.7rem 1.4rem 1.7rem;
        font-size: 1.7rem;
        margin-top:0.5rem;
        // margin: 1rem 0 0.5rem 0;
        width: 100%;
        font-family: $ff-Cream-b;
        @media screen and (min-width: $tablet-BP) { 
            background-position: 97% center;
            padding: 1.2rem;
            
        }
        @media screen and (min-width: $desktop-BP) { 
            padding: 1.2rem 3.7rem 1.4rem 1.7rem;
        }
        &::placeholder{
            font-style: italic;
            color: $dark;
        }
        
        option {
            background: $gray-bg-soft;
        }
    }
}