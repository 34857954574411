.general-slider-wrapper {
    .general-slider {
        margin: 0;
       
        & > div {
            border: none;
           
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .general-slider-dots {
        &.outside-dots {
            ul {
                bottom: -4rem;
                @media screen and (min-width: $tablet-BP) {
                    bottom: 2rem;
                }
            }  

        }
        
        position: relative;
        ul {
            bottom: 1rem;

            @media screen and (min-width: $desktop-BP) {
                bottom: 2rem;
            }
            li {
                &.slick-active {
                    button {
                        &::before {
                            background: $red;
                            transition: all 300ms linear;
                        }
                    }
                }
                button {
                    &::before {
                        content: "";
                        background: $gray-dots;
                        opacity: 1;
                        border-radius: 1rem;
                        width: 1.2rem;
                        height: 1.2rem;
                        transition: all 300ms linear;
                       
                        @media screen and (min-width: $tablet-BP) {
                            background: $white;
                            box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
                        }
                    }
                    &:hover {
                        &::before {
                            background: $red;
                            transition: all 300ms linear;
                        }
                    }
                }
            }
        }
    }
}