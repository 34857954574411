section.home-welcome-to {
    @media screen and (min-width: $desktop-BP) {
        padding-top: 9rem;
    }
    & >div {
        padding: 0;
        & > div {
            &:first-child {
                position: relative;
                margin-top: 5rem;
                margin-bottom: 5rem;

                @media screen and (min-width: $desktop-BP) {
                    margin-top: 0;
                }

                h1, h3 {
                  font-family: $ff-Cream-b;
                  text-align: center;
                  font-size: 5.2rem;
                //   font-feature-settings: "kern";
                //   text-anchor: middle;

                    @media screen and (min-width: $desktop-BP) {
                        margin-top:0;
                    }
                }
                
                p {
                    text-align: center;
                    font-family: $ff-ballinger;
    
                    @media screen and (min-width: $tablet-BP) {
                        padding: 0rem 6rem;
                        margin-bottom: 6rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        padding: 0rem 19rem;
                    }
                }
            }
        }

        .grid {
            @media screen and (min-width: $tablet-BP) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0px 2rem;
            }
        }
    }
}