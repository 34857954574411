.payment-information-form.address-info {
    padding: 3.5rem 0;
    @media screen and (min-width: $desktop-BP) {
        padding: 4rem 0;
    }

    .generic-button {
        @media screen and (min-width: $desktop-BP) {
            line-height: 2rem;
        }
    }
    & > div {
        form {
            .payment {
                h3 {
                    margin: 0 0 2rem;
                    font-size: 2.7rem;
                    font-family: $ff-Cream-b;
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0 0 4rem;
                        font-size: 3.7rem;
                    }
                }
            }

            .shipping {
                & > div {
                    fieldset {
                        padding: 0;
                        border:none;
                        .grid {
                            .address {
                                .address-options {
                                    margin-bottom: 3rem;
                                    .locationname {
                                        padding: 0 2rem;
                                    }
                                    .delivery-title {
                                        font-size: 2rem;
                                        margin-bottom: 1rem;
                                        text-transform: uppercase;
                                        font-family: $ff-Clawson-b;
                                        border-bottom: 1px solid $gray-dots;
                                        display: block;
                                        padding: 0 0 1.2rem 0;
                                        margin-bottom: 3rem;
                                    }

                                    .location-type-title {
                                        font-size: 2.6rem;
                                        margin-bottom: 1rem;
                                        text-transform: uppercase;
                                        font-family: $ff-Alternate; 
                                    }

                                    & > div {
                                        .checkcontainer {
                                            font-size: 1.8rem;
                                            font-style: italic;
                                            margin: 1rem 0 0.5rem 0;
                                            label {
                                                &.error {
                                                    color:$red;
                                                    font-family: $ff-sansSerif;
                                                    font-size: 1.4rem;
                                                    text-transform: none;
                                                    margin: 0;
                                                }
                                                font-family: "Cream-Regular";
                                            }
                                        }
                                    }
                                }

                                .address-details {
                                    margin-bottom: 3rem;
                                    & > div {
                                        label {
                                            // //outline: none;
                                            font-size: 2.6rem;
                                            margin-bottom: 1rem;
                                            text-transform: uppercase;
                                            font-family: $ff-Alternate;
                                            &.error {
                                                color:$red;
                                                font-family: $ff-sansSerif;
                                                font-size: 1.4rem;
                                                text-transform: none;
                                                margin: 0;
                                            }
                                        }

                                        &.grid {
                                            @media screen and (min-width: $tablet-BP) {
                                                display: grid;
                                                grid-template-columns: 1fr 1fr;
                                                gap: 2rem;
                                            }
                                            & > div {
                                                margin-bottom: 2rem;
                                                label {
                                                    font-size: 2.6rem;
                                                    margin-bottom: 1rem;
                                                    text-transform: uppercase;
                                                    font-family: $ff-Alternate;
                                                    &.error {
                                                        color:$red;
                                                        font-family: $ff-sansSerif;
                                                        font-size: 1.4rem;
                                                        text-transform: none;
                                                        margin: 0;
                                                    }
                                                }

                                                input {
                                                    width: 100%;
                                                    border: none;
                                                    // //outline: none;
                                                    appearance: none;
                                                    font-size: 1.7rem;
                                                    padding: 1.2rem;
                                                    margin-top: 0.5rem;
                                                    border-radius: inherit;
                                                    background: $gray-bg-soft;
                                                    @media screen and (min-width: $tablet-BP) {
                                                        font-size: 1.8rem;
                                                    }
                                                    &::placeholder{
                                                        color: $dark;
                                                    }
                                                }
                                            }
                                        }

                                        &.businessName, &.streets, &.phones{
                                            input {
                                                width: 100%;
                                                border: none;
                                                // //outline: none;
                                                appearance: none;
                                                font-size: 1.7rem;
                                                padding: 1.2rem;
                                                margin-top: 0.5rem;
                                                margin-bottom: 2rem;
                                                border-radius: inherit;
                                                background: $gray-bg-soft;
                                                @media screen and (min-width: $tablet-BP) {
                                                    font-size: 1.8rem;
                                                }
                                                &::placeholder{
                                                    color: $dark;
                                                }
                                            }
                                        }

                                        &.phones{
                                            margin-top: 1rem;
                                            @media screen and (min-width: $tablet-BP) {
                                                display: grid;
                                                grid-template-columns: 1fr 1fr;
                                                gap: 2rem;
                                            }
                                        }

                                        &:nth-last-child(2) {
                                            gap: 2rem;
                                            display: grid;
                                            align-items: baseline;
                                            grid-template-columns: 1fr 1fr;

                                            .zip-address {
                                                input {
                                                    width: 100%;
                                                    border: none;
                                                    // //outline: none;
                                                    appearance: none;
                                                    font-size: 1.7rem;
                                                    padding: 1.2rem;
                                                    margin-bottom: 1rem;
                                                    margin-top: 0.5rem;
                                                    border-radius: inherit;
                                                    background: $gray-bg-soft;
                                                    @media screen and (min-width: $tablet-BP) {
                                                        font-size: 1.8rem;
                                                    }
                                                    &::placeholder{
                                                        color: $dark;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & > div {
                        label {
                            font-size: 1.8rem;
                            text-transform: uppercase;
                            font-family: $ff-Clawson-b;
                            &.error {
                                color:$red;
                                font-family: $ff-sansSerif;
                                font-size: 1.4rem;
                                text-transform: none;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .address-wrapper {
                & > div {
                    fieldset {
                        padding: 0;
                        border:none;
                        @media screen and (min-width: $desktop-BP) {
                            margin: 0 0 2rem 0 ;
                        }
    
                        .legend-wrapper{
                            border-bottom: 1px solid $gray-dots;
                            display: flex;
                            padding: 0 0 1.2rem 0;
                            margin-bottom: 1rem;
                            margin-bottom: 3rem;
                            width: 100%;
                            align-content: flex-start;

                            & legend{
                                font-size: 2rem;
                                font-family: $ff-Clawson-b;
                            }

                            & .view-more-link{
                                color: $dark;
                                font-family: $ff-sansSerif;
                                font-size: 1.8em;
                                font-style: italic;
                                font-weight: normal;
                                text-transform: none;
                                text-transform: inherit;
                            }
                        }

                        .grid {
                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 2rem;
                            }
                            & > div {
                                margin-bottom: 2rem;
                                label {
                                    font-size: 2.6rem;
                                    margin-bottom: 1rem;
                                    text-transform: uppercase;
                                    font-family: $ff-Alternate;
                                    &.error {
                                        color:$red;
                                        font-family: $ff-sansSerif;
                                        font-size: 1.4rem;
                                        text-transform: none;
                                        margin: 0;
                                    }
                                }
        
                                input {
                                    width: 100%;
                                    border: none;
                                    // //outline: none;
                                    appearance: none;
                                    font-size: 1.7rem;
                                    padding: 1.2rem;
                                    margin-top: 0.5rem;
                                    border-radius: inherit;
                                    background: $gray-bg-soft;
                                    @media screen and (min-width: $tablet-BP) {
                                        font-size: 1.8rem;
                                    }
                                    &::placeholder{
                                        color: $dark;
                                    }
                                }
                            }
                        }

                        .businessName{
                            label {
                                font-size: 2.6rem;
                                margin-bottom: 1rem;
                                text-transform: uppercase;
                                font-family: $ff-Alternate;
                                &.error {
                                    color:$red;
                                    font-family: $ff-sansSerif;
                                    font-size: 1.4rem;
                                    text-transform: none;
                                    margin: 0;
                                }
                            }
                            input {
                                width: 100%;
                                border: none;
                                // //outline: none;
                                appearance: none;
                                font-size: 1.7rem;
                                padding: 1.2rem;
                                margin-top: 0.5rem;
                                margin-bottom: 2rem;
                                border-radius: inherit;
                                background: $gray-bg-soft;
                                @media screen and (min-width: $tablet-BP) {
                                    font-size: 1.8rem;
                                }
                                &::placeholder{
                                    color: $dark;
                                }
                            }
                        }

                        .street-field {
                            span {
                                font-size: 2.6rem;
                                text-transform: uppercase;
                                font-family: $ff-Alternate;
                                display: block;
                            }
                            input {
                                width: 100%;
                                border: none;
                                // //outline: none;
                                appearance: none;
                                font-size: 1.7rem;
                                padding: 1.2rem;
                                margin-top: 0.5rem;
                                margin-bottom: 2rem;
                                border-radius: inherit;
                                background: $gray-bg-soft;
                                @media screen and (min-width: $tablet-BP) {
                                    font-size: 1.8rem;
                                }
                                &::placeholder{
                                    color: $dark;
                                }
                            }
                        }

                        .city-state-wrapper, .phones {
                            margin-bottom: 2rem;
                            & > div {
                                &:first-child {
                                    label {
                                        font-size: 2.6rem;
                                        margin-bottom: 1rem;
                                        text-transform: uppercase;
                                        font-family: $ff-Alternate;
                                        &.error {
                                            color:$red;
                                            font-family: $ff-sansSerif;
                                            font-size: 1.4rem;
                                            text-transform: none;
                                            text-transform: none;
                                            margin: 0;
                                        }
                                    }

                                    input {
                                        width: 100%;
                                        border: none;
                                        // //outline: none;
                                        appearance: none;
                                        font-size: 1.7rem;
                                        padding: 1.2rem;
                                        margin-top: 0.5rem;
                                        border-radius: inherit;
                                        background: $gray-bg-soft;
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 1.8rem;
                                        }
                                        &::placeholder{
                                            color: $dark;
                                        }
                                    }
                                }
                            }
                        }

                        .phones{
                            margin-top: 2rem;

                            @media screen and (min-width: $tablet-BP) {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 3rem;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                gap: 2rem;
                            }
                        }

                        .city-state {
                            
                            // margin-bottom: 2rem;
                            .zip-wrapper {

                                
                                @media screen and (min-width: $tablet-BP) {
                                    margin-bottom: 4rem;
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    gap: 2rem;
                                }
                                @media screen and (min-width: $desktop-BP) {
                                    margin-bottom: 1rem;
                                }
                                label {
                                    font-size: 2.6rem;
                                    text-transform: uppercase;
                                    font-family: $ff-Alternate;
                                    display: block;
                                    &.error {
                                        color:$red;
                                        font-family: $ff-sansSerif;
                                        font-size: 1.4rem;
                                        text-transform: none;
                                        margin: 0;
                                    }
                                }
                                input {
                                    width: 100%;
                                    border: none;
                                    // //outline: none;
                                    appearance: none;
                                    font-size: 1.7rem;
                                    padding: 1.2rem;
                                    margin-top: 0.5rem;
                                    border-radius: inherit;
                                    background: $gray-bg-soft;
                                    @media screen and (min-width: $tablet-BP) {
                                        font-size: 1.8rem;
                                    }
                                    &::placeholder{
                                        color: $dark;
                                    }
                                }

                                .state-select {
                                    // margin-bottom: 2rem;
                                    label {
                                        @media screen and (min-width: $tablet-BP) {
                                            margin-bottom: 0.5rem;
                                        }
                                        &.error {
                                            color:$red;
                                            font-family: $ff-sansSerif;
                                            font-size: 1.4rem;
                                            text-transform: none;
                                            margin: 0;
                                        }
                                    }
                                    &>select {
                                        
                                        @media screen and (min-width: $tablet-BP) {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }

            .additionalNotes{
                label {
                    // //outline: none;
                    font-size: 2.6rem;
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                    font-family: $ff-Alternate;
                    &.error {
                        color:$red;
                        font-family: $ff-sansSerif;
                        font-size: 1.4rem;
                        text-transform: none;
                        margin: 0;
                    }
                }
                textarea {
                    width: 100%;
                    border: none;
                    // //outline: none;
                    appearance: none;
                    font-size: 1.7rem;
                    padding: 1.2rem;
                    margin-top: 0.5rem;
                    border-radius: inherit;
                    background: $gray-bg-soft;
                    @media screen and (min-width: $tablet-BP) {
                        font-size: 1.8rem;
                    }
                    &::placeholder{
                        color: $dark;
                    }
                }
            }

            .cta-prev-cont {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 5rem;
                margin-top: 1rem;
                @media screen and (min-width: $tablet-BP) {
                    margin-top: 1rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    gap: 41rem;
                }

                input {
                    margin: 0;
                }
            }
        }
    }
}