.location-mini-card {
    & > div {
        margin: 0 2rem;
        padding: 2rem 0 4rem 0;
        border-bottom: 1px solid $gray-dots;

        @media screen and (min-width: $tablet-BP) {
            margin: 0 3rem 0 5.5rem;
        }
        
        h3 {
            font-family: $ff-Cream-b;
            font-size: 2.2rem;
        }

        & > ul {
            display:grid;
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: 1fr;
            gap: 0px 0px;
            list-style: none;
            padding: 0;
            margin: 0;

            & > li {
                margin: 0;
                padding: 0;
                @media screen and (min-width: $desktop-BP) {
                    margin-top: 1rem;
                }
                & > strong {
                    font-size: 1.4rem;
                    font-family: $ff-Clawson-b;
                }
                & > p {
                    margin: 0;
                    font-size: 14px;

                    @media screen and (min-width: $desktop-BP) {
                        padding-top: 0.5rem;
                    }

                    // &:last-child {
                    //     margin-top: 0.5rem;
                    // }

                    & > span {
                        display: block;
                    }                    
                }

                & > ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    & > li {
                        margin: 0;
                        padding: 0;

                        & > strong {
                            font-size: 1.4rem;
                            font-family: $ff-Clawson-b;                            
                        }

                        .address {
                            margin-bottom: 0.5rem;
                        }

                        & > p {
                            margin: 0;
                            line-height: 1.4;

                            a {
                                font-size: 14px;
                            }
                        }
                        .address-text {
                            margin-top: 0;
                            margin-bottom: 0.8rem;

                            @media screen and (min-width: $tablet-BP) {
                                margin-bottom: 3rem;
                            }

                            @media screen and (min-width: $desktop-BP) {
                                margin-top: 0.5rem;
                                margin-bottom: 1.2rem;
                            }
                        }

                        // &:last-child {
                        //     margin-top: 1.5rem;
                        // }
                    }
                }
            }
            
        }
    }  
}