section.images-grid {

    padding: 0;
    
    & > div {

        padding: 0;
        
        @media screen and (min-width: $desktop-BP) {
            max-width: none;
        }

        & > div {

            &.grid {

                @media screen and (min-width: $tablet-BP) {
                    display: grid;
                    grid-template-columns: repeat(10, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 1rem 1rem;
                    grid-template-areas:
                    "image-1 image-1 image-1 image-1 image-1 image-1 image-2 image-2 image-2 image-2"
                    "image-3 image-3 image-3 image-3 image-4 image-4 image-4 image-4 image-4 image-4";
                }

                @media screen and (min-width: $desktop-BP) {
                    gap: 2rem 2rem;
                }

                & > figure {

                    margin: 1rem 0;
                    
                    @media screen and (min-width: $tablet-BP) {
                        margin:0;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                        @media screen and (min-width: $tablet-BP) {
                            height: 100%;
                            object-fit: cover;
                        }
                        @media screen and (min-width: $desktop-big-BP) {
                            object-fit: cover;
                            object-position: center;
                            height: 540px;
                        }
                    }

                    @media screen and (min-width: $tablet-BP) {                        
    
                        &:first-child {
                            grid-area: image-1;   
                        }
        
                        &:nth-child(2) {
                            grid-area: image-2; 
                           
                        }
        
                        &:nth-child(3) {
                            grid-area: image-3; 
                        }
        
                        &:nth-child(4) {
                            grid-area: image-4; 
                        }
                    }
                }

             
            }
        }

    }

}