.recipe-container {
    width: 100%;

    .badge{
        display: block;
        font-family: $ff-Alternate;
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: $red;
        border-radius: 8px;
        width: fit-content;
        margin: 0 auto;
        padding: 10px 36px;
        color: $white;
        z-index: 9;

        /*hacky to match seo tag*/
        span > h1{
            font-family: $ff-Alternate;
            font-size: 3rem;
            margin: 0 auto;
        }
    }

    .hero-recipe{
        display: flex;
        font-family: $ff-Alternate;
        background-size: cover;
        background-position: center;
        min-height: 600px;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .hero-recipe-title{
            display: flex;
            position: relative;
            text-align: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 0 auto;
            padding: 20px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.7);
            text-decoration: none;

            .sticker{
                position: absolute; 
                top: 0;
                right: 0;
                background-size: 8.3rem;
                width: 10rem;
                height: 10rem;
                top: -5rem;
                right: 0;
                background-position: 0 0;
                background-repeat: no-repeat;
                z-index: 1;

                @media screen and (min-width: $tablet-BP) {
                    background-size: 11.3rem;
                    width: 14rem;
                    height: 14rem;    
                    top: -5rem;
                    right: 4rem;
                }
            }

            .sticker:hover{
                -webkit-transition: -webkit-transform .3s ease-in-out;
                transition: -webkit-transform .3s ease-in-out;
                transition: transform .3s ease-in-out;
                transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
                -webkit-transform: rotate(9deg);
                transform: rotate(9deg);
            }

            .sticker-new{
                background-image: url(/media/recipes/WB-New.png);
            }

            .sticker-eat-good-food{
                background-image: url(/media/recipes/WB-EatGoodFood.png);
            }

            .badge{
                position: absolute;
                font-size: 2rem;
                top: -120px;

                @media screen and (min-width: $tablet-BP) {
                    font-size: 3rem;
                    top: -60px;
                }

                @media screen and (min-width: $desktop-BP) {
                    top: -20px;
                }
            }

            h2{
                font-family: $ff-Alternate-cond;
                font-size: 4rem;
                text-transform: uppercase;
            }

            .meta-data{
                font-family: $ff-Alternate-cond;
                font-size: 3rem;
                text-transform: uppercase;
            }

        }

        @media screen and (min-width: $mobile-BP) {
            padding: 160px 80px;

            .hero-recipe-title{ 
                padding: 20px 40px;

                h2{
                    font-size: 7rem;
                }
            }
        }

        @media screen and (min-width: $desktop-BP){
            .hero-recipe-title{ 
                max-width: 80%;
                padding: 50px;

                h2{
                    font-size: 8rem;
                }
            }
        }

        @media screen and (min-width: $desktop-big-BP){
            .hero-recipe-title{ 
                max-width: 60%;
            }
        }

    }

    .recipes-entries{
        padding: 30px 20px;

        h3{
            font-family: $ff-Alternate-cond;
            font-size: 6rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
    }

    .recipe-grid{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;

        justify-content: center;
        gap: 40px;
        
        .recipe-item{
            width: 100%;
            border: 1px solid #bbbbbb;
            background-color: $white;
            text-decoration: none;
            position: relative;

            .sticker{
                position: absolute; 
                top: 0;
                right: 0;
                background-size: 11.3rem;
                width: 14rem;
                height: 14rem;
                top: -1.8rem;
                right: 0;
                background-position: 0 0;
                background-repeat: no-repeat;
                z-index:1;
            }

            &:hover .sticker{
                -webkit-transition: -webkit-transform .3s ease-in-out;
                transition: -webkit-transform .3s ease-in-out;
                transition: transform .3s ease-in-out;
                transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
                -webkit-transform: rotate(9deg);
                transform: rotate(9deg);
            }

            .sticker-new{
                background-image: url(/media/recipes/WB-New.png);
            }

            .sticker-eat-good-food{
                background-image: url(/media/recipes/WB-EatGoodFood.png);
            }

            .recipe-item-image{
                position: relative;
                height: 300px;
                background-size: cover;
                background-position: center;

                .badge{
                    position: absolute;
                    bottom: -30px;
                    left: 20px;
                }
            }

            .recipe-item-title {
                padding: 20px;
                padding-top: 40px;
                h4{
                    font-family: $ff-Alternate-cond;
                    font-size: 4rem;
                    text-align: left;
                    text-transform: uppercase;
                }
            }

            @media screen and (min-width: $tablet-BP) {
                width: 44%;
            }

            @media screen and (min-width: $desktop-BP) {
                width: 30%;
            }

            @media screen and (min-width: $desktop-big-BP) {
                width: 22%;
            }
        }
    }
}