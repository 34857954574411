.load-more {
    margin-top: 3rem;
    span {
        display:block;
        text-align: center;
        @media screen and (min-width: $tablet-BP) {
            text-align: center;
        }
        a {
            font-family: $ff-sansSerif;
            font-size: 2.2rem;
            text-decoration: none;

            @media screen and (min-width: $tablet-BP) { 
                font-size: 2.5rem;
            }
        }
    }
}