article.slider-timeline-card {

    background: $white;
    padding: 1.3rem 2.1em;

    @media screen and (min-width: $desktop-BP) {
        padding: 3rem 4em 4rem;
        // max-width: 56rem; // optional
        border:1px solid $gray-dots;
    }
    
    & > div {
        padding: 0;

        & > div {

            display:flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            @media screen and (min-width: $desktop-BP) {
                text-align: left;
            }
         
            h3 {
                font-family: $ff-HouseSlam-r;
                margin: 0;
            }

            h4 {
                font-family: $ff-Cream-b;
                margin: 0;
                letter-spacing: -2px;
            }

            p {
                font-family: $ff-ballinger;
                direction: ltr;
            }
        }
    }
}