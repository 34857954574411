section.big-image {
    padding: 0;

    & > div {
        padding: 0;
        @media screen and (min-width: $desktop-big-BP) {
            margin: 0;
            max-width: none;
        }

        figure {
            margin: 0;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                box-shadow: 0px -14px 24px -4px rgba(0, 0, 0, 0.2);

                @media screen and (min-width: $desktop-big-BP) {
                    height: 650px;
                    object-position: center;
                    object-fit: cover;
                }
            }
        }
    }
}