.modal {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    visibility: visible;
    transition: all 0.35s ease-in;
    
    .modal-overlay {
        background: $transparent-black;
        cursor: pointer;
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .modal-dialog {
        position: relative;
        width: 90%;
        max-width: 800px;
        max-height: 80vh;
        background: #fff;
        overflow: auto;
        cursor: default;
        padding: 4rem 2rem 0 2rem;
        
        @media screen and (min-width: $tablet-BP) {
            padding: 4rem 2rem;
        }
        .close-modal {
            font-size: 1.5rem;
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
        .modal-header {
            & > h3 {
                text-align: center;
                text-transform: uppercase;
                font-family: $ff-Alternate;
                padding: 0 3rem;
                font-weight: 300;
            }
        
        }
        .modal-content {
            margin-top: 2rem;
            .grid {

                @media screen and (min-width: $tablet-BP) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem 2rem;
                   
                }
              

                & > div {
                    position: relative;
                    
                    a {
                        font-family: $ff-Cream-b; 
                        text-transform: none;
                        letter-spacing: 0;
                    }     
                        
                    svg {
                        display: none;
                        @media screen and (min-width: $desktop-BP) {
                            display:block;
                            z-index: 2;
                            position: absolute;
                            bottom: -2.5rem;
                            right: -4rem;
                            width: 15rem !important;
                            height: 8rem !important;
                            transform: rotate(6deg) !important;
                        }
                    }
                }
            }
        }
    }
  }