div.slider-product-detail{

    &.disabled-wrapper {
        position: relative;
        &::before {
            content: "";
            background: url(/media/ecommerce/Backsoon.png) no-repeat 0 0;
            background-size: 11rem;
            top: -2rem;
            right: 0;
            width: 13rem;
            height: 10rem;
            position: absolute;
            z-index: 2;

            @media screen and (min-width: $tablet-BP) {
                background-size: 13rem;
                width: 15rem;
            }

            @media screen and (min-width: $desktop-BP) {
         
            }

            @media screen and (min-width: $desktop-big-BP) {
             
            }
        }
    }
    & > div {
        &.slider-product-detail-images {
            img {
                width: 100%;
                height: auto;
            }
        }
        &.slider-product-thumbnails-wrapper {
            .slick-track {
                margin: 0 2rem;
                @media screen and (min-width: $desktop-BP) {
                    margin: 0;
                }
                .slick-slide {
                    margin: 1rem 1rem 1rem 0;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}