section.page-title {
    padding: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid $gray-dots;
    

    &.flowers,
    &.catering,
    &.gifts {
        margin-bottom: 0;
    }

    @media(min-width: $tablet-BP) {
        margin-bottom: 0;
    }

    & > div {
       
        padding: 0;
        margin:0;
        max-width: none;

        & > div {
            background: $vintage-gray;
            padding: 2rem 3rem;
            text-align: center;
            @media(min-width: $tablet-BP) {  
                padding: 3rem 0 4.5rem;
            }

            @media(min-width: $desktop-BP) {
                padding: 0 0 4.5rem;
                background-image: url(/media/globals/titles-logo.png);
                background-repeat: no-repeat;
                background-position: bottom right;
                background-size: auto 100%;
            }

            h1 {
                font-family: $ff-HouseScrip-sb;
                color: $red;
                margin:0;
                line-height: 4.5rem;
                // margin-bottom: 2.1rem;
                font-size: 5rem;
               
                @media(min-width: $tablet-BP) {
                    line-height: 6.5rem;
                    margin-bottom: 2.5rem;
                    font-size: 9rem;
                }  
            }

            p {
                font-family: $ff-Cream-r;
                letter-spacing: -1px;
                margin-top: 0.5rem;
                margin-bottom:0;
            }
        }
    }
}