.cart-totals {
    padding: 0 2rem 4rem; //new
    & > .grid{
        background-color: $white;
        border: 1px solid $gray-dots;

        @media screen and (min-width: $tablet-BP) {
            display: grid;
            grid-template-columns: 4fr 5fr;
            gap: 0 3rem;
        }
        @media screen and (min-width: $desktop-BP) {
            grid-template-columns: 2fr 5fr;
        }
        & > div {
            &:first-child { // promo code
                display: flex;
                flex-direction: column;
                background-color: $vintage-gray;
                padding: 3rem 2rem;
                border-bottom: 1px solid $gray-dots;
                @media screen and (min-width: $tablet-BP) {
                    border: none;
                    border-right: 1px solid $gray-dots;
                }
                @media screen and (min-width: $desktop-BP) {
                    padding: 5rem 2rem;
                }
                & > label {
                    font-family: $ff-Alternate-cond;
                    font-size: 2.6rem;;
                    margin-bottom: 2rem;
                    text-transform: uppercase;
                }
                .error-code {
                    color: #CF1A0D;
                    font-family: "ballinger", sans-serif;
                    font-size: 1.4rem;
                    margin-bottom: 0.7rem;
                }
                & > input {
                    // //outline: none;
                    padding: 1rem;
                    border: 1px solid $gray-dots;
                    margin-bottom: 1rem;
                    font-size: 17px;
                }
                .code-up {
                    font-family: $ff-sansSerif;
                    font-size: 1.3rem;
                    background: none;
                    border: none;
                    border-bottom: 1px solid;
                    text-align: left;
                    width: min-content;
                    padding: 0;
                    cursor: pointer;
                }
            }

            &:last-child { // prices and button checkout
                padding: 3rem 2rem;

                @media screen and (min-width: $desktop-BP) {
                    padding: 3rem 4rem 3rem 2rem;
                }
                & > div {
                    &:first-child { // prices
                        & > div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 1rem 0;
                            border-bottom: 1px solid $gray-dots;
                            @media screen and (min-width: $desktop-BP) {
                                border-bottom: 1px solid $gray-dots;
                            }
                            &:first-child,
                            &:nth-child(3) {
                                border-bottom: 1px solid $gray-dots;
                                @media screen and (min-width: $desktop-BP) {
                                    border-bottom: 1px solid $gray-dots;
                                }
                            }
                            &:last-child {
                                & > span {
                                    &:first-child {
                                        font-weight: 700;
                                    }
                                }
                            }
                            &.is-indent {
                                border-bottom: 1px solid $gray-dots;
                                @media screen and (min-width: $desktop-BP) {
                                    margin-left: 6rem;
                                    border-bottom: 1px solid $gray-dots;
                                }
                                & > span {
                                    font-size: 1.5rem;
                                    @media screen and (min-width: $desktop-BP) {
                                        font-size: 1.7rem;
                                        font-weight: normal;
                                    }
                                    &.is-price {
                                        font-size: 3rem;
                                        letter-spacing: 2px;
                                    }
                                }
                            }
                            & > span {
                                font-family: $ff-sansSerif;
                                font-size: 1.8rem;
                                @media screen and (min-width: $desktop-BP) {
                                    font-size: 2rem;
                                    font-weight: bold;
                                }
                            }
                            & > div {
                                font-family: $ff-SignPainter;
                                color: $red;
                                display: flex;
                                & > span {
                                    &.is-price {
                                        font-size: 3.5rem;
                                        letter-spacing: 2px;
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 5rem;
                                        }
                                    }

                                    &.is-price-2{
                                        font-size: 3.5rem;
                                        letter-spacing: 2px;
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 3.8rem;
                                        }
                                    }
                                }
                                & > sup {
                                    font-size: 2.7rem;
                                    
                                    @media screen and (min-width: $tablet-BP) {
                                        margin-top: 0.5rem;
                                        font-size: 2.8rem;
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        margin-top: 0.2rem;
                                        padding-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }

                    // .shipping-message {
                    //     .message {
                    //         font-size: 1.5rem;
                    //     }
                    // }
                    
                    &:last-child { // button checkout
                        margin-top: 2rem;
                        text-align: center;
                        @media screen and (min-width: $tablet-BP) {
                            text-align: right;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            margin-top: 4rem;
                        }
                        a {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}