.megamenu-container{
    display: none;

    // Ensure the megamenu is hidden on mobile
    @media screen and (min-width: $desktop-BP) {
        display: none;
        border: 2px solid black;
        position: absolute;
        background-color: $white;
        background-image: url(/media/backgrounds/PaintBackground1.jpg);
        top: 80px;
        font-family: $ff-Alternate;
        font-size: 1.8rem;
        line-height: 1.2;
        width: 1024px;
        text-transform: uppercase;    
    }

    p, h1, h2, h3, h4, h5, h6{
        font-family: $ff-Alternate;
        margin: 0;
        line-height: 1.2;
    }

    a{
        text-decoration: none;
        color: black;
    }

    a:hover{
        color: $red;
    }

    img{
        width: 100%;
    }

    h4{
        font-size: 1.75rem;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        li{
            margin-bottom: 10px;
        }
    }

    .column{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1em;
        padding-top: 20px; 
        padding-bottom: 20px;
        img{
            margin: 10px 0;
        }
        @media screen and (min-width: $desktop-BP) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media screen and (min-width: $desktop-big-BP) {
            padding-left: 42px;
            padding-right: 42px;
        }
    }

    // .column:nth-last-child(2){
        // width: 28.5714285714%;
    // }

    .column:last-child{
        border-left: 2px solid black;
        font-family: $ff-HouseSlam-r;
        font-size: 2rem;
    }

}