section.page-not-found {
    padding: 2rem 0;
    @media screen and (min-width: $tablet-BP) {
        padding: 10rem 0;
    }
    & > div {
        text-align: center;
        & > h2 {
            font-family: $ff-HouseSlam-r;
            color: $red;
            font-weight: 100;
            margin: 0;
            font-size: 12rem;
            line-height: 11rem;
            @media screen and (min-width: $tablet-BP) {
                font-size: 17rem;
                line-height: 16rem;
            }
            @media screen and (min-width: $desktop-BP) {
                font-size: 25rem;
                line-height: 24rem;
            }
        }
        & > p {
            font-family: $ff-Cream-r;
            font-size: 2rem;
            margin: 0;
            @media screen and (min-width: $tablet-BP) {
                font-size: 2.5rem;
            }
            @media screen and (min-width: $desktop-BP) {
                font-size: 3rem;
            }
        }
    }
}