section.card-locations {

    margin-bottom: 3rem;
    padding: 0;

    @media screen and (min-width: $tablet-BP) {
        padding: 0;
        margin:0;
        margin-bottom: 4.5rem;
    }
    @media screen and (min-width: $desktop-BP) {
        padding: 0;
        margin-bottom: 3rem;
    }


    & > div {
        max-width: none;
        &:first-child {

            padding: 0;
    
            & > div {
                background: $white;
                border: 1px solid $gray-dots;
    
                & > div {
                    &:first-child {
                        text-align: center;
                        padding: 3rem 4rem 1rem;
    
                        @media screen and (min-width: $tablet-BP) {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        }

    
                        h3 {
                            text-transform: uppercase;
                            margin: 4rem 0 3rem;
                            font-family: $ff-Clawson-b;
                            
                            @media screen and (min-width: $tablet-BP) {
                                margin: 0;
                                // font-size: 6rem;
                                margin-bottom: -1rem;
                            }
                        }

                        a {
                            transition: all .2s ease-in-out;
                            &:hover {
                                background: $dark-red;
                                transition: all .2s ease-in-out;
                            }
                        }
                    }
    
                    &:last-child {
    
                        border-top: 3px solid $dark;
                        border-bottom: 3px solid $dark;
                        margin: 4rem 4rem;
    
                        @media screen and (min-width: $tablet-BP) {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-template-rows: 1fr auto;
                            gap: 0px 1rem;
                            grid-template-areas:
                            "store store phone"
                            "links links links";
                            margin: 0 4rem 4rem 4rem;
                        }

                        @media screen and (min-width: $desktop-BP) {
                            grid-template-columns: 2fr 1fr 28rem;
                            grid-template-rows: 1fr;
                            grid-template-areas: none;
                        }
    
                        & > div {
                            &:first-child {
    
                                @media screen and (min-width: $tablet-BP) {
                                    padding-bottom: 1.5rem;
                                    grid-area: store;
                                }
                                @media screen and (min-width: $desktop-BP) {
                                    grid-area: auto;
                                }
                                ul {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-template-rows: 1fr;
                                    gap: 0px 0px;
                                    padding: 2rem 0 0 0;
                                    list-style: none;
    
                                    @media screen and (min-width: $tablet-BP) {
                                        margin-top: 0;
                                        padding: 1.5rem 0 0 0;
                                        grid-template-columns: 1.5fr 2fr;
                                        gap: 0px 1rem;
                                    }


                                    @media screen and (min-width: $desktop-BP) {
                                        grid-template-columns: 1fr 1fr;
                                        gap: 0px 0px;
                                    }
                
                                    li {
                                        padding: 0;
                                        margin: 0;
                
                                        &:first-child {
                                            text-align: left;
    
                                            .p-r {
                                                padding-right: 6rem;          
                                            }
                                            
                                        }
    
                                        & > p {
                                            margin-bottom: 0.5rem;
                                            font-size: 15px;
                                            @media screen and (min-width: $desktop-BP) {
                                                margin-top: 0;
                                                font-size: 17px;
                                            }
    
                                            & > strong {
                                                display: block;
                                                
                                            }
                                        }
                
                                        & > strong {
                                            font-family: $ff-Alternate;
                                            text-transform: uppercase;
                                            font-weight: 100;
                                            @media screen and (min-width: $tablet-BP) {
                                                font-size: 2rem;
                                            }
                                        }
                
                                        &:last-child {
                                            text-align: left;
                                            padding-left: 2rem;
                                        }
                                    
                                        a {
                                            color: $red;
                                        }
                                        .separation {
                                            margin-bottom: 1.5rem;
                                            @media screen and (min-width: $tablet-BP) {
                                                margin-bottom: 3.5rem;
                                            }
                                            @media screen and (min-width: $desktop-BP) {
                                                margin-bottom: 1rem;
                                            }
                                        }
                                    }
                                }
                            }
            
                            &:nth-child(2) {
                                margin: 0;
                                border-bottom: 3px solid $dark;
                                margin-top: 1.5rem;

                                @media screen and (min-width: $tablet-BP) {
                                    grid-area: phone;
                                    padding-top: 1.5rem;
                                    border: none;
                                    margin: 0;
                                }
    
                                @media screen and (min-width: $desktop-BP) {
                                    border: none;
                                    padding-top: 1.5rem;
                                    grid-area: auto;
                                }

                                strong {
                                    display: block;
                                    font-family: $ff-Alternate;
                                    text-transform: uppercase;
                                    line-height: normal;
                                    font-weight: 100;
                                    @media screen and (min-width: $tablet-BP) {
                                        font-size: 2rem;
                                        line-height: 2rem;
                                    }
                                }
                                
                                p {
                                    text-align: left;
                                    margin: 1rem 2rem 4rem 0;

                                    @media screen and (min-width: $tablet-BP) {
                                        margin-top: 1rem;
                                        margin-bottom: 0.5rem;
                                    }
    
                                    @media screen and (min-width: $desktop-BP) {
                                        margin: 0;
                                    }
                                }
                            }
    
                            &:last-child {

                                @media screen and (min-width: $tablet-BP) {
                                    grid-area: links;
                                    border-top: 3px solid $dark;
                                }
                                
                                @media screen and (min-width: $desktop-BP) {
                                    padding: 0;
                                    border: none;
                                    border-left: 1px solid $dark;
                                    grid-area: auto;
                                }
                                
                                ul {
                                    padding: 0;
                                    display:flex;
                                    flex-direction: column;
                                    height: 100%; //remove when event link is added 
                                    
                                    li {
                                        list-style-type: none;
                                        padding: 1rem 0;
                                        margin: 0;
                                        position: relative;
                                        padding: 1rem 0;
                                        @media screen and (min-width: $tablet-BP) {
                                            padding: 1.5rem 6rem;
                                            text-align: center;
                                            line-height: 2.4rem;
                                            height: 50%; //remove when event link is added 
                                            display: flex; //remove when event link is added 
                                            width: 100%; //remove when event link is added 
                                            align-items: center; //remove when event link is added 
                                            justify-content: center; //remove when event link is added 
                                        }

                                        &:after {
                                            content: "";
                                            background: url(/media/locations/arrow-locations.png) no-repeat 0 0;
                                            background-size:4rem ;
                                            width: 4rem;
                                            height: 12px;
                                            display: inline-flex;
                                            position: absolute;
                                            right: 0;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;

                                            @media screen and (min-width: $tablet-BP) {
                                                background-size:4rem;
                                                width: 4rem;
                                            }

                                            @media screen and (min-width: $desktop-BP) {
                                                background-size:4rem ;
                                                width: 4rem;
                                            }
                                        }
    
                                        &:nth-child(2) {
                                            border-top: 1px solid $dark;
                                            // border-bottom: 1px solid $dark;
                                        }
                                        a {
                                            text-decoration: none;
                                            font-family: $ff-Alternate;
                                            text-transform: uppercase;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
            }
        }
        
        &:last-child {
            padding: 0;
        }
    }
}