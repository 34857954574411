section.review-order-content-items {
    padding-top: 3rem;


    @media screen and (min-width: $desktop-BP) {
        padding: 3rem 2rem 0;
        max-width: 1200px;
        margin: 0 auto;
    }

    & > div {
        border: 1px solid $gray-dots;
        border-bottom: none;
        background: $white;
        padding: 4rem 0 0.5rem;

        @media screen and (min-width: $desktop-BP) {
            padding: 0;
            margin-top: 4rem;
        }
    }
}