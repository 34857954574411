section.shop-online-cards {

    margin-top: 3rem;
    padding: 0;

    & > div {
        max-width: none;
        padding: 0;
        .grid {
            @media screen and (min-width: $tablet-BP) {
                display:grid;
                grid-template-columns: repeat(2,1fr);
                gap:2rem;
            }
        }
    }

}