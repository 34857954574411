.slider-home-card {
 
    position: relative;
    z-index: 2;

    text-align: center;
    text-decoration: none;

    /* @update-jan-24 */
    /*background:url(/media/backgrounds/slider-bg-home.png) no-repeat 0 0;*/
    background-color:  rgba(0,0,0,0.6);

    &.slider-home-card-hidden{
        visibility: hidden;

        & > div.bg-white{

        }
    }
  
    & > div {

        &.bg-white {
            padding: 3.5rem 3rem 5rem 3rem;

            @media screen and (min-width: $tablet-BP) {
                margin: 0;
                padding: 4.5rem 4rem 7rem 3rem;
            }
        
            @media screen and (min-width: $desktop-BP) {
                padding: 3rem 4.5rem 6rem 3rem;
                /*background: url(/media/globals/slider-home-logo-westborn.png) no-repeat bottom right;*/
                background-size: 21rem;
            }
        
            @media screen and (min-width: $desktop-big-BP) {
                // padding: 7rem 6rem 5rem 5rem;
                padding: 7rem 5rem 6rem 6rem;
                background-size: 29rem;
            }
        }
        
        h3 {
            /*color:$red;*/
            color: white;
            opacity: 1;
            line-height: 4rem;
            font-family: $ff-Clawson-b;
            text-transform: uppercase;
            // font-family: $ff-Alternate;
            
            @media screen and (min-width: $tablet-BP) {
                line-height: 4.5rem;
            }

            @media screen and (min-width: $desktop-big-BP) {
                line-height: 6.5rem;
            }

            &.big {
                font-size: 4rem;
                @media screen and (min-width: $tablet-BP) {
                    font-size: 4.8rem;
                    
                }
                @media screen and (min-width: $desktop-big-BP) {
                    font-size: 5.5rem;
                }
            }
        }

        p {
            color: white;
            font-family: $ff-ballinger;
        }

        .slider-home-text {
            display: flex;
            align-items: top;
            justify-content: center;

            @media screen and (min-width: $desktop-big-BP) {
                padding: 0 4rem;
                margin-bottom: 3rem;
            }

            p {
                margin-top: 0;
            }
        }

        .button-container {
            margin-top:1rem;
        }
        .generic-button{
            background-color: white;
            color: black;
            margin: auto;
        }
        
    }


}