.event-ticket-price {
    padding: 0 2rem 3rem 2rem;
    @media screen and (min-width: $desktop-BP) {
        padding: 0;
    }
    & > div {
        margin-top: 3rem;
        & > div {
            &:first-child {
                h3 {
                    font-family: $ff-Cream-b;
                    padding-bottom: 1rem;
                    margin: 0;
                    border-bottom: 1px solid $gray-dots;  
                }
            }
    
            &:nth-child(2){
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2rem;
                margin-top: 1rem;
                span {
                    font-family: $ff-SignPainter;
                    color: $red;
                    font-size: 4rem;
                    font-weight: bold;

                    @media screen and (min-width: $desktop-BP) {
                        font-size: 5.5rem;
                    }
                }
                & > div {

                    &.has-select {
                        position: relative;

                        & > select {
                            background-image: url(/media/globals/arrow-accordion-2.svg);
                            background-repeat: no-repeat;
                            background-position: 92% center;
                            background-size: 1rem;
                        }
                    }
                    select {
                        // //outline: none;
                        background: $vintage-gray;
                        border: none;
                        padding: 1rem 2rem;
                        height: 4rem;
                        text-align: -webkit-center;
                        text-align-last:center;
                        appearance: none;
                        font-size: 17px;
                        
                        option {
                            font-family: $ff-Cream-b;
                            background: $vintage-gray;
                        }
                    }
                }
            }
        } 
    }
}