.hvr-scale{        
    transition: all .3s ease-in-out;
    &:hover, &:focus {
        @media screen and (min-width: $desktop-BP) {
            transform: scale(1.09);
            transition: all .3s ease-in-out;
        }
    }
}

.hvr-card-scale {
    transition: all .5s ease;
    &:hover,&:focus {
        @media screen and (min-width: $desktop-BP) {
            a {
                box-shadow: 0 12px 8px 0 rgba(0,0,0,0.1);
            }
            transform: translateY(-2rem);
            cursor: pointer;
            transition: all .5s ease;
        }
    }

    &.hvr-card-scale-small {   
        &:hover,&:focus {
            @media screen and (min-width: $desktop-BP) {
                transform: translateY(-1rem);
            }
        }  
    }
}

.hvr-fade {
    transition: color .2s ease-in;

    &:hover,&:focus {
        @media screen and (min-width: $desktop-BP) {
            transition: color .2s ease-in;
            color: $red;
        }
    }
}

.hvr-text-color {
    position: relative;
    font-size: 2em;
    color: $dark;
    overflow: hidden;
    background: linear-gradient(to right, $red, $red 50%, $dark 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    &:hover,&:focus {
        @media screen and (min-width: $desktop-BP) {
            background-position: 0 100%;
        }
    }
}

.hvr-button-bg {
    display: inline-block;
    color: #fff;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $red;
        transition: all .3s;
        z-index: -1;
    }

    &:hover,&:focus {
        
        color: $white;

        &:before {
            width: 100%;
        }
    }
}

.hvr-button-rotate{
    transform: rotate(6deg);
    transition: transform 275ms ease;
    cursor: pointer;
    &:hover,&:focus {
        @media screen and (min-width: $desktop-BP) {
            transform: rotate(1deg) !important; 
            transition: transform 275ms ease;
        }
    }
}

.hvr-fade-bg {
    transition: all 275ms ease;
    cursor: pointer;
    &:hover,&:focus      {
        @media screen and (min-width: $desktop-BP) {
            background-color:$red !important;
            transition: all 275ms ease;
            color: $white;
        }
    }
}

.hvr-underline-from-left {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    position: relative;
    overflow: hidden;

    &::before{
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 100%;
        bottom: 0;
        background: $red;
        height: 2px;
        transition: right 0.3s ease-out;
    }

    &:hover:before, &:focus:before, &:active:before,&:focus {
        right: 0;
    }
}

.hvr-underline-fade{
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    position: relative;
    overflow: hidden;
    
    &::before{
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background: $red;
        height: 2px;
        transition: all 0.3s ease-out;
    }

    &:hover:before, &:focus:before, &:active:before {
        opacity: 1;
    }
}

// cart animation

@keyframes jump {
    // 0% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
    // 50% { transform: translate(-50%, -150%) scale(1, 1); }
    0% { transform: rotate(15deg); }
    25% { transform:  rotate(-15deg); }
    50% { transform:  rotate(15deg); }
    75% { transform:  rotate(-15deg); }
    100% { transform:  rotate(15deg); }
    // 100% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
}

.jumping {
    animation: jump 0.5s;
}