.sort-content-select {
    overflow: hidden;
    height: 0;
    
    // background-color: $vintage-gray;
    @media screen and (min-width: $tablet-BP) {
       padding: 0;
    }

    @media screen and (min-width: $desktop-BP) {
        height: auto !important;
        background-color: transparent;
        padding: 0 0 2rem 0;
    }
   
    .has-select {
        position: relative;
        font-size: 2.5rem;
        border-bottom: 1px solid $gray-dots;
    
        & > select {
            font-family: $ff-Clawson-b;
            text-transform: uppercase; 
            background: url(/media/backgrounds/PaintBackground-800x70.jpg);
            //outline: none;
            margin: 1.2rem 0 1rem 0;
            width: 100%;
            border: none;
            appearance: none;
            background-image: url(/media/globals/arrow-accordion-2.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 15px) center;
            background-size: 1.5rem;
            padding: 0.2rem 0 0 2rem;
            cursor: pointer;
            font-size: 2rem;

            @media screen and (min-width: $tablet-BP) {
                background-size: 1.7rem; 
            }
           
            @media screen and (min-width: $desktop-BP) {
                font-size: 2rem;
                font-weight: 300;
                padding: 0;
            }

            & > optgroup {
                &.prueba{
                    font-size: 1rem !important;
                } 
            }
        }
    }
}