.cards-slider {

    & > div {
        &.cards-slider-wrapper {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: repeat(3,1fr);
                gap: 0 2rem;
            }
        
            .slick-slide {
                margin: 0 1rem;
            }
        }

        &.cards-slider-tools {
            .cards-slider-dots, .slick-dots{

                position: relative;
                padding: 0;
                @media screen and (min-width: $desktop-BP) {
                    margin-top: 7rem;
                }
                ul {
                    bottom: 4rem;
                    @media screen and (min-width: $tablet-BP) {
                        bottom: 1rem;
                    }
                     
                    @media screen and (min-width: $desktop-BP) {
                        bottom: 2rem;
                    }
                    li {
                        margin: 0 1px;
                      
                        &.slick-active {
                           
                            button {
                               
                                &::before {
                                  
                                    background: $red;
                                    opacity: 1;
                                    transition: all 300ms linear;
                                }
                            }
                        }

                        button {
                            padding:0;

                            &::before {
                                transition: all 300ms linear;
                                content: "";
                                background: $dark;
                                opacity: 1;
                                border-radius: 1rem;
                                width: 0.8rem;
                                height: 0.8rem;
                                opacity: 0.4;
                               
                                
                                @media screen and (min-width: $tablet-BP) {
                                    width: 1.2rem;
                                    height: 1.2rem;
                                }
                            }
                            
                            &:hover {

                                &::before {
                                    background: $red;
                                    opacity: 1;
                                    transition: all 300ms linear;
                                }
                            }
                        }
                    }
                }  
            }
        }
    }

    




   

}