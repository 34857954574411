section.slider-timeline {
    background: url(/media/backgrounds/bg-brown.jpeg) repeat 0 0;
    background-size: cover;
    margin-top: 3rem;
    padding-bottom: 2rem;

    @media screen and (min-width: $tablet-BP) {
        padding: 0;
        padding-bottom: 3rem;
    }

    @media screen and (min-width: $desktop-BP) {
        padding-bottom: 4.4rem;
    }

    & > div {

        &.slider-timeline-slick {
            margin-bottom: 2rem;
            @media screen and (min-width: $tablet-BP) {
                padding: 4rem 0;
            }
            @media screen and (min-width: $desktop-BP) {
                max-width: none;
                padding: 7.6rem 0 4rem;
            }
        }

        &.slider-timeline-dots {
            position: relative;
            padding: 0;
            @media screen and (min-width: $desktop-BP) {
                margin-top: 0;
            }
            ul {
                bottom: 1rem;
                @media screen and (min-width: $desktop-BP) {
                    bottom: 2rem;
                }
                li {
                    height: 10px;
                    width: 10px;
                    &.slick-active {
                        button {
                            &::before {
                                background: $red;
                                opacity: 1;
                                transition: all 300ms linear;
                            }
                        }
                    }
                    button {
                        &::before {
                            transition: all 300ms linear;
                            content: "";
                            background: $dark;
                            opacity: 1;
                            border-radius: 1rem;
                            width: 0.8rem;
                            height: 0.8rem;
                            opacity: 0.4;
                            @media screen and (min-width: $tablet-BP) {
                                width: 1.2rem;
                                height: 1.2rem;
                            }
                        }
                        
                        &:hover {
                            &::before {
                                background: $red;
                                opacity: 1;
                                transition: all 300ms linear;
                            }
                        }
                    }
                }
            }
        }
    }
}