div.slider-community-item {
    & > div {
     
        &:first-child {
            border:$red;
        }

        &:last-child {

            ul {
                padding: 0;
                padding-bottom: 3rem;
                text-align: center;

                @media screen and (min-width: $tablet-BP) {
                    padding-bottom: 4rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    padding-bottom: 1rem;
                }

                li {
                    list-style: none;
                    font-family: $ff-ballinger;
                    padding: 0.5rem;
                    @media screen and (min-width: $tablet-BP) {
                        padding: 0.8rem;
                    }
                }
            }
        }
        
    }
}