.filter-title {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
    border: 1px solid $gray-dots;
    position: relative;
   
    @media screen and (min-width: $tablet-BP) {
        padding: 1rem 2rem 1rem ;
        margin: 0;
        
    }
    @media screen and (min-width: $desktop-BP) {
        display: block;
        padding: 0;
        border: none;
        justify-content: center;
    }
    h3 {
        font-size: 28px;
        font-family: $ff-Alternate-cond;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 0.2rem;
        @media screen and (min-width: $desktop-BP) {
            border-bottom: 3px solid $dark;
            font-size: 25px;
        }

        @media screen and (min-width: $desktop-big-BP) {
            font-size: 28px;
        }
    }
    .filter-icon {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        zoom: 1;
        background: url(/media/globals/mobile-circles-line-filter.svg) no-repeat right center;
        cursor: pointer;
        position: absolute;
        width: 83%;
        height: 63%;

        @media screen and (min-width: $tablet-BP) {
            width: 90%;
        }
        @media screen and (min-width: $desktop-BP) {
            display: none;
        }
    }
}