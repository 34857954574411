section.summary-payment-information {
    @media screen and (min-width: $desktop-BP) {
        padding: 0rem 2rem 0;
        max-width: 1200px;
        margin: 0 auto;
    }
    & > div {
        padding: 0 2rem;
        background: $white;
        border: 1px solid $gray-dots;
        border-top: none;
        margin-bottom: 4rem;
        @media screen and (min-width: $desktop-BP) {
            margin-bottom: 7rem;
            padding: 0 4rem;
        }
        & > div {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 1fr 2fr;
                gap: 6rem;
                border-top: 3px solid $dark;
                margin-bottom: 2rem;
            }
            & > div {               
                &:first-child{
                    font-family: $ff-ballinger;
                    font-size: 2rem;
                    & > div {
                        padding: 1rem 0;
                        @media screen and (min-width: $desktop-BP) {
                            padding: 0;
                        }
                        h3 {
                            font-family: $ff-Cream-b;
                            margin: 0 0 3rem;
                            padding: 0;

                            @media screen and (min-width: $desktop-BP) {
                                padding: 4rem 0 0;
                            }
                        }
                        & > div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid $gray-dots;
                            padding: 1.5rem 0;

                            & > div {
                                display: flex;
                                align-items: center;
                                & > span {
                                    font-size: 1.7rem;
                                }
                                & > sup {
                                    font-size: 1.2rem;
                                }
                            }
                            &.is-indent {
                                @media screen and (min-width: $desktop-BP) {
                                    margin-left: 6rem;
                                }
                                & > span {
                                    font-size: 2rem;
                                    @media screen and (min-width: $desktop-BP) {
                                        font-size: 1.7rem;
                                        font-weight: normal;
                                    }
                                }
                            }
                            &:last-child {
                                span, sup {
                                    font-weight: bold; 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}