.local-delivery-warning{

	margin: 10px 0 20px 0;
	padding: 10px;
	border: 1px solid $red;

	& > p{
		margin: auto;
		color: $red;
	}
}