.payment-information-form.customer {
    padding: 3.5rem 0;
        @media screen and (min-width: $desktop-BP) {
            padding: 4.5rem 0;
        }
        .generic-button {
            line-height: 2rem;
        }
    & > div {
        form {
            .payment {
                h3 {
                    margin: 0 0 2rem;
                    font-size: 2.7rem;
                    font-family: $ff-Cream-b;
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0 0 4rem;
                        font-size: 3.7rem;
                    }
                
                }
            }

            .email-wrapper {
                margin-bottom: 1rem;
                @media screen and (min-width: $tablet-BP) {
                    margin-bottom: 2rem;
                }
                fieldset {
                    padding: 0;
                    border:none;
                    
                    // @media screen and (min-width: $desktop-BP) {
                    //     margin: 0 0 2rem 0 ;
                    // }

                    legend {
                        font-size: 2.6rem;
                        margin-bottom: 1rem;
                        text-transform: uppercase;
                        font-family: "alternate-gothic-atf";
                    }

                    & > div {
                        input {
                            width: 100%;
                            border: none;
                            // //outline: none;
                            appearance: none;
                            font-size: 1.7rem;
                            padding: 1.2rem;
                            border-radius: inherit;
                            background: $gray-bg-soft;
                            @media screen and (min-width: $tablet-BP) {
                                font-size: 1.8rem;
                            }
                            &::placeholder{
                                color: $dark;
                            }
                        }
                    }
                }
            }

            .shipping {
                margin-bottom: 4rem;
                & > div {
                    fieldset {
                        padding: 0;
                        border:none;
                        margin: 0;
                        .grid {
                            .recipient {
                                @media screen and (min-width: $desktop-BP) {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    gap: 2rem;
                                }
                                & > div {
                                    &:first-child {
                                        margin-bottom: 2rem;
                                        @media screen and (min-width: $desktop-BP) {
                                            margin-bottom: 0;
                                        }
                                    }

                                    .label-edit {
                                        display: block;
                                        font-size: 2.6rem;
                                        margin-bottom: 1rem;
                                        text-transform: uppercase;
                                        font-family: "alternate-gothic-atf";
                                    }
                                    input {
                                        width: 100%;
                                        border: none;
                                        //outline: none;
                                        appearance: none;
                                        font-size: 1.7rem;
                                        padding: 1.2rem;
                                        border-radius: inherit;
                                        background: $gray-bg-soft;
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 1.8rem;
                                        }
                                        &::placeholder{
                                            color: $dark;
                                        }
                                    }
                                }
                            }   
                        }   
                    }
                }
            }
        }
    }
}