.card {
    margin-bottom:2rem;
    position: relative;

    .logo-hover {
        display: none;
    }

    &:first-child {
        margin-bottom:5rem;
        margin-top: 10rem;

        @media screen and (min-width: $tablet-BP) {
            margin-bottom:2rem;
            margin-top: 0;
        }


            & > a {
                .sticker {
                    display: none;
                    @media screen and (min-width: $tablet-BP) {
                        display: none;
                    }
                }

                .logo-hover {
                    display: block; 

                    &::before {
                        content: "";
                        background: url(/media/globals/logo-card-hover.png) no-repeat 0 0;
                        background-size: 14rem;
                        width: 14rem;
                        height: 14rem;
                        position: absolute;
                        top: -90px;
                        left: 0;
                        z-index: 1;   
                    }
                }
            }

    } 

    /*&:last-child {
        &.has-eat-good-icon {
            & > a {
                .sticker {
                    @media screen and (min-width: $tablet-BP) {
                        display: block;
                    }
                }                
            }
        }
    }*/

    &.has-eat-good-icon{
        & > a {
            .sticker {
                position: absolute;
                display: inline-block;
                width: 12rem;
                height: 12rem;
                zoom: 1;
                right: 1rem;
                top: -3rem;

                &::before {
                    content: "";
                    background: url(/media/home/WB-FOOD.png) no-repeat 0 0;
                    background-size: 12rem;
                    width: 12rem;
                    height: 12rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    transform: rotate(11deg);
                    transition: transform .3s ease-in-out;
                    
                    @media screen and (min-width: $tablet-BP) {
                        right: 2rem;
                        transform: rotate(13deg);
                    }
                }

                @media screen and (min-width: $desktop-BP) {
                    &:hover {
                        &::before {
                            transition: transform .3s ease-in-out;
                            transform: rotate(18deg) !important;
                        }
                    }
                }
            }  

            /*.logo-hover {
                position: absolute;
                display: inline-block;
                width: 14rem;
                height: 14rem;
                zoom: 1;
                left: 0rem;
                top: -9rem;

                @media screen and (min-width: $tablet-BP) {

                    top: -6.5rem;

                    &::before {
                        background-size: 10rem;  
                    }
                }

                @media screen and (min-width: $desktop-BP) {
                    left: 4rem;
                    top: -9rem;

                    &::before {
                        background-size: 14rem;  
                    }
                }
                 
            }*/
        }
    }

    a {
        text-decoration: none;
        border:1px solid $gray-dots;
        display: block;
        background: white;
    }

    figure {
        margin: 0;
       
        & >img {
            width: 100%;
            height: auto;
        }

        figcaption {
            text-align: center;
            padding: 3rem 0 3rem;
            h4 {
                font-family: $ff-Cream-b;
                margin: 0;
            }
            p {
                margin: 0;
                font-family: $ff-ballinger;

                img {
                    margin-left: 1rem;
                }
            }
        }
    }
    
}