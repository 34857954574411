.event-card {
    border:1px solid $gray-dots;
    background: $white;
    padding: 4rem 2rem;
    margin-bottom: 3rem;

    @media screen and (min-width: $tablet-BP) {
        padding: 4rem;
    }

    &.hide {
        opacity: 0;
        display: none;
        min-height: 50px;
        transition: all 500s ease;
        animation: selected 300ms 1;
    }

    @media screen and (min-width: $desktop-BP) {
        margin-bottom: 3rem;
    }

    & > div {
        @media screen and (min-width: $tablet-BP) {
            display:flex;
        }
        & > div {
            
            &:first-child {
                @media screen and (min-width: $tablet-BP) {
                    padding-right: 4rem;
                }
                figure {
                    margin: 0;

                    img {
                        width: 90%;
                        margin: 0 auto;
                        height: auto;
                        border: 1px solid $gray-dots;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 3rem;
                        

                        @media screen and (min-width: $tablet-BP) {
                            width: 31rem;
                            margin-bottom: 0;   
                        }

                        @media screen and (min-width: $desktop-BP) {
                            width: 20rem;  
                        }
                    }
                }
            }
        
            &:last-child{
                
                h3 {
                    margin:0;
                    padding-bottom: 1rem;
                    line-height: 0.7;
                    font-family: $ff-Cream-b;
                    text-align: center;
                    font-size: 3.8rem;

                    @media screen and (min-width: $tablet-BP) {
                        text-align: left;
                        margin-bottom: 0;
                    }
                }

                div {
                    font-family: $ff-Alternate;
                    text-transform: uppercase;
                    font-size: 1.7rem;
                    text-align: center;
                    @media screen and (min-width: $tablet-BP) {
                        font-size: 2rem;
                        text-align: left;
                    }
                }

                p {
                    text-align: center;
                    margin: 2rem 0 4rem;
                    font-weight: 300;
                    @media screen and (min-width: $tablet-BP) {
                        text-align: left;
                        margin: 2rem 0;
                    }

                }

                a {
                    @media screen and (min-width: $tablet-BP) {
                        font-family: $ff-HouseSlam-r;
                        color: $red;
                        font-size: 2rem;
                        border:none;
                        text-decoration: underline;
                        display: inline;
                        padding: 0;
                    }
                }
            }
        }
    }
}