section.family-owned {
    background:url(/media/home/bg-brown.jpeg) repeat 0 0;
    background-size: cover;
    margin-top: 6rem;
    overflow: hidden;

    @media screen and (min-width: $tablet-BP) {
       background-size: cover;
    }

    @media screen and (min-width: $desktop-big-BP) {
        padding: 5rem 0;
    }

    & > div {
        @media screen and (min-width: $desktop-big-BP) {
            width: 100vw;
            display: flex;
            flex-direction: row-reverse;
            max-width: none;
            padding: 0;
            padding-right: 8rem;
        }
       
        & > div {
            &:first-child { //circle
                position: relative;
                background:$white;
                padding: 2rem 3rem 3rem;
                border: 1px solid $gray-dots;
                box-shadow: $soft-shadow-inside;

                @media screen and (min-width: $tablet-BP) {
                    padding: 4rem 3rem 4rem;
                }
    
                @media screen and (min-width: $desktop-big-BP) {
                    width: 65rem;
                    padding: 8rem 8rem 0rem;
                    align-self: center;
                    padding: 5rem 10rem 6rem 7rem;
                    margin-left: -15rem;
                }

                & > span {
                    position:absolute;
                    width: 10rem;
                    height: 10rem;
                    right: 2rem;
                    top: -3rem;
                    display: inline-block;
                    zoom: 1;

                    @media screen and (min-width: $tablet-BP) {
                        width: 17rem;
                        height: 17rem;
                        top: -4rem;
                    }

                    &::before {
                        content: "";
                        background:url(/media/home/WB-OurStory.png) no-repeat 0 0;
                        background-size: 11rem 11rem;
                        position:absolute;
                        width: 11rem;
                        height: 11rem;
                        right: -1rem;
                        top: 0;
                        zoom: 1;
                        transform: rotate(9deg);
                        transition: transform .3s ease-in-out;

                        @media screen and (min-width: $tablet-BP) {
                            background-size: 16rem 16rem;
                            width: 17rem;
                            height: 17rem;
                            right: 0;
                            transform: rotate(-14deg);
                        }
                    }
                  
                 
                    @media screen and (min-width: $desktop-BP) {
                        &:hover {
                            &::before {
                                transition: transform .3s ease-in-out;
                                transform: rotate(-9deg) !important;
                            }
                        }
                    }
                }
                    
                h4 {
                    font-family: $ff-Cream-b;
                    padding-right:8rem ;

                    @media screen and (min-width: $desktop-big-BP) {
                        padding-right: 16rem;
                    }
                }

                p {
                    font-family: $ff-ballinger;
                    @media screen and (min-width: $tablet-BP) {
                        margin-top: 4rem;
                        margin-bottom: 4rem;
                    }

                    @media screen and (min-width: $desktop-big-BP) {
                        margin-top: 2rem;
                        margin-bottom: 4.5rem;
                    } 
                }
               
            }
            
            &:last-child { //image

                @media screen and (min-width: $desktop-big-BP) {
                    flex: 1;
                    height: 90%;
                    align-self: center;
                }

                figure {
                    margin: 0;
                    img {
                        width: 100%;
                        height: auto;
                        border: 1px solid $gray-dots;
                        
                        @media screen and (min-width: $desktop-BP) {
                            height: 403px;
                            object-fit: cover;
                            object-position: center;
                        }
                        @media screen and (min-width: $desktop-big-BP) {
                            border: none;
                            height: 550px;
                            object-fit: cover;
                            object-position: center;
                        
                        }
                    }
                }
           
            }
        }
    }
   
}