.payment-information-form.shipping {
    padding: 3.5rem 0;
        @media screen and (min-width: $desktop-BP) {
            padding: 4.5rem 0;
        }
        .generic-button {
            line-height: 2rem;
        }
    & > div {
        form {

            .payment {
                h3 {
                    margin: 0 0 2rem;
                    font-size: 2.7rem;
                    font-family: $ff-Cream-b;
                    @media screen and (min-width: $tablet-BP) {
                        margin: 0 0 4rem;
                        font-size: 3.7rem;
                    }
                
                }
            }

            & > div {
                .checkcontainer {
                    font-size: 1.8rem;
                    font-style: italic;
                    margin: 1rem 0 0.5rem 0;
                    
                    @media screen and (min-width: $tablet-BP) {
                        width: 48%;
                    }

                    label {
                        font-family: "Cream-Regular";
                    }
                    .price {
                        padding-left: 1rem;
                    }
                    .locationname {
                        padding: 0 0 0 2rem;
                    }
                }
            }

            .cta-prev-cont {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 5rem;
                margin-top: 4rem;
                @media screen and (min-width: $tablet-BP) {
                    margin-top: 3rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    margin-top: 4rem;
                    gap: 41rem;
                }
                input {
                    margin: 0;
                }
            }

            .grid {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}