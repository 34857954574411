section.slider-home {
    padding: 0;
    background: url(/media/home/bg-brown.jpeg);
    background-size: cover;
    overflow: hidden;
    
    @media screen and (min-width: $tablet-BP) {
        margin: 0;
    }

    & > div {
        max-width: none;
        margin: 0;
        position: relative;
        padding: 0;
        @media screen and (min-width: $tablet-BP) {
            margin-bottom: -2px;
        }
        .container {
            @media screen and (min-width: $tablet-BP) {
                display: flex;
            }

            .slider-home-wrapper{
                
                @media screen and (min-width: $tablet-BP) {
                    width: 58rem;
                    align-self: center;
                    position: relative;
                    z-index: 2;
                    position: absolute; /* @update-nov-23 */
                }
                @media screen and (min-width: $desktop-big-BP) {
                    width: 65rem;
                    position: absolute; /* @update-nov-23 */
                }

                .slider-home-cards {
                    padding: 0;
                    margin: 0;


                    @media screen and (min-width: $tablet-BP) {
                        padding: 4rem;
                    }
                    @media screen and (min-width: $desktop-big-BP) {
                        padding: 0;
                        padding-left: 7rem;
                    }
                }

                .slider-home-controls {
                    position: relative;
                    padding: 0;

                    @media screen and (min-width: $desktop-big-BP) {
                        margin-left: 13rem;
                        display: flex;
                        position: absolute;
                        bottom: 15px;
                    }

                    .slider-home-dots {
                        position: relative;

                        @media screen and (min-width: $desktop-big-BP) {
                            // display: inline;
                        }

                        ul.slick-dots {
                            display: flex;
                            justify-content: flex-start;
                            padding-left: 5.5rem;
                            bottom: 1rem;
                            @media screen and (min-width: $tablet-BP) {
                                justify-content: flex-start;
                                padding-left: 7.5rem;
                                bottom: 6rem;
                                right: 6rem;
                                padding-left: 12.7rem;
                            }
                            @media screen and (min-width: $desktop-big-BP) {
                                justify-content: flex-start;
                                position: static;
                                padding: 0;
                                // display: inline;
                            }
                            
                            li {
                                width: 7px;
                                @media screen and (min-width: $tablet-BP) {
                                    width: 15px;
                                }

                                &.slick-active {
                                    button {
                                        &::before {
                                            background: $red;
                                            transition: all 300ms linear;
                                        }
                                    }
                                }
                                
                                button {
                                    width: 7px;
                                    @media screen and (min-width: $tablet-BP) {
                                        width: 15px;
                                    }
                                    &::before {
                                        transition: all 300ms linear;
                                        content: "";
                                        background: $gray-dots;
                                        opacity: 1;
                                        border-radius: 1rem;
                                        width: 0.8rem;
                                        height: 0.8rem;
                                        @media screen and (min-width: $tablet-BP) {
                                            width: 1rem;
                                            height: 1rem;
                                        }
                                        @media screen and (min-width: $desktop-big-BP) {
                                            background: $white;
                                        }
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        &:hover {
                                            &::before {
                                                background: $red;
                                                transition: all 300ms linear;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .slider-home-prev {
                        display: none !important;
                        color: $gray-dots;
    
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 2rem;
                            line-height: 0.7rem;
                            cursor: pointer;
                            display: block !important;
                            color: $white;
                            transition: all 300ms linear;
                            &:hover {
                                color: $red;
                                transition: all 300ms linear;
                            }
                        }
                    }
                    .slider-home-next {
                        display: none !important;
                        color: $gray-dots;
    
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 2rem;
                            line-height: 0.7rem;
                            cursor: pointer;
                            display: block !important;
                            color: $white;
                            transition: all 300ms linear;
                            &:hover {
                                color: $red;
                                transition: all 300ms linear;
                            }
                        }
                    }
                }

            }

            //nuevo wrapper para el slider, generalmente no quieres manipular slick directamente con css, es mejor ponerlo en un div y manipular ese div. 
            // ya depsues era solo darle el width necesario al container.
            .images-slider-container{

                margin-left: 0;
                width: 100%;
                position: absolute;
                height: 100%;
                // padding-top: 3rem;


                /* block commented on @update-nov-23 
                @media screen and (min-width: $tablet-BP) {
                    // width del container es igual al 100% menos el width del cuadro blanco mas el margen negativo q le estamos dando
                    width: calc(100% - 58rem + 47rem);
                    margin-left: -47rem;
                }

                @media screen and (min-width: $desktop-BP) {
                    margin-left: -39rem;
                    width: calc(100% - 58rem + 39rem);
                }
                @media screen and (min-width: $desktop-big-BP) {
                    margin-left: -15rem;
                    width: calc(100% - 65rem + 15rem); //los 15 se le quitan del margin
                }
                */

                @media screen and (min-width: $tablet-BP) {
                    position: relative;
                    padding-top: 0;
                }
            }

            .slider-home-images{
                margin-bottom: 5rem;
                height: 100%;

                &::after {
                    content: "";
                    background: $white;
                    left: 3rem;
                    right: 3rem;
                    height: 20px;
                    bottom: -18px;
                    position: absolute;
                    @media screen and (min-width: $tablet-BP) {
                        display: none;
                    }
                }

                @media screen and (min-width: $tablet-BP) {
                    height: 100%;
                    align-self:center;
                    margin-bottom: 0;
                }

                @media screen and (max-width: $tablet-BP){
                    // figure{
                        // padding: 0 30px;
                    // }
                }

                .slick-list{
                    height: 100%;
                    
                    .slick-track{
                        height: 100%;

                        .slick-slide{
                            height: 100%;
                        }

                        .slick-slide > *{
                            display: flex !important;
                            height: 100%;
                        }
                    }
                }

                figure {
                    margin: 0;
                    height: 100%;
                    

                    // a la imagen le damon un fix height para q no crezca mas de lo necesario
                    // y le damos object fit pare q no pierda las dimensiones
                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        
                        @media screen and (min-width: $tablet-BP){
                            height: 485px;
                            object-fit: contain;
                        }

                        @media screen and (min-width: $desktop-BP){
                            height: 650px;
                            object-fit: cover;
                        }
                    }
                }
            }
            
        }
    }

    .slide {
        position: relative;
        width: 100%;
        // height: 100%;
        overflow: hidden;
    }
    /* Background video container */
    .video-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200vw;  /* Aspect ratio 16:9 */   
        height: 100vh;
        transform: translate(-50%, -50%);
        z-index: 0;
        pointer-events: none; /* Prevent interaction */
    }
    .youtube-player{
        width: 100%;
        height: 100%;

    }
    /* YouTube Iframe */
    .youtube-player iframe {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    /* Content Overlay (Text, Buttons, etc.) */
    .video-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: white;
        text-align: center;
        font-size: 2rem;
    }
    @media screen and (min-width: $tablet-BP) {
        .slide {
            height: calc(100vh - 120px);
        }

        .video-bg{
            width: 100vw;
            height: 100vh;
        }
    }
}