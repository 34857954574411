.cart-form {
    position: relative;
    & > div {
        &.grid {
            & > div {
                display: flex;
                flex-direction: column-reverse;
                @media screen and (min-width: $desktop-BP) {
                    display: grid;
                    grid-template-columns: auto 29rem 14rem;
                    gap: 0 4rem;
                }
                & > div {
                    @media screen and (min-width: $desktop-BP) {
                        margin-bottom: 6rem;
                    }
                    &:first-child {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 2rem;
                        & > textarea {
                            height: 10rem;
                            resize: none;
                            background-color: $gray-bg-soft;
                            border: none;
                            padding: 1.5rem;
                            border-radius: inherit;
                            // //outline: none;
                            font-size: 16px;
                            @media screen and (min-width: $tablet-BP) {
                                font-size: 18px;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                height: 14.3rem;
                            }
                            &::placeholder {
                                color: $dark;
                                font-family: $ff-Cream-r;
                                font-style: italic ;
                            }
                        }
                        .text-help{
                            margin: 1rem 0;
                            font-family: $ff-Cream-r;
                            font-size: 15px;
                            margin-bottom: 2rem;
                        }
                    }
                    &:nth-child(2) {
                        & > div {
                            &.date-select {
                                position: relative;
                                margin: 1rem 0;
                                &::before {
                                    content: "";
                                    background: url(/media/ecommerce/calendar-icon.svg) no-repeat center center;
                                    background-size: 2.2rem;
                                    position: absolute;
                                    height: 2rem;
                                    width: 3rem;
                                    top: 0;
                                    left: 0.9rem;
                                    bottom: 0;
                                    margin: auto;
                                }
                                & > input{
                                    background-color: $gray-bg-soft;
                                    border: none;
                                    border-radius: inherit;
                                    // //outline: none;
                                    width: 100%;
                                    padding: 1rem 7rem;
                                    font-family:$ff-Cream-b;
                                    appearance: none;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                    background-size: 1.5rem;
                                    background-color: $gray-bg-soft;
                                    font-size: 19px;
                                    @media screen and (min-width: $tablet-BP) {
                                        text-align: left;
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        font-size: 18px;
                                        padding: 1rem 5rem;
                                    }
                                }
                            }
                            &.time-select,
                            &.pickup-select {
                                position: relative;
                                margin: 1rem 0;
                                &::before {
                                    content: "";
                                    background: url(/media/ecommerce/delivery-car-icon.svg) no-repeat center center;
                                    background-size: 2.8rem;
                                    position: absolute;
                                    height: 2rem;
                                    width: 3rem;
                                    top: 0;
                                    left: 1rem;
                                    bottom: 0;
                                    margin: auto;
                                }
                                & > select {
                                    width: 100%;
                                    padding: 1rem 7rem;
                                    font-family: $ff-Cream-b;
                                    border: none;
                                    border-radius: inherit;
                                    border-radius: 0;
                                    appearance: none;
                                    // //outline: none;
                                    font-size: 19px;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                    background-size: 1.5rem;
                                    background-color: $gray-bg-soft;
                                    @media screen and (min-width: $tablet-BP) {
                                        direction: ltr; 
                                    } 
                                    @media screen and (min-width: $desktop-BP) {
                                        font-size: 18px;
                                        padding: 1rem 5rem;
                                    }
                                }
                            }
                            &:last-child {
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &:last-child {
                        & > label { 
                            margin-top: 1rem;
                            @media screen and (min-width: $desktop-BP) { 
                                margin-top: 0;
                            }
                        }
                        
                        &.has-select{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-top: 2rem;
                            @media screen and (min-width: $desktop-BP) { 
                                justify-content: flex-start;
                                margin: 0;
                                align-items: flex-start;
                            }
                            
                            & > select {
                                text-indent: calc(((100% - 19px) + 15px) / 2);
                                width: 100%;
                                padding: 1rem 2rem 1rem 1.5rem;
                                font-family: $ff-Cream-b;
                                border: none;
                                border-radius: inherit;
                                appearance: none;
                                // //outline: none;
                                font-size: 19px;
                                background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                background-size: 1.5rem;
                                background-color: $gray-bg-soft;
                                @media screen and (min-width: $tablet-BP) {
                                    padding: 1rem 3.5rem 1rem 0.5rem;
                                    // text-indent: calc(((100% - 19px) + 40px) / 2);
                                    text-align: center;
                                }
                                @media screen and (min-width: $desktop-BP) {
                                    @-moz-document url-prefix(){
                                        & {
                                            text-indent: 0;
                                        }
                                    }
                                }
                            }
                            .select-amount {
                                margin-top: 0;
                            }
                        }
                    }
                    & > label {
                        font-size: 1.6rem;
                        font-family: $ff-sansSerif;
                        margin-bottom: 1rem;
                        margin-top: 3rem;
                        text-align: center;
                        display: block;
                        font-weight: bold;
                        @media screen and (min-width: $tablet-BP) {
                            font-size: 2rem;
                        }
                        @media screen and (min-width: $desktop-BP) { 
                            text-align: left;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &:last-child {
            text-align: center;
            @media screen and (min-width: $desktop-BP) { 
                    display: grid;
                    align-items: center;
                    grid-template-columns: 5fr auto;
                    text-align: left;
                }
                & > div {
                    &:nth-child(1){
                        p {
                            color:$dark-second-gray;
                        }
                    }
                    &:nth-child(2) {
                    
                    @media screen and (min-width: $desktop-BP) { 
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                    }
                    & > input {
                        @media screen and (min-width: $desktop-BP) { 
                            margin: 0 0 0 3rem !important;
                            padding: 1rem 4rem !important;
                        }
                    }
                    & > label {
                        input {
                            position: absolute;
                            left: -999999px;
                            top: -999999px;
                            text-indent: -999999px;
                        }
                        & > a {
                            text-align: center;
                            display: inline-block;
                            font-family: $ff-sansSerif;
                            margin-top: 2rem;
                            padding-bottom: 0.5rem;
                            text-decoration: none;
                            font-size: 1.5rem;
                            font-weight: bold;
                            border-bottom: 2px solid $gray;
                            @media screen and (min-width: $desktop-BP) { 
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
} 