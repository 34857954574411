section.more-products {

    padding: 0;

    @media screen and (min-width: $desktop-BP) { 
         padding: 0 4rem;
    }

    & > div {
        padding: 0;

        @media screen and (min-width: $desktop-BP) { 
            padding: 4rem 4rem 0;
            max-width: 1200px;
            margin: 0 auto;

            
            &.no-icon {
                max-width: 1200px;
                padding: 4rem 2rem 0; 
            }
        }

        @media screen and (min-width: $desktop-big-BP) { 
            padding: 4rem 5rem 0;
            max-width: 1200px;
            margin: 0 auto;

            &.no-icon {
                max-width: 1200px;
                padding: 4rem 2rem 0;
            }
        }

        @media screen and (min-width: $desktop-extra-big-BP) { 
            padding: 4rem 8rem 0;
            max-width: 1500px;
            margin: 0 auto;

            &.no-icon {
                max-width: 1200px;
                padding: 4rem 2rem 0;
            }
        }

        @media screen and (min-width: $desktop-max-big-BP) { 
            padding: 4rem 10rem 0;
            max-width: 1650px;
            margin: 0 auto;

            &.no-icon {
                max-width: 1200px;
                padding: 4rem 2rem 0;
            }
        }
           
        & > div {
            &:first-child {

                padding: 0 2rem;
                text-align: center;

                &.no-icon {
                    padding: 0;
                    h3 {
                        margin: 3rem 0 0 0;

                        @media screen and (min-width: $tablet-BP) { 
                            margin: 3rem 0 2rem 0;
                        }  
                    }
                }

                @media screen and (min-width: $desktop-BP) { 
                    text-align: left;
                }

                h3 {
                    font-family: $ff-HouseSlam-r;
                    font-weight: 100;
                    font-size: 4rem;
                    margin: 0;
                    @media screen and (min-width: $desktop-BP) { 
                        font-size: 6rem;
                        margin-left: 6rem;
                    }
                }
    
                p {
                    margin: 0 0 3rem  0;

                    @media screen and (min-width: $tablet-BP) { 
                        margin: 0 0 5rem  0;
                    }

                    @media screen and (min-width: $desktop-BP) { 
                        margin: 0 0 4rem 9rem;
                    }
                }
            }

            &:last-child {
                position: relative;
               & > span {
                    background: url(/media/ecommerce/WB-Florist.png) no-repeat 0 0;
                    background-size: 10rem;
                    width: 10rem;
                    height: 10rem;
                    display: inline-block;
                    zoom: 1;
                    position:absolute;
                    top: 4rem;
                    left: 0;
                    z-index: 2;
                    top: -2rem;
                    left: 3.5rem;
                    transform: rotate(-16deg);
                    transition: transform .3s ease-in-out;

                    @media screen and (min-width: $tablet-BP) { 
                        background-size: 11rem;
                        width: 11rem;
                        height: 11rem;
                        top: -4rem;
                        left: 13rem;
                    }

                    @media screen and (min-width: $desktop-BP) { 
                        background-size: 13rem;
                        width: 13rem;
                        height: 13rem;
                        top: -7rem;
                        left: -6rem;

                        &:hover {
                            transition: transform .3s ease-in-out;
                            transform: rotate(-14deg);
                        }
                    }
                }
            } 
        }
    }
}