section.soup-of-the-day-item {
    border:1px solid $gray-dots;
    background: $white;
    margin-bottom: 3rem;

    @media screen and (min-width: $tablet-BP) {
        margin-bottom: 4.5rem;
    }

    @media screen and (min-width: $desktop-BP) {
        margin-bottom: 3rem;
    }

    
    & > div {

        @media screen and (min-width: $tablet-BP) {
            padding:3rem 0 4rem;
        }

        & > div {
            
            &:first-child { // title and date
                @media screen and (min-width: $tablet-BP) {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }
                & > div {
                    &:first-child { // contains h3 Berkley title
                        h3 {
                            margin: 0 0 2rem;
                            font-family: $ff-Clawson-b;
                            font-weight: 700;
                            text-transform: uppercase;
                            text-align: center;
                            // font-size: 6rem;
                            @media screen and (min-width: $tablet-BP) {
                                margin: 0;
                            }
                        }
                    }
                    &:last-child { // contains date November
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;

                        @media screen and (min-width: $tablet-BP) {
                            padding-bottom: 0rem;
                            border-bottom: 1px solid $gray;
                        }

                        @media screen and (min-width: $desktop-BP) {
                            padding-top: 0.5rem; 
                        }
                        span {
                            font-size: 1.5rem;
                            font-family: $ff-sansSerif;
                            text-transform: uppercase;
                            @media screen and (min-width: $tablet-BP) {
                                margin-right: 2rem;
                            }
                        }
                        time {
                            font-family: $ff-HouseSlam-r;
                            text-transform: uppercase;
                            font-size: 3rem;
                        }
                    }
                }
        
            }

            &:last-child {
                border-bottom: 3px solid $dark;
                border-top: 3px solid $dark;
                padding: 2rem 0;
                font-family: $ff-sansSerif;
                @media screen and (min-width: $tablet-BP) {
                    padding: 1rem 0;
                }
                ol {
                    counter-reset: item;
                    padding: 0;
                    @media screen and (min-width: $tablet-BP) {
                        // display: flex;
                        // flex-direction: column;
                        // flex-wrap: wrap;
                        display: block;
                        column-count: 2;
                        padding-bottom: 2rem;
                        // height: 22rem;
                    }
                  
                    li {
                        margin: 0;
                        padding: 1rem 0 1rem 4rem;
                        font-size: 1.4rem;
                        font-weight: 100;
                        display: block;
                        position: relative;
                        border-bottom: 1px solid $gray-dots;
                        word-wrap: break-word;

                        @media screen and (min-width: $tablet-BP) {
                            font-size: 15px;
                            // width: 49%;
                            // &:nth-child(6),
                            // &:nth-child(7),
                            // &:nth-child(8),
                            // &:nth-child(9),
                            // &:nth-child(10) {
                            //     margin-left: 1rem;
                            // }
                        }
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 17px;
                            // &:nth-child(6),
                            // &:nth-child(7),
                            // &:nth-child(8),
                            // &:nth-child(9),
                            // &:nth-child(10) {
                            //     margin-left: 2rem;
                            // }
                        }

                        &::before {
                            content: counter(item) "  ";
                            counter-increment: item;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            font-size: 1.8rem;
                            font-weight: bold;
                            width: 3.5rem;
                            text-align: center;
                            border-right: 1px solid $gray-dots;
                            padding-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}