/****************** GLOBAL *********************/


/*-----------------------------------*\
	FONT FACE
\*-----------------------------------*/

@font-face {
	font-family: 'Cream-Regular';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-Cream-r/Cream-Regular.eot');
	src: url('../fonts/westborn-fonts/ff-Cream-r/Cream-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-Cream-r/Cream-Regular.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-Cream-r/Cream-Regular.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-Cream-r/Cream-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Cream-Medium';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-Cream-m/Cream-Medium.eot');
	src: url('../fonts/westborn-fonts/ff-Cream-m/Cream-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-Cream-m/Cream-Medium.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-Cream-m/Cream-Medium.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-Cream-m/Cream-Medium.ttf') format('truetype');

}

@font-face {
	font-family: 'Cream-Bold';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.eot');
	src: url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.ttf') format('truetype'),
	url('../fonts/westborn-fonts/ff-Cream-b/Cream-Bold.svg#svgCream-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'ClawsonHighBold';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-Clawson-b/ClawsonHighBold.eot');
	src: url('../fonts/westborn-fonts/ff-Clawson-b/ClawsonHighBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-Clawson-b/ClawsonHighBold.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-Clawson-b/ClawsonHighBold.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-Clawson-b/ClawsonHighBold.ttf') format('truetype');
}

@font-face {
	font-family: 'HouseSlant-Regular';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-HouseSlam-r/HouseSlant-Regular.eot');
	src: url('../fonts/westborn-fonts/ff-HouseSlam-r/HouseSlant-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-HouseSlam-r/HouseSlant-Regular.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-HouseSlam-r/HouseSlant-Regular.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-HouseSlam-r/HouseSlant-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'House-Scrip-Semi-Bold';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-HouseScrip-sb/SignPainter-HouseScript.eot');
	src: url('../fonts/westborn-fonts/ff-HouseScrip-sb/SignPainter-HouseScript.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-HouseScrip-sb/SignPainter-HouseScript.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-HouseScrip-sb/SignPainter-HouseScript.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-HouseScrip-sb/SignPainter-HouseScript.ttf') format('truetype');
}

@font-face {
	font-family: 'SignPainter';
	font-display: swap;
	src: url('../fonts/westborn-fonts/ff-SignPainter/SignPainter-HouseScript.eot');
	src: url('../fonts/westborn-fonts/ff-SignPainter/SignPainter-HouseScript.eot?#iefix') format('embedded-opentype'),
	url('../fonts/westborn-fonts/ff-SignPainter/SignPainter-HouseScript.woff2') format('woff2'),
	url('../fonts/westborn-fonts/ff-SignPainter/SignPainter-HouseScript.woff') format('woff'),
	url('../fonts/westborn-fonts/ff-SignPainter/SignPainter-HouseScript.ttf') format('truetype');
}

/*-----------------------------------*\
	FONT FAMILY
\*-----------------------------------*/

$ff-serif: serif; 

$ff-sansSerif: "ballinger", sans-serif;

$ff-ballinger: "ballinger"; //this is complet

$ff-Alternate: 'alternate-gothic-atf'; //ok

$ff-Alternate-cond: "alternate-gothic-condensed-a"; //buttons

$ff-Cream-r: 'Cream-Regular'; //this is complet

$ff-Cream-m: 'Cream-Medium'; //this is complet

$ff-Cream-b: 'Cream-Bold'; //this is complet

$ff-Clawson-b: 'ClawsonHighBold'; //this is complet

$ff-HouseSlam-r: 'HouseSlant-Regular'; //i need a original typo!!

$ff-HouseScrip-sb: 'House-Scrip-Semi-Bold'; //ok

$ff-SignPainter: 'SignPainter';

// color palette
$white: #fff;
$soft-white: #FFFEF9;
$dark: #252525;
// $red: #E8392D;
$red: #CF1A0D;
$dark-red: #AF180D;
$gray: #9A9A9A;
$black: #252525;
$soft-gray:#FBF8F1;
$dark-gray:#757474;
$dark-second-gray:#676262;
$gray-dots:#CCC8BE;
// $sky-blue: #2E81BB;
$gray-bg-soft:#F5F2EB;
$vintage-yellow: #FFFEF9;
$vintage-gray: #EFEADF;
$transparent-black: rgba(0,0,0,0.5);
$soft-shadow-inside: 0 0px 25px 0 rgba(0,0,0,0.2);
$logo-shadow: 1px 2px 5px 7px rgba(0,0,0,0.1);

// spacing & containers
$sectionPadding: 96px;
$sectionPadding-medium: 60px;
$sectionPadding-small: 30px;
$sectionPaddingTop: 130px;
$sectionPaddingTop-medium: 65px;
$sectionPaddingTop-small: 30px;

/*-----------------------------------*\
	MEDIA QUERIES IN CORRECT ORDER
\*-----------------------------------*/
$mobile-small-BP: 400px;
$mobile-BP: 640px;
$tablet-BP: 768px;
$desktop-BP: 1024px;
$desktop-big-BP: 1200px;
$desktop-extra-big-BP: 1450px;
$desktop-max-big-BP: 1600px;

/*-----------------------------------*\
	UNIVERSAL
\*-----------------------------------*/
html {
	box-sizing: border-box;
	background: url(/media/backgrounds/PaintBackground1.jpg) repeat 0 0;
	font-size: 62.5%;
	line-height: 1.1;
    scroll-behavior: smooth;
    
}

html,
body {
	margin: 0;
	padding: 0;
	color: $black;
}

body {
	padding-top: 6rem;
	scroll-margin-top: 6rem;
	
	@media screen and (min-width: $tablet-BP) {
        padding-top: 8rem;
		scroll-margin-top: 14rem;
	}
	@media screen and (min-width: $desktop-BP) {
		scroll-margin-top: 11rem;
	}
	* {
		scroll-margin-top: 6rem;
		@media screen and (min-width: $tablet-BP) {
			scroll-margin-top: 14rem;
		}
		@media screen and (min-width: $desktop-BP) {
			scroll-margin-top: 11rem;
		}
	}

	&[class^="shop"] {
		@media screen and (min-width: $tablet-BP) {
			padding-top: 10rem ;
		}
		* {
			@media screen and (min-width: $tablet-BP) {
				scroll-margin-top: 16rem;
			}
			@media screen and (min-width: $desktop-BP) {
				scroll-margin-top: 17rem;
			}
		}

		.sticky-tablet {
			@media screen and (min-width: $desktop-BP){
				top: 12rem;
				
			}
		}
	}
	&[class^="shop/groceries"] {
		// padding-top: 12rem;
		@media screen and (min-width: $tablet-BP) {
			padding-top: 16rem ;
		}
		@media screen and (min-width: $desktop-BP) {
			padding-top: 20rem ;
		}
	}
	
}


*,
*:before,
*:after {
	box-sizing: border-box;
}

[x-cloak] { display: none !important; }

/*-----------------------------------*\
	SKIP NAV
\*-----------------------------------*/
#skip-to-content {
	position: absolute;
	top: 0px;
	left: 0;
	transform: translateY(-800%);
	z-index: 999999;
	transition: all .35s linear;
	padding: .5em 1em;
	text-decoration: none;

	&:focus {
		transform: translateY(0);
	}
}

/*-----------------------------------*\
	SECTION
\*-----------------------------------*/

section {
	padding: 0 2em;

	@media(min-width: $tablet-BP) {
		padding: 0 4em;
	}

	&>div {
		padding: 4em 0;
		max-width: 1200px;
        margin: 0 auto;

		@media(min-width: $tablet-BP) {
			padding: 4em 2em;
        }
    }
}


/*-----------------------------------*\
	LIST
\*-----------------------------------*/

ol,ul{
	padding: 0;
	margin: 0;
	list-style: none;
	li{
		padding: 0;
		margin: 0;
	}
}

.hide-on-small-only {
	display: none;
	@media(min-width: $tablet-BP) {
		display: block;
	}
}

.hide-on-med-and-down {
	display: none;
	@media(min-width: $desktop-BP) {
		display: block;
	}
}
.hide-on-large-and-down {
	display: none;
	@media(min-width: $desktop-big-BP) {
		display: block;
	}
}
.hide-on-large-and-up {
	display: block;
	@media(min-width: $desktop-BP) {
		display: none !important;
	}
}
.hide-on-xlarge-and-up {
	display: block;
	@media(min-width: $desktop-big-BP) {
		display: none !important;
	}
}
.hide-on-med-and-up {
	display: block;
	@media(min-width: $tablet-BP) {
		display: none !important;
	}
}

/*-----------------------------------*\
	HELPERS
\*-----------------------------------*/

.o-hidden {
	overflow: hidden;
}

.is-space-bottom {
	margin-bottom: 2rem;
	@media screen and (min-width: $desktop-BP) {
		margin-bottom: 3rem;
	}
}

.is-space-litle-top {
	margin-top: 0.1rem;
}

.sticky-desktop {		
	@media screen and (min-width: $desktop-BP){
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: 2rem;
		// height: 300px;
		will-change: transform;
		
		// &.sticky-small {
		// 	height: 206px;
		// }

		// &.sticky-inter {
		// 	height: 267px;
		// }
	}
}

.sticky-only-tablet {		
	@media screen and (min-width: $tablet-BP){
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: 8rem;
		will-change: transform;
		z-index: 3;
	}
	@media screen and (min-width: $desktop-BP){
		position: static;
	}
}

.sticky-tablet {		
	@media screen and (min-width: $tablet-BP){
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: 8rem;
		// height: auto;
		will-change: transform;

		@media screen and (min-width: $desktop-BP){
			// height: 235px;
			top: 9rem;
			margin-bottom: 2rem;
		}
		
		// &.sticky-small {
		// 	height: 206px;
		// }

		// &.sticky-inter {
		// 	height: 267px;
		// }
	}
}

.checkcontainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	user-select: none;
	
	.locationname {
		position: relative;
		padding: 1rem 0 0.5rem 2rem;
		font-family: $ff-Clawson-b;
		font-size: 2rem;
		@media screen and (min-width: $tablet-BP) {
			font-family: $ff-Alternate;
		}
		@media screen and (min-width: $desktop-BP) {
			padding: 2rem 0 1.5rem 2.4rem;
			font-family: $ff-Clawson-b;
		}
		
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
	
	.checkmark {
		position: absolute;
		top: 0;
		left: 2px;
		bottom: 0;
		margin: auto;
		height: 1rem;
		width: 1rem;
		border: 1px solid #000;
		border-radius: 25px;
		transition: all 200ms ease;
		z-index: 1;
	}
	input:checked {
		~ .locationname {
			color: $red;
			transition: all 200ms ease;
			.checkmark {
				background-color: $red;
				border-color: $red;
				transition: all 200ms ease;
			}
		}
	}

	&.simple {
		input:checked {
			~ label {
				font-family: $ff-Cream-b !important;
				font-style: normal !important;
			}
		}
	}
}

main {
    &.flowers,
	&.catering,
	&.gifts {
        & > div {
            &.column-reverse {
                display: flex;
                flex-direction: column-reverse;
                @media screen and (min-width: $desktop-BP) {
                    display: block;
                }
            }
        }
    }
}

//datepicker
//we customize it with brand fonts :D

.datepicker-panel > ul {
	font-family: $ff-Cream-b;
}

.has-select{

	& > select {
		padding: 1rem 2rem 1rem 1.5rem;
		font-family: $ff-Cream-b;
		border: none;
		border-radius: inherit;
		appearance: none;
		// //outline: none;
		font-size: 17px;
		background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
		background-size: 1.5rem;
		background-color: $gray-bg-soft;
		@media screen and (min-width: $tablet-BP) {
			padding: 1rem 3.5rem 1rem 0.5rem;
		}	   
	}
}

input {
	font-style: normal;
	font-family: $ff-Cream-b;
	&::placeholder {
		font-style: italic;
		font-family: $ff-Cream-r;
	}
} 

textarea {
	font-family: $ff-Cream-b;
	&::placeholder  {
		font-family: $ff-Cream-r;
		font-style: italic;
	}
}

.hidden {
	display: none;
}

//formfree errors
.ff-errors,.error {
	color:$red;
    font-family: $ff-sansSerif;
    font-size: 1.4rem;
	
}

.error {
	padding-top: 0.5rem;
	text-transform: none;
	margin: 0 0 1rem 0 !important;
	font-family: $ff-sansSerif;
}

.no-results{
	grid-template-columns: 100% !important;
}

.no-found{
	font-family: "Cream-Regular";
    font-size: 2.4rem;
    text-align: center;
    margin-top: 4rem;
}

form {
	:disabled {
		opacity: 0.5;

		&:hover {
			cursor:not-allowed;
		}
	}
}

.disabled {
	opacity: 0.5;
}

//calendar freeform careers
.flatpickr-calendar { 
	border: 1px solid $gray-dots;
	border-radius: none;
	box-shadow: $logo-shadow;
	font-family: 'Cream-Bold';
	// -webkit-appearance: none;
    // border-radius: 0;
}
// .flatpickr-current-month {
// 	-webkit-appearance: none;
//     border-radius: 0;
// }
.flatpickr-month {
	border-top: 1px solid #3399FE;
}
.flatpickr-day.today  {
	background: #CCE5FF;
	border-radius: 0;
	color: black;
	border: transparent;
}
.flatpickr-rContainer {
	.flatpickr-weekdays {
		.flatpickr-weekdaycontainer {
			.span {
				.flatpickr-weekday {
					color: $dark;
				}
			}
		}
	}
}

//validation form weddings-contact-custom
.parsley-errors-list {
	.parsley-type,
	.parsley-required{
		color: #CF1A0D;
		font-family: $ff-ballinger;
		font-size: 1.4rem;
		padding: 0.5rem 0;
	}
}


.file {
	opacity: 0;
	width: 0.1px;
	height: 0.1px;
	position: absolute;
}

.file-input label {
	display: block;
	position: relative;
	width: 17rem;
	font-size: 1.8rem;
	height: 50px;
	border-radius: none;
	font-weight: 100;
	background: $red;
	font-family: $ff-Alternate-cond;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: bold;
	// //outline: none;
	cursor: pointer;
	transition: transform .2s ease-out;
}

.file-name {
	color: $dark;
	font-size: 1.5rem;
}

input:hover + label,
input:focus + label {
	transform: scale(1.02);
}

  /* Adding an outline to the label on focus */
input:focus + label {
	outline: 1px solid #000;
	outline: -webkit-focus-ring-color auto 2px;
}



.parsley-errors-list {
	.parsley-string,
	.parsley-type,
	.parsley-required,
	.parsley-address,
	.parsley-hours {
		color: #CF1A0D;
		font-family: "ballinger", sans-serif;
		font-size: 1.4rem;
		padding: 0.5rem 0;
	}
}
div.has-checkbox {
	display: flex;
	align-items: center;
	input[type=checkbox],
	input[type="radio"] {
		-webkit-appearance: none;
		-moz-appearance:    none;
		appearance:         none;
	}

	input[type='checkbox'] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		// //outline: none;
		position: relative;
		width: 2rem;
		height: 2rem;
		background-color: $gray-bg-soft;
		cursor: pointer;
		margin-right: 1rem;
	}
	input[type='checkbox']::before {
		content: '';
		color: #fff;
		position: absolute;
		top: 4px;
		right: 4px;
		bottom: 4px;
		left: 4px;
		background-color: transparent;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-transform: scale(0);
				transform: scale(0);
		-webkit-transition: -webkit-transform 0.25s ease-in-out;
		transition: -webkit-transform 0.25s ease-in-out;
		transition: transform 0.25s ease-in-out;
		transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
		background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=");
		filter: invert(16%) sepia(97%) saturate(3253%) hue-rotate(356deg) brightness(91%) contrast(97%);
	}
	
	input[type='checkbox']:checked::before {
		-webkit-transform: scale(1);
				transform: scale(1);
	}

	label {
		font-size: 1.8rem;
		text-transform: uppercase;
		font-family: $ff-Clawson-b;
		// //outline: none;				
	}
}

/*-----------------------------------*\
	FOR ACCESSIBILITY
\*-----------------------------------*/

//button pause sliders
button.slick-autoplay-toggle-button,
p.slick-instructions.slick-sr-only {
    visibility: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

a, button, input, select, textarea, h3, .checkcontainer, .text-arrow-lottie,label {
	&:focus{
		outline-color: $red;
	} 
}