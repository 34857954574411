section.apply-form {
    padding: 0 1em;
  
    & > div {
        padding: 1rem 0;
        & > div {
            @media screen and (min-width: $tablet-BP) {
                margin: 50px;
                padding: 50px;  
            }      
            
            & > div {
                & > div {

                    &:first-child {
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
}