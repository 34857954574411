section.product-detail {
    @media screen and (min-width: $tablet-BP) {
        padding: 0;
    }
    @media screen and (min-width: $desktop-BP) {
        margin-top: 2rem;
    }
    & > div {
        & > div {
            &.grid {
                background: $white;
                border: 1px solid $gray-dots;
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $desktop-BP) {
                    display: grid;
                    grid-template-columns: 45% auto;
                    grid-template-rows: 1fr;
                    gap: 0 3rem;
                    padding: 6rem;
                }
            }
        }
    }
}