div.slider-timeline-item {
    padding: 0;
    @media screen and (min-width: $tablet-BP) {
        padding: 0 2rem;
    }
    @media screen and (min-width: $desktop-BP) {
        padding: 0 4rem;
    }
    
    &.one {
        @media screen and (min-width: $desktop-BP) {
            padding-bottom: 17rem;
        }
        & > div {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 2.5fr 1.5fr;
            }
            & > div {
                &:first-child {
                    @media screen and (min-width: $desktop-BP) {
                        align-self: flex-start;
                    }
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                
                &.slider-info {
                    @media screen and (min-width: $desktop-BP) {
                        flex: 1;
                        align-self: flex-end;
                        margin-bottom: -13rem;
                        margin-left: -20rem;
                    }
                }
            }
            
        }
    }

    &.two {
        & > div {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 2.5fr 1.5fr;
                direction: rtl;
            }
            & > div {
                &:first-child {
                    @media screen and (min-width: $desktop-BP) {
                        align-self: flex-start;
                        margin-top: 13rem;
                    }
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                
                &.slider-info {
                    @media screen and (min-width: $desktop-BP) {
                        flex: 1;
                        align-self: flex-start;
                        margin-right: -20rem;
                    }
                }
            }
            
        }
    }
}