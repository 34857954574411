section.our-story-article {
    margin-top: 4rem;

    @media screen and (min-width: $tablet-BP) {
        margin-top: 6rem;
    }

    @media screen and (min-width: $desktop-BP) {
      padding: 0;
    }

    & > div {
        padding: 0;
        background: $white;
        border: 1px solid $gray-dots;

        @media screen and (min-width: $desktop-BP) {
            max-width: none;
            background: none;
            border: none;
        }
      
        & > div {
            position: relative;

            &.grid{
                
                @media screen and (min-width: $desktop-BP) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    margin: 2rem auto;
                }

                & > div {
                    &.our-story-bg {
                        @media screen and (min-width: $desktop-BP) {
                            grid-area: 1 / 2 / 2 / 12;
                            background: #fff;
                            border:1px solid $gray-dots;
                        }
                    }
                    
                    &.our-story-image {
                        @media screen and (min-width: $desktop-BP) {
                            grid-area: 1 / 1 / 2 / 6;
                            padding: 7rem 0;
                            display: flex;
                            align-items: center;
                        }

                        @media screen and (min-width: $desktop-big-BP) {
                            grid-area: 1 / 1 / 2 / 7;
                            padding: 9rem 0;
                        }

                        figure {
                            position: relative;
                            margin: 0;

                            img {
                                width: 100%;
                                height: auto;
                                object-fit: cover;
                            }
                            & > span{
                                position:absolute;
                                width: 8rem;
                                height: 8rem;
                                right: 1rem;
                                top: -3.2rem;
                                display: inline-block;
                                
                                @media screen and (min-width: $tablet-BP) {
                                    top: -4.2rem;
                                }

                                &::before {
                                    content:"";
                                    background:url(/media/our-story/WB-OurStory.png) no-repeat 0 0;
                                    background-size: 8rem 8rem;
                                    position:absolute;
                                    width: 8rem;
                                    height: 8rem;
                                    left: 0;
                                    top: 0rem;
                                    display: inline-block;
                                    transform: rotate(9deg);
                                    transition: transform .3s ease-in-out;
    
                                    @media screen and (min-width: $tablet-BP) {
                                        background-size: 11rem 11rem;
                                        width: 11rem;
                                        height: 11rem;
                                        transform: rotate(-14deg);
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        background-size: 12rem 12rem;
                                        width: 12rem;
                                        height: 12rem;
                                        transform: rotate(-18deg);
                                    } 
                                }
    
                                @media screen and (min-width: $desktop-BP) {
                                    &:hover {
                                        &::before {
                                            transition: transform .3s ease-in-out;
                                            transform: rotate(-14deg) !important;
                                        }
                                    }
                                }                              
                            }
                        }
                    }

                    &.our-story-content {
                        padding: 0 1.7rem;
                    
                        @media screen and (min-width: $tablet-BP) {
                            padding: 0 7rem;
                        }

                        @media screen and (min-width: $desktop-BP) {
                            grid-area: 1 / 6 / 2 / 12;
                            padding: 7rem;
                        } 

                        @media screen and (min-width: $desktop-big-BP) {
                            grid-area: 1 / 7 / 2 / 12; 
                            padding: 9rem 6rem;
                        }

                        @media screen and (min-width: $desktop-extra-big-BP) {
                            padding: 9rem 9rem;
                        }
            
                        .cream-r {
                            font-family: $ff-Cream-m;
                            font-weight: bolder;
                            padding: 0;
                            margin-bottom: 3rem;
                            margin-top: 4rem;
                            line-height: 1.3;

                            @media screen and (min-width: $tablet-BP) {
                                margin-bottom: 5rem;
                                margin-top: 6rem; 
                            }
                            @media screen and (min-width: $desktop-BP) {
                                margin:0;
                                line-height: 1.5;
                            }
                        }
    
                        .ballinger-1,
                        .ballinger-2 {
                            font-family: $ff-ballinger;
                            
                            @media screen and (min-width: $desktop-BP) {
                                font-size: 14px;
                            }

                            @media screen and (min-width: $desktop-big-BP) {
                                font-size: 17px;
                            }

                            @media screen and (min-width: $desktop-extra-big-BP) {
                                font-size:18px;
                            }
                            
                        }

                        .ballinger-2 {
                            margin-bottom: 4rem; 

                            @media screen and (min-width: $tablet-BP) {
                                margin-bottom: 7rem;
                            }

                            @media screen and (min-width: $desktop-BP) {
                                margin-bottom: 0;
                            }
                        }
                         
                    }
                }
            }
        }
    }
}