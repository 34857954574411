div.filters-and-sorts {
    @media screen and (min-width: $tablet-BP) {
        background: url(/media/backgrounds/PaintBackground-800x70.jpg) repeat 0 0;
        z-index: 4;
        border-bottom: 1px solid $gray-dots;
    }
    @media screen and (min-width: $desktop-BP) {
        background: none;
        margin-bottom: 3.3rem;
        height: 266px;
        border: none;
    }
    & > div {
        &.hide-on-large-and-up { // mobile and tablet
            & > div {
                &.titles {
                    &.grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        & > div {

                            &.active {
                                background-color: $vintage-gray;
                            }
                        }
                    }
                }
            }
        }
    }
}