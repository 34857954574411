section.cart-empty,
.succes-message-apply-form {
    padding: 2rem 0;
    @media screen and (min-width: $tablet-BP) {
        padding: 10rem 0 17rem;
    }
    & > div {
        text-align: center;
        & > h2 {
            font-family: $ff-Cream-b;
            margin-bottom: 2rem;
        }
        & > a {
            margin: 0 auto;
        }
    }
}