section.entertain {
    margin-top: 6rem;
    @media screen and (min-width: $tablet-BP) {
        margin-top: 4rem;
        margin-bottom: 7rem;
    }

    & > div {
        background: white;
        text-align: center;
        border: 1px solid $gray-dots;
        position: relative;

        @media screen and (min-width: $desktop-BP) {
            min-height: 46rem;
        }

        & > div {
            &:first-child{
                position: absolute;
                left: 1.5rem;
                top: -0.5rem;
                transform: rotate(-12deg);

                @media screen and (min-width: $tablet-BP) {
                    left: -2.5rem;
                    top: -1.5rem;
                }
                @media screen and (min-width: $desktop-BP) {
                    left: -4.5rem;
                    top: 0.5rem;
                }

                h3 {
                    font-family: $ff-HouseSlam-r;
                    color: $red;
                    position: relative;
                    font-weight: 100;
                    text-transform: uppercase;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 292px;
                        height: 5rem;
                        background-image: url(/media/home/Fill1.png);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        bottom: -4rem;
                        left: 3rem;
                        transform: rotate(11deg);
                        @media screen and (min-width: $tablet-BP) {
                            background-image: url(/media/home/entertain-line.svg);
                            bottom: -6rem;
                            left: 6rem;
                            height: 7rem;
                            width: 411px;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            bottom: -5rem;
                        }
                    }
                }
            }
            
            &.grid {
                grid-template-columns: repeat(2, 1fr); 
                display: grid;
                gap: 0 0;
                padding-top: 10rem;
                
                @media screen and (min-width: $tablet-BP) {
                    padding-top: 5rem; 
                }
                @media screen and (min-width: $desktop-BP) {
                    grid-template-columns: repeat(4, 1fr); 
                }
        
                & > div {    
                    a {
                        text-decoration: none;
                        font-family: $ff-Clawson-b;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @media screen and (min-width: $desktop-BP) {
                            &:hover {
                                span {
                                    transition:all .9s ease;
                                    &:first-child {
                                        transition:all .5s ease;
                                        transform:scale(1.1); 
                                    }
                                    &:last-child {
                                        position: relative;
                                        font-family: $ff-HouseSlam-r;
                                        color: $red;
                                        font-size: 3.6rem;
                                        transform: rotate(-11deg);
                                        transition:all .5s ease;
                                        
                                        &::after {
                                            content: "";
                                            position:absolute;
                                            background: url(/media/home/arrow-entertain-hover.svg) no-repeat 0 0;
                                            bottom: -3.5rem;
                                            right: -4rem;
                                            width: 14rem;
                                            height: 100%;
                                            transform: rotate(11deg);
                                            transition:all .5s ease;
                                        }
                                    }
                                }
                            }
                        }
                        span {
                            text-transform: uppercase;
                            padding: 0 2.5rem;
                            @media screen and (min-width: $tablet-BP) {
                                &:last-child {
                                    margin-top: 2rem;
                                }
                            }
                            @media screen and (min-width: $desktop-BP) {
                                transition:all .5s ease;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .icon {
        display: inline-flex;
        zoom: 1;
        &.flowers-icon {
            background: url(/media/home/Flowers-m.svg) no-repeat center top;
            width: 145px;
            height: 130px;
            @media screen and (min-width: $tablet-BP) {
                background-image: url(/media/home/Flowers.svg);
                width: 255px;
                height: 180px;
            }
            @media screen and (min-width: $desktop-BP) {
                width: 223px;
                height: 236px;
                background-position: center 32px;
                background-size: 145px;
            }
            @media screen and (min-width: $desktop-big-BP) {
                width: 255px;
                height: 247px;
                background-size: auto;
            }
        }
        &.gift-basket-icon {
            background: url(/media/home/GiftBasket-m.svg) no-repeat 10px 5px;
            width: 145px;
            height: 130px;
            @media screen and (min-width: $tablet-BP) {
                background-image: url(/media/home/Gift-Basket.svg);
                width: 255px;
                height: 180px;
                background-position: 20px 5px;
            }
            @media screen and (min-width: $desktop-BP) {
                height: 236px;
                background-position: 10px 32px;
                width: 223px;
                background-size: 175px;
            }
            @media screen and (min-width: $desktop-big-BP) {
                width: 255px;
                height: 247px;
                background-position: 20px 37px;
                background-size: auto;
            }
        }
        &.weddings-icon {
            background: url(/media/home/Weddings-m.svg) no-repeat 0 20px;
            width: 145px;
            height: 150px;
            margin-top: 2rem;
            @media screen and (min-width: $tablet-BP) {
                background-image: url(/media/home/Weddings.svg);
                width: 255px;
                height: 236px;
            }
            @media screen and (min-width: $desktop-BP) {
                margin: 0;
                width: 223px;
                background-size: 200px;
                background-position: -1px 25px;
            }
            @media screen and (min-width: $desktop-big-BP) {
                width: 255px;
                background-position: 0 29px;
                background-size: auto;
                height: 247px;
            }
        }
        &.catering-icon {
            background: url(/media/home/Catering-m.svg) no-repeat 3px 0;
            width: 145px;
            height: 150px;
            margin-top: 2rem;
            @media screen and (min-width: $tablet-BP) {
                background-image: url(/media/home/Catering.svg);
                width: 255px;
                height: 236px;
                background-position: 15px 0;
            }
            @media screen and (min-width: $desktop-BP) {
                margin: 0;
                width: 223px;
                background-position: 0 0;
            }
            @media screen and (min-width: $desktop-big-BP) {
                width: 255px;
                height: 247px;
                background-position: 15px 0;
            }
        }
    }
}