.form-custom-orders {
    padding: 0;
    background:$gray-bg-soft;
    
    // @media screen and (min-width: $desktop-BP) {
    //     margin-top: 3rem;
    // }

    .g-recaptcha{
        margin-top: 1rem;
    }

    & > div {
        padding: 0;
        &.expanded {
            button {
                .tab-title {
                    &::after {
                        transform: rotate(180deg);
                        border-color: $red;
                        transition: transform .5s ease-in-out;
                    }
                }
            }
        }

        button.accordion-tab {
            background: $red;
            padding: 0.3rem 2rem;
            position: relative;
            cursor: pointer;
            display: block;
            width: 100%;
            border: none;
            font-weight: bold;
            font-family: $ff-ballinger;
            text-align: left;

            @media screen and (min-width: $tablet-BP) {
                padding: 2.5rem;
                text-align: center;
            }
            @media screen and (min-width: $desktop-BP) {
                background: $red;
                transition: all .3s ease-in-out;

                &:hover {
                    opacity: 0.8;
                    transition: all .3s ease-in-out;
                }
            }
            &::after {
                content: "";
                height: 1px;
                width: 100%;
                left: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                background-color: $white;
                position: absolute;
                display: none;

                @media screen and (min-width: $tablet-BP) {
                    display: block;
                }
            }
            .tab-title {
                background: $red;
                color: $white;
                position: relative;
                z-index: 2;
                padding: 0;
                font-size: 17px;
                text-transform: uppercase;
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: block;

                @media screen and (min-width: $tablet-BP) {
                    font-size: 24px;
                    text-align: center;
                    padding: 0 10rem 0 6rem;
                    display: block;
                    max-width: 85%;
                    margin: 0 auto;
                }
                @media screen and (min-width: $desktop-BP) {
                    padding: 0 10rem 0 6rem;
                    display: inline;
                    background: $red;
                    margin-bottom: 1rem;
                    max-width: none;
                }

                &::after {
                    content: "";
                    background-image: url(/media/ecommerce/wb-form-white-arrow.svg);
                    background-repeat: no-repeat;
                    background-position: 0 0 ;
                    background-size: 2rem;
                    position: absolute;
                    width: 25px;
                    height: 13px;
                    top:0;
                    right: 1rem;
                    bottom: 0;
                    margin: auto;
                    transition: transform .5s ease-in-out;

                    @media screen and (min-width: $tablet-BP) {
                        background-size: 2.5rem;
                        right: 6rem; 
                    }
                }

                .arrow-left,
                .arrow-right {
                    margin: auto; 
                    bottom: 0;
                    top: 0;
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    background-size: 1.5rem;
                }

                .arrow-left {
                    background-image: url(/media/ecommerce/arrow-form-2-HEAD.svg);
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    left: 0;
                    display: none;

                    @media screen and (min-width: $tablet-BP) {
                        display: block;
                    }
                }
    
                .arrow-right {
                    background-image: url(/media/ecommerce/arrow-form-HEAD.svg);
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    right:0;
                    display: none;

                    @media screen and (min-width: $tablet-BP) {
                        display: block;
                    }
                }
            }
        }
        & > div {

    
           &:last-child {
                height: 0;
                overflow: hidden;

                form {
                    & > div {
                        &.grid {
                            padding: 3rem;
                            @media screen and (min-width: $tablet-BP) {
                                margin: 2rem 4rem;
                            }
                            @media screen and (min-width: $desktop-BP) {
                                display: grid;
                                grid-template-columns: 1fr 2fr;
                                gap: 0px 6rem;
                                padding: 5rem 3rem;
                                max-width: 1200px;
                                margin: 0 auto;
                            }
            
                            & > div {
                                & > fieldset {
                                    border:none;
                                    border-radius: inherit;
                                    padding: 0;
                                    margin: 0;
        
                                    & > legend {
                                        font-family: $ff-Alternate;
                                        text-transform: uppercase;
                                        // //outline: none;
                                        margin-bottom: 1rem;
                                        font-size: 2.4rem;
        
                                        @media screen and (min-width: $tablet-BP) {
                                            font-size: 2.6rem;
                                            margin-top: 2rem;
                                        }
                                    }
                    
                                    & > div {
                                        &.flex {
                                            @media screen and (min-width: $tablet-BP) {
                                                display:flex;
                                                flex-direction: column;
                                            }
                                            & > div {
                                                &:first-child {  
                                                    margin-bottom: 2rem;
                                                }
                                                &:last-child{
                                                    margin-bottom: 1rem;
                                                }
                                            }
                                        }
                    
                                        &.is-half {
                                            margin-bottom: 1rem;
                                        }
                    
                                        & > div {
                                            &.has-select {
                                                position: relative;
                                                & > select {
                                                    background-image: url(/media/globals/arrow-accordion-2.svg);
                                                    background-repeat: no-repeat;
                                                    background-position: 97% center;
                                                    background-size: 1.5rem;
                                                }
                                            }
                    
                                            & > textarea {
                                                height: 13rem;
                                                resize: none;
                                            }
                    
                                            & > select,
                                            & > textarea,
                                            & > input {
                                                background: 
                                                $vintage-yellow;
                                                font-family: $ff-Cream-r;
                                                // //outline: none;
                                                padding: 1.7rem;
                                                width: 100%;
                                                border: none;
                                                border-radius: inherit;
                                                appearance: none;
                                                font-size: 17px;
                                                font-family: $ff-Cream-b;
        
                                                @media screen and (min-width: $tablet-BP) {
                                                    font-size: 18px;
                                                }
        
                                                &::placeholder{
                                                    font-style: italic;
                                                    font-family: $ff-Cream-r;
                                                }
                                            }

                                            & > input {
                                                margin-bottom: 1rem;
                                                font-family: $ff-Cream-b;
                                                @media screen and (min-width: $tablet-BP) {
                                                    margin-bottom: auto;
                                                }
                    
                                                &::placeholder {
                                                    color: $dark;
                                                }
                                            } 
                                        } 
                                    }
                                }
                    
                                .ajax-message {
                                    margin-top: 3rem;
                                    & > div {
                                        &:first-child {
                                            text-align: right;
                                            margin-right: 0;
                                            padding: 0;

                                            & > input {
                                                font-style: normal;
                                            }
                                        }

                                        &.message {      
                                            p {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                .ff-errors{
                                    display: none;
                                }
                            }
                        }
                    }
                }   
            }
        } 
    }
}