.card-text-center-small {
    padding: 0;
    
    & > div {
        position: relative;
        padding: 0;
        max-height: 20rem;
        overflow: hidden;
        margin-bottom: 0.5rem;
        border-top: 1px solid $gray-dots;
        border-bottom: 1px solid $gray-dots;

        @media screen and (min-width: $tablet-BP) {
            max-height: 25rem;
            border: none;
            margin: 0;
        }

        @media screen and (min-width: $desktop-BP) {
            max-height: 38rem;
        }

        a {
            display: block;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                @media screen and (min-width: $tablet-BP) {
                    border: 1px solid$gray-dots;
                }
            }
        
            & > div {
                position: absolute;
                background: $white;
                border: 1px solid $gray-dots;
                z-index: 1;
                top: 3rem;
                left: 3rem;
                right: 3rem;
                bottom: 3rem;
                padding: 3rem 7rem 3rem 2rem;
                align-items: start;
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 40rem;
                margin: auto;
                transition: all .5s ease;

                @media screen and (min-width: $tablet-BP) {
                    padding: 3rem;
                    top: 5rem;
                    left: 4rem;
                    right: 4rem;
                    bottom: 5rem;
                    padding: 3rem 9rem 3rem 3rem;
                }

                @media screen and (min-width: $desktop-BP) {
                    top: 10rem;
                    left: 8rem;
                    right: 8rem;
                    bottom: 10rem;
                    align-items: center;
                    padding:0;
                }

                @media screen and (min-width: $desktop-big-BP) {
                    top: 10rem;
                    left: 12rem;
                    right: 12rem;
                    bottom: 10rem;
                    align-items: center;
                    padding:0;
                }

                &::after {
                    content:"";
                    background: url(/media/locations/arrow-locations.png) no-repeat 0 0;
                    background-size: 5rem;
                    width: 7rem;
                    height: 20px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    @media screen and (min-width: $tablet-BP) {
                        right: 0rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        display: none;
                        
                    }
                }

                svg {
                    display: none;
                    @media screen and (min-width: $desktop-BP) {
                        display:block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 50% !important;
                        height: 50% !important;
                        z-index: 2;
                    }
                }

                &:hover {
                    @media screen and (min-width: $desktop-BP) {
                    
                        transition: all .5s ease;
                        background: $red;

                        p {
                            color: $red;
                        }
        
                        h3 {
                            color: $red;
                        } 
                    }
                }

                h3 {
                    font-family: $ff-Cream-b;
                    margin: 0;
                    font-size: 24px;
                    width: 100%;
                   

                    @media screen and (min-width: $tablet-BP) {
                        font-size: 22px;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        width: auto;
                        font-size: 35px;
                        text-align: center;
                    }
                } 

                p {
                    font-family: $ff-sansSerif;
                    font-size: 15px;
                    margin: 0;
                   

                    @media screen and (min-width: $tablet-BP) {
                        font-size: 17px;            
                    }

                    @media screen and (min-width: $desktop-BP) {
                        font-size: 16px;            
                    }

                    @media screen and (min-width: $desktop-big-BP) {
                        text-align: center;
                    }
                }
            }
        }
    }
}