/*----------------------------------------------------------------*\
		MAIN NAV
\*----------------------------------------------------------------*/
// body[class^="shop/groceries"] {
// 	#js-menu {
// 		padding-top: 3rem;
// 		top: 13.5rem;
// 		@media screen and (min-width: $tablet-BP) {
// 			padding-top: 0;
// 			top: auto;
// 		}
// 	}

// 	header nav.main-nav > div > div:nth-child(2) > div:nth-child(2) > ul:first-child > li:last-child {
// 		bottom: -8rem;

// 		@media screen and (min-width: $tablet-BP) {
// 			bottom: 1.5rem;
// 		}
// 		@media screen and (min-width: $desktop-BP) {
// 			bottom: 2rem;
// 		}
// 	}
// }


header {
	margin:0 auto;
	.skip-content{
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
		z-index: 10;

		&:focus{
			position: absolute;
			left: 0;
			top: 0;
			width: auto;
			height: auto;
			overflow: hidden;
		}
	}

	nav.main-nav {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		border-bottom: 3px solid $dark;
		width:100%;
		background: url(/media/backgrounds/PaintBackground1.jpg) repeat 0 0;
	
		&[class^="main-nav shop"] {
			& > div {
				@media screen and (min-width: $tablet-BP) {
					grid-template-rows: 10rem ;
				}
	
				& > div {
					&:first-child {
						@media screen and (min-width: $tablet-BP) {
							padding-top: 1rem;
						}
						@media screen and (min-width: $desktop-BP) {
							padding-top: 0;
						}
	
						img {
							width: 6rem;
							height: auto;
							@media screen and (min-width: $tablet-BP) {
								width: 8rem;
							}
							@media screen and (min-width: $desktop-BP) {
								width: 100%;
							}
						}
	
						@media screen and (min-width: $desktop-BP) {
							width: 10rem;
							&.logo-big {
								img {
									background: $soft-white;
									transform: scale(1.5);
									z-index: 2;
									transition: all 200ms ease-in-out;
									transform-origin: top left;
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	
		&.shop-online {
			& > div {
				& > div {
					&:nth-child(2) {
						& > div {
							&:last-child { // cart button
								display: none !important;
							}
						}
					}
				}
			}
		}  
	
	
		& > section.mercato-banner{
			display: none;
		}
		&[class^="main-nav shop/groceries"] {
			& > section.mercato-banner {
				@media screen and (min-width: $tablet-BP) {
					display: block;
				}
			}
	
			& > div {
				& > div {
					&:nth-child(2) {
						&.activeShop{
							& > div {
								&:last-child {
									display: none;
								}
							}
						}
					}
				}
			}		
		}
	
		& > div {
			position: relative;
			display:grid;
			grid-template-columns: auto 1fr auto;
			grid-template-rows: 6rem ;
	
			@media screen and (min-width: $tablet-BP) {
				grid-template-rows: 8rem ;
			}
	
			& > div {
				&:first-child {
					grid-column: 1;
					border-right: 1px solid $gray-dots;
					// padding: 1rem;
					@media screen and (min-width: $desktop-BP) {
						width: 8rem;
					}
	
					img {
						width: 6rem;
						padding: 1rem;
						height: auto;
						transition: all 300ms ease-in-out;
						// transform-origin: top left;
						@media screen and (min-width: $tablet-BP) {
							width: 8rem;
						}
					}
	
					@media screen and (min-width: $desktop-BP) {
						img{
							width: 100%;
						}
	
						&.logo-big {
							padding: 0;
							position: relative;
							img {
								position: absolute;
								top: 0;
								transform: scale(1.8);
								background: $soft-white;
								// padding: 1.5rem;
								box-shadow: $logo-shadow;
								transition: all 100ms ease-in-out;
								transform-origin: top left;
								z-index: 2;
							}
						}
					}
				}
			
				&:nth-child(2) {
	
					&.activeShop {
						position: relative;
						background-color: $red;
						@media screen and (min-width: $tablet-BP) {
							background-color: transparent;
							// border-bottom: 1px solid $gray-dots; //new
							// width: 100%; //new
						}
	
						& > div {
							& > a {
								color: $white !important;
								@media screen and (min-width: $tablet-BP) {
									color: $red;
								}
							}
							&:nth-child(2) { // js menu
								& > ul {
									&:first-child {
										& > li {
											&:last-child {
												@media screen and (min-width: $tablet-BP) {
													// top: 4.5rem;
													// transform: rotate(-5deg);
												}
												@media screen and (min-width: $desktop-BP) {
													// top: 2.5rem;
												}
												&:hover {
													@media screen and (min-width: $desktop-BP) {
														// transform: rotate(-8deg) !important; 
														// transition: transform 275ms ease;
													}
												}
											}
										}
									}
									&:last-child {
										@media screen and (min-width: $tablet-BP) {
											display: flex;
										}
									}
								}
							}
							&:last-child { // cart button
								width: 5rem;
								height: 5rem;
								display: flex;
								background-color: $dark;
								border-radius: 50%;
								bottom: -7rem;
								position: absolute;
								right: -6rem;
								z-index: -2;
	
								@media screen and (min-width: $tablet-BP) {
									bottom: -4.8rem;
									right: 3rem;
								}
								@media screen and (min-width: $desktop-BP) {
									bottom: -4.4rem;
								}
	
								@media screen and (min-width: $desktop-big-BP) {
									bottom: -4rem;
								}
								& > a {
									height: 100%;
									width: 100%;
									display: block;
									background-image: url('/media/ecommerce/shop-online/cart-icon.svg');
									background-position: center center;
									background-repeat: no-repeat;
									background-size: 2.5rem;
								}
								& > span {
									font-family: $ff-sansSerif;
									color: $white;
									width: 2.8rem;
									height: 2.8rem;
									background-color: $red;
									top: -1.3rem;
									right: -0.5rem;
									position: absolute;
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									font-weight: bold;
									font-size: 1.3rem;
	
									@media screen and (min-width: $tablet-BP) {
										right: -0.9rem;
										top: -1.2rem;
									}
									@media screen and (min-width: $tablet-BP) {
										right: -0.5rem;
										// width: 2.5rem;
										// height: 2.5rem;
									}
								}
								
							}
						}
	
					}
	
					& > div {
						&:first-child { // shop online red mobile
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 3.2rem;
							@media screen and (min-width: $tablet-BP) {
								display: none;
							}
							a {
								text-decoration: none;
								font-family: $ff-HouseSlam-r;
								color: $red;
							}
						}
						&.menu{ // js menu
							background: $vintage-gray;
							padding: 0;
							position: fixed;
							right: -100%;
							top: 6.1rem;
							bottom: 0;
							width: 100%;
							z-index: -1;
							list-style-type: none;
							margin: 0;

							&.menu.active{
								overflow: scroll;
							}
							@media screen and (min-width: $tablet-BP) { // @changed 12 Nov 2024
								top: 8.1rem;
							}
							
							@media screen and (min-width: $desktop-BP) {
								z-index: 3;
								width: auto;
								background:none;
								position:static;

								display: flex;
								flex-direction: row;
								justify-content: flex-end;
								align-items: center;
								padding: 0 40px;
								height: 100%;
							}
	
							& > ul {
								display: flex;
								z-index: 3;
								// width: 100%;
								justify-content: center;
								align-items: flex-start;
								flex-direction: column;
								@media screen and (min-width: $desktop-BP) { // @changed 12 Nov 2024
									align-items: center;
									justify-content: flex-start;
									flex-direction: row;
								}
								@media screen and (min-width: $desktop-big-BP) { // @changed 12 Nov 2024
									justify-content: center;
								}
								
								& > li {
									position: relative;

									&.active, &.red {
										a {
											color: $red;
										}
									}
									&.has-submenu:after{
										content: "";
										position: absolute;
										width: 3rem;
										height: 3rem;
										background: url(/media/globals/arrow-accordion-2.svg) no-repeat center center;
										background-size: 2rem;
										top: 1.5rem;
										right: 1.5rem;
										-webkit-transition: -webkit-transform .5s ease-in-out;
										transition: -webkit-transform .5s ease-in-out;
										transition: transform .5s ease-in-out;
										transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;

										@media screen and (min-width: $desktop-BP) {
											display: none;
										}
									}

									&.has-submenu.expanded:after{
										-webkit-transform: rotate(180deg);
										transform: rotate(180deg);
										border-color: #CF1A0D;
										-webkit-transition: -webkit-transform .5s ease-in-out;
										transition: -webkit-transform .5s ease-in-out;
										transition: transform .5s ease-in-out;
										transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
									}

									&.shop-menu:after{
										display: none;
									}
	
									border-bottom: 1px solid $dark;
									margin: 0 3rem;
									padding: 1rem 0;
									width: 84%;
									
									@media screen and (min-width: $desktop-BP) {
										border:none;
										margin:0;
										line-height: normal;
										width: auto;
									}

									@media screen and (min-width: $desktop-BP) {
										padding-left: 10px; // same as .megamenu-container .column
										padding-right: 10px; // same as .megamenu-container .column
									}

									@media screen and (min-width: $desktop-big-BP) {
										padding-left: 42px; // same as .megamenu-container .column
										padding-right: 42px; // same as .megamenu-container .column
									}
									
									&:first-child {
										margin-top: 4rem;	
	
										@media screen and (min-width: $desktop-BP) { // @changed 12 Nov 2024
											margin-top:0;
										}
									}
	
									& > a {
										text-decoration: none;
										font-family: $ff-Alternate;
										font-size: 2.8rem;
										text-transform: uppercase;
	
										@media screen and (min-width: $tablet-BP) {
											// margin: 0 1.5rem;
											font-size: 1.6rem;
										}
	
									}
	
								}
	
								&:first-child {
									// height: 60%;
									a {
										font-size: 4rem;
										@media screen and (min-width: $desktop-BP) { // @changed 12 Nov 2024
											font-size: 2rem;
										}
									}
									& > li {
										& > .submenu{
											display: none;
										}
										
										&.expanded > .submenu{
											display: block;
										}

										&:last-child{
											border:none;
										}

										@media screen and (min-width: $desktop-BP) { // @changed 12 Nov 2024
											&.expanded > .submenu{
												display: none;
											}
										}

										& > .submenu{
											margin-top: 10px;
											padding: 20px 0;
											border-top: 1px solid $black;

											li{
												padding: 6px 0;
											}

											a, p{
												font-family: $ff-Alternate;
												font-size: 2.6rem;
												text-transform: uppercase;	
												color: $black;
												text-decoration: none;
											}

											img{
												width: 100%;
											}
										}

										& > .recipes-column{
											p{
												margin: 0;
												line-height: 1.2;
											}
											.all-recipes{
												display: block;
												margin-top: 10px;
											}
										}

										&.shop-menu{

											padding: 40px 0;

											@media screen and (min-width: $desktop-BP) { // @changed 12 Nov 2024
												padding: 0 20px;
											}
										}
										
										&.shop-menu > a{
											// position: absolute;
											display: inline-block;
											background-color:$red;
											border-radius: 6px;
											padding: 0.5rem 4rem;
											z-index: 5;

											color: $white;
											font-family: $ff-HouseSlam-r;
											font-size: 2.8rem;
											text-transform: uppercase;

											@media screen and (min-width: $desktop-BP) {
												bottom: 5rem;
												margin-top: 0;
											}
		
											@media screen and (min-width: $desktop-BP) {
												// padding: 0.5rem 4rem;
												display: block;
											}
										}

										a:hover{
											transform: rotate(-4deg);
										}


									}
								}
	
								&:last-child {
									height: 40%;
									border-top: 1px solid $gray-dots;
									display: none;
									z-index: 1;
								}
							}
						}
						&:last-child { // cart button
							display: none;
						}
					}
				}
	
				// last child burger mobile
				&:last-child {  
	
					display: flex;
					align-items: center;
					padding: 0 2rem;
					border-left: 1px solid $gray-dots;
	
					.nav-burger {
						width: 32px;
						height: 23px;
						transform: rotate(0deg);
						transition: .5s ease-in-out;
					}
						
					.nav-burger span {
						display: block;
						position: absolute;
						height: 3px;
						width: 100%;
						background: $dark;
						opacity: 1;
						left: 0;
						transform: rotate(0deg);
						transition: .25s ease-in-out;
					}
					
					.nav-burger span:nth-child(1) {
						top: 0;
						transform-origin: left center;
					}
					
					.nav-burger span:nth-child(2) {
						top: 0;
						bottom: 0;
						margin: auto;
						transform-origin: left center;
					}
					
					.nav-burger span:nth-child(3) {
						bottom: 0;
						transform-origin: left center;
					}
					
					.nav-burger.open span:nth-child(1) {
						transform: rotate(45deg);
						top: -3px;
						left: 8px;
					}
					
					.nav-burger.open span:nth-child(2) {
						width: 0%;
						opacity: 0;
					}
					
					.nav-burger.open span:nth-child(3) {
						transform: rotate(-45deg);
						top: 20px;
						left: 8px;
					}
				}
			}
		}
	}


	// .social-media > a{ 
	// 	margin: 0 10px 0 0 !important;
	// }

	// @media screen and (min-width: $desktop-BP) {
	// 	.social-media > a{ 
	// 		margin: 0 !important;
	// 	}
	// 	.social-media > a:hover{
	// 	    color: #CF1A0D;
	// 	    transition: color .4s ease-in;
	// 	}
	// }
}