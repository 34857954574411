section.breadcrumbs {
    background: $vintage-gray;
    padding: 0;
    margin: 0 auto;
    max-width: none;

    &[class^="breadcrumbs events/"],
    &[class^="breadcrumbs shop/products/"] {
        background: none;
        
        &  > div {
            @media(min-width: $tablet-BP) {
                padding: 3rem 1.5rem;
            }
            
            ol {
                li {
                    &:before {
                        color: $dark;
                    }
                    a {
                        color: $dark;
                    }
                }
            }
        }
    }

    &[class^="breadcrumbs shop/success"]{
        background: none;

        &  > div {
            @media(min-width: $tablet-BP) {
                padding: 3rem 1.5rem;
            }
            
            ol {
                li {
                    &:before {
                        color: $dark;
                    }
    
                    a {
                        color: $dark;
                    }
                }
            }
            @media(min-width: $desktop-BP) {
                padding: 7rem 1.5rem 2em;
            }
        }
    }

    &  > div {
        position: relative;
        margin: 0 auto;

        @media(min-width: $tablet-BP) {
            // padding: 3rem 2rem 0;
            padding: 2rem;
        }
        @media(min-width: $desktop-BP) {
            padding: 7rem 1.5rem 2em;
        }

        ol {
            display: flex;
            list-style: none;
            font-size: 13px;
            padding: 0;
            li {
                color: #5a5a5a;
                font-family: $ff-sansSerif;

                &:first-child {

                    &:before {
                        content:"";
                    }
                }

                &:before {
                    content:"\203A";
                    color: #5a5a5a;
                }

                a {
                    text-decoration: none;
                    color: #5a5a5a;
                    // color: $dark-gray;
                    padding: 0 0.5rem;
                    font-family: $ff-sansSerif;
                }
            }
        }
    }
}