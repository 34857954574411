.event-article {

    padding:0 2rem;
    max-width: 1200px;
    margin: 6rem auto 3rem auto;
    
    @media screen and (min-width: $desktop-BP) {
        margin-bottom: 3rem;
    }

    & > div {
        border:1px solid $gray-dots;
        background: $vintage-yellow;
        
        @media screen and (min-width: $desktop-BP) {
            padding: 4rem 4rem 7rem 4rem;
            display: flex;
        }

        & > div {
            
            &:first-child {
                padding: 4rem 2rem 0 2rem;
                @media screen and (min-width: $desktop-BP) {
                    padding: 0;
                    padding-right: 4rem;
                }
                
                figure {
                    margin: 0;

                    img {
                        width: 90%;
                        margin: 0 auto;
                        height: auto;
                        border: 1px solid $gray-dots;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 3rem;

                        @media screen and (min-width: $tablet-BP) {
                            width: 31rem;
                            margin-bottom: 0;   
                        }

                        @media screen and (min-width: $desktop-BP) {
                            width: 37rem;  
                        }
                    }
                }
            }
        
            &:last-child{

                
                h1 {
                    margin:0;
                    padding-bottom: 1rem;
                    line-height: 0.7;
                    font-family: $ff-Cream-b;
                    text-align: center;
                    font-size: 3.8rem;

                    @media screen and (min-width: $tablet-BP) {
                        margin-top: 3rem;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        text-align: left;
                        margin-bottom: 0;
                        font-size: 4.8rem;
                        margin-top: 0;
                        
                    }
                }

                & > div {
                       
                    &.event-date {
                        font-family: $ff-Alternate;
                        text-transform: uppercase;
                        font-size: 1.8rem;
                        text-align: center;
                        border-bottom: 1px solid $gray-dots;
                        padding-bottom: 3rem;
                        @media screen and (min-width: $desktop-BP) {
                            font-size: 2rem;
                            text-align: left;
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                    }

                    &:nth-child(3) {
                        padding: 2rem 2rem 1rem 2rem;

                        @media screen and (min-width: $tablet-BP) {
                            padding: 0 2rem 1rem 2rem;
                        }
                        @media screen and (min-width: $desktop-BP) {
                            padding: 0;
                            margin-bottom: 7rem;
                        }
                        p {
                            font-size: 15px;
                            font-weight: 300;
                            @media screen and (min-width: $tablet-BP) {
                                font-size: 18px;
                                margin: 2rem 0;
                                padding-top: 2rem;
                            }
                        }
                    }
                }  
            }
        }
    }
}