.review-order-item {

    padding: 2.7rem 2rem;

    @media screen and (min-width: $desktop-BP) {
        padding: 2.7rem 4rem 0;

        &:first-child {
            padding: 4rem 4rem 0;  
        }
    }

    .grid {
        @media screen and (min-width: $desktop-BP) {
            display: grid;
            grid-template-columns: 1.5fr 5fr;
            gap: 0 2rem;
            border-bottom: 1px solid $gray-dots;
            padding-bottom: 0;
        }

        & > div {
            &:first-child {
                padding-bottom: 2rem;
                text-align: center;

                @media screen and (min-width: $desktop-BP) {
                    padding-bottom: 0.4rem;
                }

                figure {
                    margin: 0;
                    img {
                        width: 50%;
                        height: 50%;
                        object-fit: cover;
                        padding-bottom: 2rem;
                        @media screen and (min-width: $desktop-BP) {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                & > div {
                    border-bottom: 1px solid $gray-dots;
                    padding-bottom: 1rem;

                    h3 {
                        font-family: $ff-Cream-b;
                    }
                }
            }

            &:last-child {
                position: relative;
                margin-bottom: 1.8rem;
                & > div {

                    &:first-child { // title and price
                        @media screen and (min-width: $desktop-BP) {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 1rem;
                            & > div {
                                h3 {
                                    font-family: $ff-Cream-b;
                                    padding-bottom: 2rem;
                                    margin: 0 0 2rem 0;

                                    @media screen and (min-width: $desktop-BP) {
                                        font-size: 26px;
                                        padding-bottom: 0;
                                        margin: 0;
                                    }
                                }

                                & > div {
                                    & > span {
                                        font-size: 1.7rem;
                                        font-weight: bold;
                                        font-family: $ff-sansSerif;
                                        @media screen and (min-width: $desktop-BP) {
                                            font-weight: 500;
                                            font-size: 1.8rem;
                                        }
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        .quantityProduct {
                                            margin-right: 2rem;
                                            
                                        }
                                    }
                                } 
                            }
                        }
                    }

                    &:nth-child(2) { // grid
                        font-family: $ff-sansSerif;
                        @media screen and (min-width: $desktop-BP) {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 0 4rem;
                        }
                        & > div {

                            &:first-child { // enclosure and quantity
                                span {
                                    font-size: 2rem;
                                    font-weight: bold;
                                    @media screen and (min-width: $desktop-BP) {
                                        font-weight: 300;
                                    }
                                }

                                h4 {
                                    margin-top: 3rem;
                                    margin-bottom: 0.1rem;
                                    font-size: 2rem;

                                    @media screen and (min-width: $desktop-BP) {
                                        margin-top: 0;
                                    }
                                }

                                p {
                                    @media screen and (min-width: $desktop-BP) {
                                        margin: 0;
                                    }
                                }

                                .allow-message {
                                    color: $dark-second-gray;
                                    margin-top: 1rem;
                                }
                            }

                            &:last-child {
                            

                                h4 {
                                    font-size: 2rem;
                                    margin-bottom: 18px;
                                
                                    @media screen and (min-width: $desktop-BP) {
                                    margin-top: 0;
                                    }
                                }

                                & > div {
                                    display: flex;
                                    flex-direction: column;
                                    @media screen and (min-width: $desktop-BP) {
                                        display: grid;
                                        grid-template-columns: 1.3fr 1fr;
                                        gap: 0 2rem;
                                    }

                                    & > div {
                                        &.delivery-wrapper {
                                            display: flex;
                                            flex-direction: column;
                                            & > div {
                                                display: flex;
                                                flex-direction: column;
                                                &.pickup-delivery {
                                                    margin-bottom: 2rem;
                                                }  
                                            }
                                        }

                                        &.pickup-time {
                                            display: flex;
                                            flex-direction: column;
                                            margin-top: 2rem;

                                            @media screen and (min-width: $desktop-BP) {
                                                margin-top: 0;
                                            }
                                        }
                                        span {
                                            font-size: 2rem;
                                            @media screen and (min-width: $desktop-BP) {
                                                font-size:1.7rem;
                                            }
                                            &:first-child {
                                                font-size:1.5rem;
                                                margin-bottom: 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: 3px solid $dark;
                        padding: 2rem 0 5rem 0;

                        @media screen and (min-width: $desktop-BP) {
                            border:none;
                            display: inline-block;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            padding-bottom: 1.8rem;
                        }
                        a {
                            font-weight: bold;
                            font-family: $ff-sansSerif;
                            text-decoration: none;
                            font-size: 1.5rem;
                            border-bottom: 1px solid $dark;
                        }
                    }
                }
            }
        }
    }
}