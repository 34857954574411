#qarr-display-container{
    font-family: $ff-ballinger;
    margin-bottom: 1rem;

    .qarr-tab-links{
        a.qarr-tab-link{
            font-family: $ff-ballinger;
            font-size: 1.8rem;
            text-transform: uppercase;
        }
        a.qarr-tab-link.active{
            color: $dark-red;
        }
        a.qarr-tab-link.active:after{
            background-color: $dark-red;
        }
    }

    .qarr-element-title{
        font-family: $ff-Alternate-cond;
        font-size: 2.6rem;
        text-transform: uppercase;
    }
}
.qarr-modal{
    font-family: $ff-ballinger;

    .qarr-header{
        font-family: $ff-Alternate-cond;

        .header-text{
            font-size: 2.6rem;
            text-transform: uppercase;
        }
    }
    .qarr-footer{
        font-family: $ff-Alternate-cond;

        & > input[type=button], & >input[type=submit]{
            font-family: $ff-Alternate-cond;
            font-size: 2rem;
            text-transform: uppercase;
        }
    }
}
