/*----------------------------------------------------------------*\
		BUTTONS
\*----------------------------------------------------------------*/

input[type="submit"],button {
	-webkit-appearance:none;
	border-radius: 0;
	// //outline: none;
}

.generic-button {
	background-color: $white;
    color: $red;
    padding: 11px 0px;
	font-size: 2rem;
	max-width: 18rem;
    text-decoration: none;
	letter-spacing: 1px;
	display: block;
	text-align: center;
	border: 1px solid $gray-dots;
	font-family: $ff-Alternate-cond;
	text-transform: uppercase;

	&.is-active {
		background-color: $red;
		color: $white;
	}
	
	&.general {
		width: 100%;
		max-width: none;
		padding: 1rem 2rem;

		@media screen and (min-width: $tablet-BP) {
			width: auto;
		}

		&.short {
			width: auto;
			padding: 1rem 2rem;
			margin: auto;
		}

		&.small {
			max-width: max-content;
		}
	}
	&.is-small {
		max-width: 14rem;
	}
	&.is-fotter {
		margin-bottom: 2rem;
		@media screen and (min-width: $desktop-BP) {
			margin-bottom: 0;
		}
	}
	&.is-submit {
		max-width: 16rem;
		background: $gray-bg-soft;
		padding: 0.7rem 4rem;


		&.is-inter-submit {
			width: 100%;
			max-width: none;
			background: $white;

			@media screen and (min-width: $tablet-BP) {
				width: 15rem;
				max-width: 16rem;
			}
		}
	}
	&.large-button{
		width: auto;
		max-width: none;
	}
	&.lb-hide-medium-big{
		width: auto;
		max-width: none;
		@media screen and (min-width: $tablet-BP) {
			max-width: 18rem;
		}
	}
	&.is-transparent {
		background: transparent;
	}

	&.is-medium {
		max-width: 22rem;
	}

}

.shop-online-button {
	background-color: $white;
    color:$red;
    padding:6px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
	letter-spacing: 2px;
	display: block;
	text-align: center;
	border: 1px solid $gray;
	border-radius: 13px;
	width: 11rem;
}

.button-underline {
	font-family: $ff-sansSerif;
	display: inline-flex;
	border-bottom: 2px solid $gray-dots;
	text-decoration: none;
	font-size: 15px;
	font-weight: bold;
	padding-bottom: 0.3rem;
	@media screen and (min-width: $tablet-BP) {
		padding-bottom: 0.4rem;
	}
	&.is-small {
		font-size: 1.5rem;
	}

	&.is-border-quit {
		border:none;
	}
}

.radius-button {
	font-family: $ff-HouseSlam-r;
	background:$red;
	color:$white;
	width: 14rem;
	font-size: 25px;
	border-radius: 5px;
	border:none;
	padding: 1rem;
	text-transform: uppercase;

	&.radius-large {
		display:inline;
		padding: 1rem 3rem;
		border-radius: 10px;

	}
}

.dark-button {
	background-color: $white;
    color: $dark;
    padding: 15px 4px;
	font-size: 2.8rem;
	width: 100%;
    text-decoration: none;
	letter-spacing: normal;
	display: block;
	text-align: center;
	border: 1px solid $dark-gray;
	font-family: $ff-HouseSlam-r;
	text-transform: uppercase;
	margin-bottom: 1.2rem;

	@media screen and (min-width: $tablet-BP) {
		padding: 20px 4px;
		letter-spacing: 1px;
	}

	@media screen and (min-width: $desktop-BP) {
		max-width: 40rem;
	}
}