section.mercato-banner {
    display: none;
    background: $red;
    text-align: center;
    
    @media screen and (min-width: $tablet-BP) {
        background: $red;
        display: block;
    }

    & > div {
        padding: 2rem;
       
        & > span {
            font-family: $ff-Cream-r;
            color: $white;
            font-size: 1.6rem;
            @media screen and (min-width: $tablet-BP) {
                font-size: 2.5rem;
            }

            // &:first-child {
            // text-transform: uppercase;
            // }
        }
    }
}