section.product-list-flowers {
    padding-bottom: 1.5rem;
    padding: 0;

    @media screen and (min-width: $tablet-BP) {
        padding-bottom: 3rem;
    }

    @media screen and (min-width: $desktop-BP) {
        padding-bottom: 8rem;
        background: url(/media/globals/bg-logo-products.png) no-repeat bottom left;
        background-size: 39%;
    }

    // @media screen and (min-width: $desktop-big-BP) {
    //     background-size: 39%;
    // }

    @media screen and (min-width: $desktop-extra-big-BP) {
        background-size: 31%;
    }


    & > div {
        padding: 0;
        @media screen and (min-width: $tablet-BP) {
            padding: 0;
            max-width: 1200px;
        }

        @media screen and (min-width: $desktop-BP) {
            padding: 0 2rem;
        }
        
        & > .grid {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 1fr 75%;
                grid-template-rows: 1fr;
                gap: 0px 2rem;
                margin-top: 6rem;
            }
            @media screen and (min-width: $desktop-big-BP) {
                gap: 0px 5rem;
            }

            & > div {

                align-self: flex-start;
                // position: sticky;
                // top: 150px;

                .filters-and-sorts {
                    height: auto;
                    margin-bottom: 0;
                }

                // @media screen and (min-width: $desktop-BP) {
                //     min-height: 90rem;
                // }
                
                &:nth-child(2){
                    padding: 0 2em;
                    & > div {
                        @media screen and (min-width: $tablet-BP) {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 3rem 3rem;
                        }
    
                        @media screen and (min-width: $tablet-BP) {
                            padding-top: 4.5rem;
                        }
    
                        @media screen and (min-width: $desktop-BP) {
                            padding: 0; 
                        }
    
                        & > div {
                            max-width: 100%;
                        }

                        &.load-more {
                            display: block;
                            text-align: center;
                        }
                    }  
                }
            }

        }
    }
}