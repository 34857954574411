.product-card {
    background: $white;
    border: 1px solid $gray-dots;
    position: relative;
    margin-bottom: 3rem;
    margin-top: 3rem;

    @media screen and (min-width: $tablet-BP) {
        margin: auto;
    }

    &.hide {
        opacity: 0;
        display: none;
        min-height: 50px;
        transition: all 500s ease;
        animation: selected 300ms 1;
    }

    & > span {
        &.sticker {
            top: -1.8rem;
            right: 0;
            background-position: 0 0;
            background-size: 10rem;
            background-repeat: no-repeat;
            position: absolute;
            width: 12rem;
            height: 12rem;
            transform: rotate(2deg);
            transition: transform .3s ease-in-out;
            z-index: 2;

            @media screen and (min-width: $tablet-BP) {
                background-size: 12rem;
                width: 14rem;
                height: 14rem;
            }

            &.sticker-bestSeller {
                background-image: url(/media/ecommerce/WB-BestSeller.png);
            } 

            &.sticker-new {
                background-image: url(/media/ecommerce/WB-New.png);
                background-size: 11.3rem;
            }
            &.sticker-feedTheLove{
                background-image: url(/media/ecommerce/WB-FeedTheLove.png);
                background-size: 11.3rem;
            }

            &.sticker-limited {
                background-image: url(/media/ecommerce/WB-LTO.png);
                transform: rotate( -14deg);
                background-size: 15rem;
                width: 16rem;
                height: 14rem;
                top: -3.7rem;

                &:hover {
                    transition: transform .3s ease-in-out;
                    transform: rotate(-8deg);
                }
            }
    
            &.sticker-seasonal {
                background-image: url(/media/ecommerce/WB-Seasonal.png);
                background-size: 15rem;
                width: 16rem;
                height: 14rem;
                top: -3.7rem;
            }
        }

        @media screen and (min-width: $desktop-BP) {
            &:hover {
                transition: transform .3s ease-in-out;
                transform: rotate(9deg);
            }
        }
    }

    & > div {

        a {
            text-decoration: none;
            display: block;
            padding: 1rem 1rem 2.5rem 1rem;
            & > div {

                &:first-child { 
                    position: relative;
                    .sticker-back-soon {
                        background-image: url(/media/ecommerce/Backsoon.png);
                        background-size: 10rem;
                        bottom: -2rem;
                        right: 0;
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        position: absolute;
                        width: 12rem;
                        height: 8rem;
                        transform: rotate(2deg);
                        transition: transform .3s ease-in-out;
                        z-index: 2;

            
                        @media screen and (min-width: $tablet-BP) {
                            background-size: 12rem;
                            width: 12rem;
                            height: 9rem;
                            right: 2rem;
                            
                        }
                        @media screen and (min-width: $desktop-BP) {
                            right: 2rem;
                            &:hover {
                                transition: transform .3s ease-in-out;
                                transform: rotate(9deg);
                            }
                        }
                    }
                    figure {
                        margin: 0;
        
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                &:nth-child(2) {
                    padding: 1.5rem;
                    h3 {
                        font-family: $ff-Cream-b;
                    }
        
                    p {
                        font-family: $ff-sansSerif;
                    }
                }
    
                &:last-child {
                    display: flex;
                    align-items: flex-end;
                    p {
                        font-family: $ff-sansSerif;
                        font-style: italic;
                    }
                }
            }
        }  
    }
}