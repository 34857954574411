.filter-with-accordion {
    padding: 0;
    border-left: 1px solid $gray-dots;
    
    @media screen and (min-width: $tablet-BP) {
        height: auto;
        border: none;
    }

    @media screen and (min-width: $desktop-BP) {
        margin-top: 0;
    }

    & > div {
        overflow: hidden;
        height: 0;
        padding: 0;

        @media screen and (min-width: $desktop-BP) {
            height: auto !important;
        }

        & > div {
            border-bottom: 1px solid $gray-dots;
            &:last-child { // clear all
                padding: 0 2rem;
                border-bottom: none;
                @media screen and (min-width: $desktop-BP) {
                    padding: 0;
                }
                a {
                    text-decoration: none;
                    margin: 1.2rem 0 1rem 0;
                    display: inline-block;
                    font-family: $ff-sansSerif;
                    font-size: 1.8rem;
                }
                
            }

            &.expanded {
                margin-bottom: 1rem;
                button {    
                    &::after {
                        transform: rotate(180deg);
                        border-color: $red;
                        transition: transform .5s ease-in-out;
                    }
                    @media screen and (min-width: $desktop-BP) {
                        color: $dark; 
                    }
                }

                .accordion-content {
                    @media screen and (min-width: $desktop-BP) {
                        margin: 0;
                        border: none;
                        background-color: transparent;
                    }
                }
            }

            button {
                font-family: $ff-Clawson-b;
                font-weight: 400;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: 2rem;
                margin: 1.2rem 0 1rem 0;
                padding: 0 2rem;
                border: none;
                background: none;
                width: 100%;
                text-align: left;
                @media screen and (min-width: $desktop-BP) {
                    padding: 0;
                }
                
                &::after {
                    content: "";
                    position: absolute;
                    width: 1.5rem;
                    height: 1.5rem;
                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat 0 0;
                    background-size: 1.5rem;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 15px;
                    transition: transform .5s ease-in-out;

                    @media screen and (min-width: $tablet-BP) {
                        background-size: 1.7rem;
                    }
    
                    @media screen and (min-width: $desktop-BP) {
                        background-size: 1.5rem;
                    }
                }
            }

            .accordion-content {
                height: 0;
                overflow: hidden;
                visibility: hidden;
                
                & > ul {
                    padding-left: 3rem;
                    
                    .checkcontainer {
                        .locationname {
                            padding: 0.5rem 0 0.5rem 2rem;
                            font-family: $ff-sansSerif;
                        }
                    }

                    &:last-child {
                        margin-bottom: 1.5rem;
                        @media screen and (min-width: $desktop-BP) {
                            margin-bottom: 1.8rem;
                        }
                    }
                }
            }
            
        } 
    }   
}