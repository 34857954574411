section.community-partners {

    @media screen and (min-width: $desktop-BP) {
        margin-top: 3rem; 
        margin-bottom: 4rem;
    }

    & > div {

        & > div {
            &:first-child {
                text-align: center;
               

                @media screen and (min-width: $desktop-BP) {
                    padding: 0 25rem 1rem;
                    background: url(/media/globals/bg-wm-logo.png) no-repeat bottom left;
                    background-size: 20%;
                }

                h3 {
                    font-family: $ff-Cream-b;
                    letter-spacing: -2px;
                }

                p {
                    font-family: $ff-ballinger ;
                    margin-bottom: 4rem;
                }
            }

            &:last-child {
                position: relative; //father
                border: 1px solid $gray-dots;
                background: $white;
                padding: 1rem 2rem 0rem;

                @media screen and (min-width: $desktop-BP) {
                    padding: 1rem 2rem 0;   
                }
                
                h3 {
                    font-family: $ff-Clawson-b;
                    letter-spacing: 1.5px;
                    color:$red;
                    text-align: center;
                    margin-bottom: 3rem;

                    @media screen and (min-width: $desktop-BP) {
                     padding-top: 2rem;   
                    }
                }

                .community-partners-dots {
                    position: relative;
                    padding: 0;
                    @media screen and (min-width: $desktop-BP) {
                        margin-top: 7rem;
                    }
                    ul {
                        bottom: 1rem;
                        @media screen and (min-width: $desktop-BP) {
                            bottom: 2rem;
                        }
                        li {
                            margin: 0 1px;
                            &.slick-active {
                                button {
                                    &::before {
                                        background: $red;
                                        opacity: 1;
                                        transition: all 300ms linear;
                                    }
                                }
                            }
                            button {
                                padding:0;
                                &::before {
                                    transition: all 300ms linear;
                                    content: "";
                                    background: $dark;
                                    opacity: 1;
                                    border-radius: 1rem;
                                    width: 0.8rem;
                                    height: 0.8rem;
                                    opacity: 0.4;
                                    @media screen and (min-width: $tablet-BP) {
                                        width: 1.2rem;
                                        height: 1.2rem;
                                    }
                                }
                                
                                &:hover {
                                    &::before {
                                        background: $red;
                                        opacity: 1;
                                        transition: all 300ms linear;
                                    }
                                }
                            }
                        }
                    }
                }

                .slick-community-prev,
                .slick-community-next {
                    position: absolute;
                    bottom: 0;
                    top:0;
                    margin: auto;
                   
                    cursor: pointer;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    font-size: 2.6rem;
                    text-align: center;
                    border: 1px solid $gray-dots;
                    color: $gray;
                    background:$white;
                    line-height: 2.4rem;
                   
                }
                .slick-community-prev {
                    left: -1.5rem;
                }

                .slick-community-next {
                    right: -1.5rem;  
                }  
            }
        }
    }

}