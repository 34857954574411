section.events-details {
    padding-bottom: 1.5rem;
    padding: 0;
    
    @media screen and (min-width: $tablet-BP) {
        padding-bottom: 3rem;
    }

    @media screen and (min-width: $desktop-BP) {
        padding-bottom: 4rem;
    }

    & > div {
        padding: 0;
        @media screen and (min-width: $tablet-BP) {
            padding: 0;
            max-width: 1200px;
        }

        @media screen and (min-width: $desktop-BP) {
            padding: 0 2rem;
            max-width: 1200px;
        }
        
        .grid {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 1fr 75%;
                grid-template-rows: 1fr;
                gap: 0px 5rem;
                margin-top: 6rem;
            }

            & > div {

                &:last-child{
                    padding: 0 2em;
                    margin-top: 2rem;

                    @media screen and (min-width: $tablet-BP) {
                        padding-top: 4.5rem;
                        margin: 0;
                    }

                    @media screen and (min-width: $desktop-BP) {
                        padding: 0;
                    }
                }
            }
        }
    }
}