div.form-product-detail {

    .allow-message {
        color: $dark-red;
        font-style: italic;

        @media screen and (max-width: $desktop-BP) {
            padding: 0 20px;
        }

    }

    & > div {
        & > form {
            padding: 1rem 2rem 3rem 2rem;
            @media screen and (min-width: $desktop-BP) {
                padding: 0;
            }
            & > div {
                &.grid {
                    @media screen and (min-width: $tablet-BP) {
                        display: grid;
                        grid-template-columns: 25% auto;
                        grid-template-rows: 1fr;
                        gap: 0 3rem;
                        direction: rtl;
                    }
                    & > div {
                        &:first-child { // price and amount

                            margin-bottom: 2rem;
                            @media screen and (min-width: $tablet-BP) {
                                margin-bottom: 0;
                            }
                            select {
                                padding: 1.1rem 3.5rem 1.2rem 0.5rem;
                            }
                        //     // display: flex;
                        //     // flex-direction: row;
                        //     // justify-content: space-between;
                        //     // align-items: center;
                                

                        //     // &:last-child {
                        //     //     margin-bottom: 0;
                        //     // }
                            
                        //     // @media screen and (min-width: $tablet-BP) {
                        //     //     flex-direction: column-reverse;
                        //     // }

                        //     // & > div {
                        //     //     @media screen and (min-width: $tablet-BP) {
                        //     //         width: 100%;
                        //     //     }
                        //     // }

                        }
                        &:last-child { // delivery and calendar
                            
                            @media screen and (min-width: $tablet-BP) {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                            }

                            & > div {
                                margin-bottom: 1rem;
                                position: relative;

                                @media screen and (min-width: $tablet-BP) {
                                    margin-bottom: 1rem;
                                }

                                &.variants-select { //size
                                    &::before {
                                        content: "";
                                        background: url(/media/ecommerce/WBCatering.png) no-repeat center center;
                                        background-size: 2.8rem;
                                        position: absolute;
                                        height: 2rem;
                                        width: 3rem;
                                        top: 0;
                                        left: 1rem;
                                        bottom: 0;
                                        margin: auto;
                                    }
                                
                                    & > select {
                                        
                                        width: 100%;
                                        padding: 1rem 5rem;
                                        font-family: $ff-Cream-b;
                                        border: none;
                                        border-radius: inherit;
                                        border-radius: 0;
                                        appearance: none;
                                        //outline: none;
                                        font-size: 17px;
                                        background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                        background-size: 1.5rem;
                                        background-color: $gray-bg-soft;
    
                                        @media screen and (min-width: $tablet-BP) {
                                            direction: ltr; 
                                        } 
    
                                        @media screen and (min-width: $desktop-big-BP) {
                                            font-size: 20px;
                                        }
                                    }
                                }


                            &.calendar { //calendar
                                &::before {
                                    content: "";
                                    background: url(/media/ecommerce/calendar-icon.svg) no-repeat center center;
                                    background-size: 2.2rem;
                                    position: absolute;
                                    height: 2rem;
                                    width: 3rem;
                                    top: 0;
                                    left: 1rem;
                                    bottom: 0;
                                    margin: auto;
                                }
                                
    
                                & > input{
                                    background-color: $gray-bg-soft;
                                    border: none;
                                    border-radius: inherit;
                                    //outline: none;
                                    width: 100%;
                                    padding: 1rem 5rem;
                                    font-family:$ff-Cream-b;
                                    appearance: none;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                    background-size: 1.5rem;
                                    background-color: $gray-bg-soft;
                                    font-size: 17px;

                                    @media screen and (min-width: $tablet-BP) {
                                        text-align: left;
                                    }

                                    @media screen and (min-width: $desktop-big-BP) {
                                        font-size: 20px;
                                    }
                                }
                            }
                            
                            &.pickup-time {
                                margin-bottom: 1rem;
                            }
                            &.pickup-select {
                                margin-bottom: 1rem;
                            }

                            &.pickup-select,
                            &.pickup-time { //delivery
                            
                                &::before {
                                    content: "";
                                    background: url(/media/ecommerce/delivery-car-icon.svg) no-repeat center center;
                                    background-size: 2.8rem;
                                    position: absolute;
                                    height: 2rem;
                                    width: 3rem;
                                    top: 0;
                                    left: 1rem;
                                    bottom: 0;
                                    margin: auto;
                                }
                            
                                & > select {
                                    width: 100%;
                                    padding: 1rem 5rem;
                                    font-family: $ff-Cream-b;
                                    border: none;
                                    border-radius: inherit;
                                    border-radius: 0;
                                    appearance: none;
                                    //outline: none;
                                    font-size: 17px;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                    background-size: 1.5rem;
                                    background-color: $gray-bg-soft;

                                    @media screen and (min-width: $tablet-BP) {
                                        direction: ltr; 
                                    } 

                                    @media screen and (min-width: $desktop-big-BP) {
                                        font-size: 20px;
                                    }
                                }
                            }


                            &.delivery-select,
                            &.delivery-method { //delivery

                                & > select{
                                    width: 100%;
                                    padding: 1rem 5rem;
                                    font-family: $ff-Cream-b;
                                    border: none;
                                    border-radius: inherit;
                                    border-radius: 0;
                                    appearance: none;
                                    //outline: none;
                                    font-size: 17px;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat calc(100% - 15px) center;
                                    background-size: 1.5rem;
                                    background-color: $gray-bg-soft;
                                    
                                }

                            }

                            }   
                        }
                    } 
                }

                &.enclosure {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    margin-top: 2rem;
                    & > label {
                        margin-bottom: 1rem;
                        font-family: $ff-Alternate-cond;
                        text-transform: uppercase;
                        font-weight: 100;
                        font-size: 2.4rem;
                        @media screen and (min-width: $tablet-BP) {
                            font-size: 2.6rem;
                        }
                    }

                    & > textarea {
                        height: 10rem;
                        resize: none;
                        background-color: $gray-bg-soft;
                        border: none;
                        padding: 1.5rem;
                        border-radius: inherit;
                        //outline: none;
                        font-size: 17px;

                        @media screen and (min-width: $tablet-BP) {
                            font-size: 18px;
                        }
                        
                        &::placeholder {
                            color: $dark;
                            font-family: $ff-Cream-r;
                            font-style: italic ;
                        }
                    }
                    .text-help{
                        margin: 1rem 0;
                        font-family: $ff-Cream-r;
                        font-size: 15px;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}
