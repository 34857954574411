/*----------------------------------------------------------------*\
		MAIN FOOTER
\*----------------------------------------------------------------*/

footer.footer {
    padding: 0;
    margin-top: 1.6rem;
    border-top: 1px solid $gray-dots;

    & > div {
        @media screen and (min-width: $desktop-BP) {
            padding: 0;
            max-width: none;
        }
        .grid {
            display: flex;
            flex-direction: column-reverse;

            @media screen and (min-width: $desktop-big-BP) {
                display: grid;
                grid-template-columns: 2fr 1fr;
                flex-direction: row;
            }

            & > div {

                &:first-child {
                    background: $white;

                    & > div {

                        padding-top: 1rem;

                        @media screen and (min-width: $desktop-BP) {
                            display: grid;
                            row-gap: 4rem;
                            grid-template-columns: 1fr 1fr;
                            // grid-template-rows: 1fr 1fr;
                            // padding: 7rem 4rem 3rem 8rem;
                            padding: 7rem 2rem 0rem 2rem;
                        }

                        @media screen and (min-width: $desktop-big-BP) {
                            padding: 7rem 4rem 3rem 5rem;
                        }

                        & > div {
                            padding: 0 2rem;
                    
                            &.expanded {
                                margin-bottom: 1rem;

                                button {    
                                    color: $red;
                                    &::after {
                                        transform: rotate(180deg);
                                        border-color: $red;
                                        transition: transform .5s ease-in-out;
                                    }
                                    @media screen and (min-width: $desktop-BP) {
                                        color: $dark; 
                                    }
                                }
                                .accordion-content {
                                    margin-top: 2rem;
                                    border-bottom: 1px solid $gray-dots;
                                    @media screen and (min-width: $desktop-BP) {
                                        border: none;
                                    }
                                }
                            }
    
                            button {
                                color: $black;
                                font-family: $ff-Clawson-b;
                                line-height: 4.8rem;
                                padding-bottom: 1rem;
                                position: relative;
                                text-transform: uppercase;
                                margin-bottom: 1rem;
                                width: 100%;
                                border: 0;
                                background: none;       
                                margin-top: 1rem;
                                text-align: left;
                                border-bottom: 1px solid $gray-dots;
                                font-size: 4.5rem;

                                @media screen and (min-width: $desktop-BP) {
                                    border-bottom: 2px solid $dark;
                                    line-height: 5rem;
                                    padding: 0;
                                    padding-bottom: 1.5rem;
                                    margin-bottom: 2rem;
                                    margin-top: 0;
                                    font-size: 5rem;
                                }
                                
                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    background: url(/media/globals/arrow-accordion-2.svg) no-repeat center center;
                                    top: calc(50% - 1.5rem);
                                    right: 0;
                                    transition: transform .5s ease-in-out;
                                    cursor: pointer;
                                
                                    @media screen and (min-width: $desktop-BP) {
                                        display: none;
                                    }
                                }
                            }
                            .accordion-content {
                                height: 0;
                                overflow: hidden;
                                visibility: hidden;
                            
                                @media screen and (min-width: $desktop-BP) {
                                    height: auto;
                                    overflow: visible;
                                    visibility: visible;
                                }
    
                                ul {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    padding: 0;
                                    list-style: none;
        
                                    li {
                                        padding: 0;
                                        margin: 0;
        
                                        &:first-child {
                                            padding-right: 4rem;
                                        }
        
                                        & > strong {
                                            font-family: $ff-Clawson-b;
                                            text-transform: uppercase;
                                        }
                                        p {
                                            margin-top: 0.1rem; 
                                            display: flex;
                                            flex-direction: column;

                                            &.allign-phone-margin{
                                                @media screen and (min-width: $desktop-BP) {
                                                    margin-bottom: 2.1rem;
                                                }
                                            }

                                            @media screen and (min-width: $desktop-BP) {
                                                padding-top: 0.5rem; 
                                            }


                                        & > strong {
                                                text-transform: none;
                                                font-family: $ff-sansSerif;
                                            }
                                        }
                                        
                                    }
                                }
                            } 
                        }
                    }
                }

                &:last-child {
                    background: url(/media/backgrounds/footer-bg.png) no-repeat 0 0;
                    background-size: cover;
                    
                    @media screen and (min-width: $desktop-BP) {
                        border-left: 1px solid $gray-dots;
                    }

                    @media screen and (min-width: $desktop-big-BP) {
                        display: grid;
                        grid-template-rows: 1fr 0.5fr 0.3fr;
                    }

                    & > div {
                        &:first-child {
                            padding: 3rem 4rem 8rem 4rem;
                            border-bottom: 1px solid $gray-dots;

                            @media screen and (min-width: $desktop-BP) {
                                padding: 7rem 5rem 8.4rem 5rem;
                                border-bottom: none;
                            }

                            h3 {
                                font-family: $ff-HouseSlam-r;
                                color: $red;
                                text-align: center;
                                text-transform: uppercase;
                                line-height: 0.9;
                                font-size: 5.6rem;
                                font-weight: 100;
                            }
        
                            p {
                                
                                text-align: center;
                                @media screen and (min-width: $desktop-BP) {
                                    padding: 0 4rem;
                                }
                            }
        
                            form {
                                position: relative;

                                input { 
                                    &[type="text"] {
                                        background: $vintage-gray;
                                        display: block;
                                        border:none;
                                        width: 100%;
                                        padding: 1.5rem;
                                        font-family: $ff-Cream-b;
                                        font-weight: bold;
                                        font-size: 1.7rem;
                                        //outline: none;
                                        @media screen and (min-width: $tablet-BP) {
                                            width: 62%;
                                            margin: 0 auto ;
                                        }
                                        @media screen and (min-width: $desktop-BP) {
                                            margin: 4rem 0;
                                            width: 100%;
                                            font-size: 1.8rem;
                                        }
                                    }
                                    &::placeholder{
                                        font-style: italic;
                                        font-family: $ff-Cream-r;
                                        color: $dark;
                                    }

                                    &[type="submit"] {
                                        position: absolute;
                                        bottom: -3;
                                        background-color: $red;
                                        padding: 0.6rem;
                                        right: -1rem;
                                        border-radius: 5px;
                                        @media screen and (min-width: $tablet-BP) {
                                            right: 11rem;
                                        }
                                        @media screen and (min-width: $desktop-BP) {
                                            width: 17rem;
                                            right: -1rem;
                                        }
                                    }
                                }
                            
                            }
                        }
                    }
                }
            }
        }
    }

    .social-media, .social-media-xlarge{
        display: flex !important;
        justify-content: center;
        font-size: 3em;

        & > a{
            color: #252525;
            margin: 0 10px;
        }

        & > a:hover{
            color: #CF1A0D;
            transition: color .4s ease-in;
        }
    }
    .social-media-xlarge{
        display: none !important;
    }

    @media(min-width: $desktop-big-BP) {
        .social-media-xlarge{
            display: flex !important;
        }
        .social-media{
            display: none !important;
        }
    }

    .links-mobile {
        ul {
            margin: 0;
            padding:3rem 2rem;
            list-style: none;
            display: grid;
            gap: 1rem;
            grid-template-columns: 1fr 1fr 1fr;

            li {
                display: flex;
                flex-direction: column;
                p {
                    margin: 0; 
                }

                a {
                    font-family: $ff-sansSerif;
                    font-weight: 300;
                    text-decoration: none;
                    font-size: 13px;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .footer-links {

        .grid-wrapper {
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: 2fr 1fr;
            }

            

            &:first-child {
                ul {
                    margin: 0;
                    padding:3rem 2rem;
                    list-style: none;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
        
                    @media screen and (min-width: $tablet-BP) {
                        grid-template-columns: 1fr 1fr;

                    }
                    @media screen and (min-width: $desktop-BP) {
                        grid-template-columns: 1fr 1.5fr;
                        padding: 0rem 0rem 0 5rem;
                    }
        
                    li {
                        display: flex;
                        flex-direction: column;
                        p {
                            margin: 0; 
                        }
        
                        a {
                            font-family: $ff-sansSerif;
                            font-weight: 300;
                            text-decoration: none;
                            font-size: 13px;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            &:last-child {
                figure {
                    margin: 0;

                    img {
                        @media screen and (min-width: $tablet-BP) {
                            max-width: 60%;
                        }

                        @media screen and (min-width: $desktop-big-BP) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        
    }
    .copyright {
        
        text-align: center;
        display: block !important;
        font-weight: 300;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1.5rem;
        text-align: center;

        @media screen and (min-width: $desktop-BP) {
            padding: 1rem 3rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        p {
            font-size: 13px;

            a {
                text-decoration: none !important;
            }
        }
    }
}

//footer form
.e2ma_signup_form{
    position: relative;

    .e2ma_signup_form_row {
        .e2ma_signup_form_label {
            display: none;
        }
    }

    .e2ma_signup_form_required_footnote {
		display: none;
	}

    input { 
        &[type="email"] {
            background: $vintage-gray;
            display: block;
            border:none;
            width: 100%;
            padding: 1.2rem;
            font-family: $ff-Cream-b;
            font-weight: bold;
            font-size: 1.7rem;
        
            //outline: none;
            @media screen and (min-width: $tablet-BP) {
                width: 62%;
                margin: 0 auto ;
            }
            @media screen and (min-width: $desktop-BP) {
                margin: 4rem 0 0 0;
                width: 100%;
                font-size: 1.8rem;
            }
        }
        &::placeholder{
            font-style: italic;
            font-family: $ff-Cream-r;
            color: $dark;
        }

        &[type="submit"] {
            position: absolute;
            bottom: -3rem;
            font-family: $ff-HouseSlam-r;
            background:$red;
            color:$white;
            width: 14rem;
            font-size: 25px;
            border-radius: 5px;
            border:none;
            padding: 1rem;
            text-transform: uppercase;
            padding: 0.6rem;
            right: -1rem;
            border-radius: 5px;
            transform: rotate(6deg);
            transition: transform 275ms ease;
            cursor: pointer;
            &:hover {
                @media screen and (min-width: $desktop-BP) {
                    transform: rotate(1deg) !important; 
                    transition: transform 275ms ease;
                }
            }
        
            @media screen and (min-width: $tablet-BP) {
                right: 11rem;
            }
            @media screen and (min-width: $desktop-BP) {
                width: 17rem;
                right: -1rem;
            }
        }
    }
}