section.wedding-event-form {
    padding:0;
    max-width: 100%;
    & > div {
        padding:0;
        max-width: none;
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        @media screen and (min-width: $tablet-BP) {
            margin-top: 3rem;
            margin-bottom: 6rem;
        }
        @media screen and (min-width: $desktop-BP) {
            padding-bottom: 0;
            margin-bottom: 4rem;
            margin-top: 6rem;
        }
        .grid {
            position: relative;
            
            @media screen and (min-width: $desktop-BP) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: 1fr;
                gap: 0px 0px;
            }
            & > div {
                &:first-child {
                    position: absolute;
                    left: 2rem;
                    right: 2rem;
                    height: 6rem;
                    bottom: -6rem;
                    background: $white;
                    border: 1px solid $gray-dots;
                    border-top: none;
                    @media screen and (min-width: $desktop-BP) { 
                        grid-area:1 / 2 / 2 / 12; 
                        border: 1px solid $gray-dots;
                        position: static;
                        left: auto;
                        right: auto;
                        height: auto;
                        bottom: auto;
                    }  
                }
                &:nth-child(2) {
                    padding: 2rem 0;
                    background: #fff;
                    border: 1px solid $gray-dots;
                    margin: 1rem 2rem 0 2rem;
                    border-bottom: none;
                    // @media screen and (min-width: $tablet-BP) { 
                    //     padding: 0 2rem;
                    // }
                    @media screen and (min-width: $desktop-BP) { 
                        margin: 0;
                        padding: 0 2rem;
                    }
                    @media screen and (min-width: $desktop-BP) { 
                        grid-area:  1 / 2 / 2 / 6;
                        padding:0;
                        background: none;
                        border: none;
                        border-radius: inherit;
                    }
                    & > form {
                        padding: 3rem;
                        @media screen and (min-width: $tablet-BP) { 
                            padding:4rem 2rem;
                        }
                        @media screen and (min-width: $tablet-BP) { 
                            padding: 3rem;
                        }

                        .g-recaptcha{
                            margin-bottom: 2rem;
                        }

                        & > div {
                            &.personalData {
                                & > fieldset {
                                    & > div {
                                        & > div {
                                            margin-bottom: 1.5rem;
                                            
                                            &:last-child {
                                                margin-bottom: 3rem;
                                                & > input {
                                                    width: 70%;
                                                    @media screen and (min-width: $tablet-BP) { 
                                                        width: 50%;
                                                    }
                                                    @media screen and (min-width: $desktop-BP) { 
                                                        width: 58%;
                                                    }
                                                    @media screen and (min-width: $desktop-big-BP) { 
                                                        width: 50%;
                                                    }
                                                }
                                            }  
                                        }
                                    }
                                }
                            }
                            &.aboutEvent {
                                & > fieldset {
                                    & > div {
                                        & > div {
                                            &.numberGuest {
                                                margin-bottom: 1.5rem;
                                                & > input {
                                                    width: 70%;
                                                    @media screen and (min-width: $tablet-BP) { 
                                                        width: 50%;
                                                    }
                                                    @media screen and (min-width: $desktop-BP) { 
                                                        width: 58%;
                                                        margin-bottom: 1.7rem;
                                                    }
                                                    @media screen and (min-width: $desktop-big-BP) { 
                                                        width: 50%;
                                                    }
                                                }
                                                &:last-child {
                                                    margin-bottom: 3rem;
                                                }  
                                            }
                                            &.help-note {
                                                margin-bottom: 3rem;
                                            }
                                        }    
                                    }
                                }  
                            }
                            & > fieldset {
                                border:none;
                                border-radius: inherit;
                                padding: 0;
                                margin: 0;
                                & > legend {
                                    font-family: $ff-Alternate;
                                    text-transform: uppercase;
                                    //outline: none;
                                    font-size: 2.4rem;
                                    @media screen and (min-width: $tablet-BP) {
                                        font-size: 2.6rem;
                                    }
                                }
                                & > div {
                                    & > div {
                                        & > select,
                                        & > textarea,
                                        & > input {
                                            //outline: none;
                                            padding: 1.2rem 1.7rem;
                                            width: 100%;
                                            border: none;
                                            border-radius: inherit;
                                            appearance: none;
                                            background: $gray-bg-soft;
                                            font-size: 1.7rem;
                                            @media screen and (min-width: $desktop-BP) {
                                                font-size: 1.8rem;
                                            }
                                            &::placeholder{
                                                color: $dark;
                                            }
                                        }
                                        & > label {
                                            
                                            display: block;
                                            font-family: $ff-Alternate;
                                            text-transform: uppercase;
                                            //outline: none;
                                            margin-bottom: 1rem;
                                            font-size: 2.4rem;
                                            @media screen and (min-width: $tablet-BP) {
                                                font-size: 2.6rem;
                                            }
                                        } 
                                        & > textarea  {
                                            resize: none;
                                            border-bottom: 2rem;
                                            height: 14rem;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                            &.ajax-message {
                                
                                p {
                                    display: none;
                                    margin-top: 2.5rem;
                                }
                            }
                        }

                        .ff-errors{
                            display: none;
                        }
                    } 
                }
    
                &:last-child {
                    position: relative;
                    @media screen and (min-width: $desktop-BP) { 
                        grid-area:1 / 6 / 2 / 13;
                        max-width: 100%;
                        padding: 4rem 0;
                    }
                    img {
                        border: 1px solid $gray-dots;
                    }
                    ul.slick-dots {
                        bottom: -4rem;
                        @media screen and (min-width: $desktop-BP) { 
                            bottom: -4.6rem;
                        }
                        @media screen and (min-width: $desktop-big-BP) { 
                            bottom: -3.3rem;
                        }
                        li {
                            button {
                                &::before {
                                    content: "";
                                    background: $gray-dots;
                                    opacity: 1;
                                    border-radius: 1rem;
                                    width: 1.2rem;
                                    height: 1.2rem;
                                    transition: all 300ms linear;
                                    box-shadow: none;
                                }
                                &:hover {
                                    &::before {
                                        background: $red;
                                        transition: all 300ms linear;
                                    }
                                }
                            }
                        }
                    }  
                }
            }
        } 
    }
}
